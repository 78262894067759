import React, { useLayoutEffect, useRef, useEffect, useContext } from "react";
import { gsap } from "gsap";

import Title from "../components/global/Title";
import UndelinedQuote from "../components/global/UndelinedQuote";
import Paragraph from "../components/global/Paragraph";
import ArrowSubtitle from "../components/global/ArrowSubtitle";
import classNames from "classnames";
import useBreakpoint from "../helpers/useBreakpoint";
import { Helmet } from "react-helmet";
import { LanguageContext } from "../App";

import AOS from "aos";
import "aos/dist/aos.css"; // Import the CSS for AOS animations

export default function ID() {
  const { currentLanguage } = useContext(LanguageContext);

  const currentBreakpoint = useBreakpoint();

  const titleRef = useRef(null);
  const imageRef = useRef(null);
  const imageContainerRef = useRef(null);
  const subtitleRef = useRef(null);
  const introSubtitleRef = useRef(null);

  useEffect(() => {
    AOS.init({ anchorPlacement: "top-bottom", disable: "phone" });
  }, []);

  // Initial Animation
  useLayoutEffect(() => {
    if (
      currentBreakpoint === "sm" ||
      currentBreakpoint === "md" ||
      currentBreakpoint === "lg" ||
      currentBreakpoint === "xl" ||
      currentBreakpoint === "2xl"
    ) {
      // Set initial opacity to 0 before the animation starts
      gsap.set(imageContainerRef.current, {
        scaleY: 0,
        transformOrigin: "top",
      });
      gsap.set(imageRef.current, { opacity: 0 });
      gsap.set(titleRef.current, { opacity: 0 });
      gsap.set(subtitleRef.current, { opacity: 0 });
      gsap.set(introSubtitleRef.current, { opacity: 0 });

      // GSAP animation timeline
      const animationTimeline = gsap.timeline();

      animationTimeline.to(titleRef.current, {
        opacity: 1,
        duration: 0.5,
        ease: "power2.out",
        delay: 0.5,
      });

      animationTimeline.to(imageContainerRef.current, {
        scaleY: 1,
        duration: 0.75,
        ease: "power2.out",
      });

      animationTimeline.addLabel("textAnimations");

      animationTimeline.to(imageRef.current, {
        opacity: 1,
        duration: 0.5,
        ease: "power2.out",
      });

      animationTimeline.to(
        subtitleRef.current,
        {
          opacity: 1,
          duration: 0.5,
          ease: "power2.out",
        },
        "textAnimations",
      );

      animationTimeline.to(
        introSubtitleRef.current,
        {
          opacity: 1,
          duration: 0.5,
          ease: "power2.out",
        },
        "textAnimations",
      );

      return () => {
        animationTimeline.kill();
      };
    }
  }, [currentBreakpoint]);

  const theses = [
    {
      title:
        currentLanguage === "PT"
          ? "Caracterização geológico-geotécnica de solos para aplicação em obra de reabilitação de via ferroviária"
          : "Geological-Geotechnical Characterization of Soils for Application in Rehabilitation of Railway Tracks",
      link: "https://sigarra.up.pt/fcup/pt/pub_geral.pub_view?pi_pub_base_id=341885",
    },
    {
      title:
        currentLanguage === "PT"
          ? "Controlo da qualidade de agregados para marcação CE"
          : "Quality Control of Aggregates for CE Marking",
      link: "https://sigarra.up.pt/fcup/pt/pub_geral.pub_view?pi_pub_base_id=312186",
    },
    {
      title:
        currentLanguage === "PT"
          ? "Estudo de solos para aplicações em Geotecnia"
          : "Study of Soils for Geotechnical Applications",
      link: "https://sigarra.up.pt/fcup/pt/pub_geral.pub_view?pi_pub_base_id=442007",
    },
    {
      title:
        currentLanguage === "PT"
          ? "Deteção de armaduras em estruturas de betão"
          : "Detection of Reinforcements in Concrete Structures",
      link: "https://recipp.ipp.pt/handle/10400.22/9197",
    },
    {
      title:
        currentLanguage === "PT"
          ? "Penetração de cloretos em estruturas de betão"
          : "Chloride Penetration in Concrete Structures",
      link: "https://recipp.ipp.pt/handle/10400.22/9778",
    },
    {
      title:
        currentLanguage === "PT"
          ? "Ferramenta para integração de resultados de ensaios em modelos BIM"
          : "Tool for Integrating Test Results into BIM Models",
      link: "https://recipp.ipp.pt/handle/10400.22/12265",
    },
    {
      title:
        currentLanguage === "PT"
          ? "Prospeção de armaduras em estruturas de betão"
          : "Prospection of Reinforcements in Concrete Structures",
      link: "https://recipp.ipp.pt/handle/10400.22/16245",
    },
    {
      title:
        currentLanguage === "PT"
          ? "Durabilidade do betão e corrosão das armaduras"
          : "Concrete Durability and Reinforcement Corrosion",
      link: "https://recipp.ipp.pt/bitstream/10400.22/19493/1/LIV_DEC_PROJI_Ebook_2021.pdf",
    },
  ];

  const articles = [
    {
      title:
        currentLanguage === "PT"
          ? "Renovação da plataforma ferroviária - Uma perspetiva do controlo de qualidade"
          : "Renovation of the Railway Platform - A Perspective on Quality Control",
      link:
        process.env.PUBLIC_URL +
        "/files/renovacao-da-plataforma-ferroviaria-uma-perspetiva-de-controlo-de-qualidade.pdf",
    },
    {
      title:
        currentLanguage === "PT"
          ? "Ferramenta para integração de resultados de ensaios de controlo de qualidade de materiais em modelos BIM"
          : "Tool for Integrating Quality Control Test Results of Materials into BIM Models",
      link:
        process.env.PUBLIC_URL +
        "/files/ferramenta-para-integracao-de-resultados-de-ensaios-de-controlo-de-qualidade-de-materiais-em-modelos-bim.pdf",
    },
    {
      title:
        currentLanguage === "PT"
          ? "Estudo da evolução da resistência à compressão de betões através do método de cura de provetes a temperatura controlada"
          : "Study of the Evolution of Concrete Compression Strength Using the Controlled Temperature Curing Method",
      link:
        process.env.PUBLIC_URL +
        "/files/estudo-da-evolucao-da-resistencia-a-compressao-de-betões-atraves-do-metodo-de-cura-de-provetes-a-temperatura-controlada.pdf",
    },
    {
      title:
        currentLanguage === "PT"
          ? "Os Laboratórios na Construção Sessão 4 - 2º Painel - Cidades Inteligentes e Construção 4.0"
          : "Laboratories in Construction Session 4 - 2nd Panel - Smart Cities and Construction 4.0",
      link:
        process.env.PUBLIC_URL +
        "/files/os-laboratorios-na-construcao-sessao-4-2-painel-cidades-inteligentes-e-construcao-4-0.pdf",
    },
  ];

  const articleItems = articles.map((item, index) => {
    let article = [];
    article.push(
      <div className="flex flex-col justify-between md:w-[calc(50%-2rem)] xl:w-[calc(25%-2rem)]">
        {index === 3 && (
          <div data-aos="fade-left">
            <UndelinedQuote
              classes="text-green-1 text-right w-full hidden xl:block"
              text={
                <span>
                  {currentLanguage === "PT"
                    ? "Partilha de experiências e aplicações realizadas no contexto profissional."
                    : "Sharing experiences and applications carried out in the professional context."}
                </span>
              }
            />
          </div>
        )}
        <div
          className={classNames(
            index === 1 && "xl:mt-48",
            index === 3 && "xl:mt-28",
            "w-full rounded-sm",
          )}
          data-aos="fade-up"
          data-aos-delay={
            currentBreakpoint === "xs" || currentBreakpoint === "sm"
              ? 0
              : index < 2
              ? 0
              : 250
          }
        >
          <div className="flex overflow-hidden bg-green-1 pl-8">
            <h3 className="w-32 py-6 pr-4 text-2xl font-medium uppercase text-white">
              {currentLanguage === "PT" ? "Artigo" : "Article"}
            </h3>
            <div className="mask-right-2 animation-2 grow bg-light-5"></div>
          </div>
          <div className="lg:pb-18  relative bg-white px-8 pb-24 pt-12 md:h-64 lg:h-72 lg:pt-10">
            <p className="text-lg leading-6 text-green-3">{item.title}</p>
            <a
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              className="absolute bottom-12 right-8 text-lg font-medium uppercase text-green-1
  underline transition-colors hover:text-green-2 focus:text-green-2 active:text-green-2 active:hover:text-green-3"
            >
              Download
            </a>
          </div>
        </div>
        {index === 0 && (
          <div data-aos="fade-right" data-aos-offset="220">
            <UndelinedQuote
              classes="text-white text-right hidden xl:block translate-y-10"
              text={
                <span>
                  {currentLanguage === "PT"
                    ? "A publicação de artigos e apresentações representa um compromisso constante na partilha de conhecimento e desenvolvimentos que impulsionam o setor, fortalecendo a nossa presença no mercado."
                    : "Publishing articles and presentations represents a constant commitment to sharing knowledge and developments that drive the sector, strengthening our presence in the market."}
                </span>
              }
            />
          </div>
        )}
      </div>,
    );

    return article;
  });

  const thesisItems = theses.map((item, index) => {
    let thesis = [];

    let thesisDelay;

    if (
      currentBreakpoint === "xs" ||
      currentBreakpoint === "sm" ||
      currentBreakpoint === "md" ||
      currentBreakpoint === "lg"
    ) {
      thesisDelay = 0;
    } else {
      if (index < 3) {
        thesisDelay = index * 250;
      } else if (index < 3 * 2 - 1) {
        thesisDelay = (index - 3) * 250;
      } else {
        thesisDelay = (index - 5) * 250;
      }
    }

    thesis.push(
      <div
        className="box-border rounded-sm border-2 border-green-3 lg:w-[calc(50%-2rem)] xl:w-[calc(33.333%-2rem)]"
        data-aos="fade-up"
        data-aos-delay={thesisDelay}
      >
        <div className="flex overflow-hidden border-b-2 border-green-3 bg-green-3 pl-8">
          <h3
            className={classNames(
              index === theses.length - 1 ? "w-48" : "w-32",
              "flex h-20 w-32 items-center py-6 pr-4 text-2xl font-medium uppercase leading-tight text-white",
            )}
          >
            {index === theses.length - 1
              ? currentLanguage === "PT"
                ? "Projeto Integrado"
                : "Integrated Project"
              : currentLanguage === "PT"
              ? "Tese"
              : "Thesis"}
          </h3>
          <div className="mask-right-2 animation-2 grow bg-white"></div>
        </div>
        <div className="relative min-h-[15rem] bg-white px-8 pb-24 pt-10">
          <p className="text-lg leading-6 text-green-3">{item.title}</p>
          <a
            href={item.link}
            target="_blank"
            rel="noopener noreferrer"
            className="absolute bottom-12 right-8 text-lg font-medium uppercase text-green-1
        underline transition-colors hover:text-green-2 focus:text-green-2 active:text-green-2 active:hover:text-green-3"
          >
            {currentLanguage === "PT" ? "Saber Mais" : "Know More"}
          </a>
        </div>
      </div>,
    );

    index === 4 &&
      thesis.push(
        <div
          className="flex items-center justify-center lg:w-[calc(33.333%-2rem)]"
          data-aos="fade-up"
          data-aos-delay={250}
        >
          <p className="hidden text-left text-lg text-green-1 underline lg:block">
            {currentLanguage === "PT"
              ? "Alguns trabalhos realizados em colaboração com diversas entidades de ensino superior."
              : "Some projects carried out in collaboration with various higher education institutions."}
          </p>
        </div>,
      );

    return thesis;
  });

  return (
    <>
      <Helmet>
        <title>{currentLanguage === "PT" ? "I&D" : "I&D"} - Senqual</title>
        <meta
          name="description"
          content={
            currentLanguage === "PT"
              ? "A área de Investigação e Desenvolvimento é um dos pontos com franca aposta da SENQUAL nos últimos anos. Privilegiando a estreita colaboração com entidades de ensino superior, são realizados trabalhos em colaboração com as mesmas, disponibilizando os recursos e meios necessários para a realização de estágios e teses."
              : "Research and Development is one of SENQUAL's strong points in recent years. Focusing on close collaboration with higher education institutions, collaborative projects are carried out, providing the necessary resources and means for internships and theses."
          }
        />
      </Helmet>
      <div className="overflow-x-hidden">
        <div className="mt-14 flex flex-col bg-light-2 lg:mb-24">
          <div className="container mx-auto mb-16 px-8">
            <div
              className="mt-4 h-24 w-1/2 md:mb-8 lg:mb-0 lg:mt-12 lg:w-1/5"
              ref={introSubtitleRef}
            >
              <UndelinedQuote
                text={
                  <span>
                    {currentLanguage === "PT"
                      ? "Estreita colaboração com entidades de ensino superior."
                      : "Close collaboration with higher education institutions."}
                  </span>
                }
              />
            </div>
            <div ref={titleRef}>
              <Title
                text={<span>{currentLanguage === "PT" ? "I&D" : "I&D"} </span>}
              />
            </div>
            <div className="-mt-5 w-full items-center lg:mb-12 lg:flex">
              <div className="w-full lg:w-7/12">
                <figure
                  className="flex h-60 w-full items-center justify-center overflow-hidden bg-green-4 sm:h-80 md:h-96 lg:h-auto lg:self-end 2xl:-mt-4"
                  ref={imageContainerRef}
                >
                  <img
                    src={process.env.PUBLIC_URL + "/img/id/main.jpg"}
                    srcSet={process.env.PUBLIC_URL + "/img/id/main-2x.jpg 2x"}
                    alt={`${
                      currentLanguage === "PT" ? "I&D" : "I&D"
                    } - Senqual`}
                    className="h-full w-full object-cover"
                    ref={imageRef}
                  />
                </figure>
              </div>
              <div
                className="mt-12 w-full md:w-3/4 lg:mt-0 lg:w-5/12 lg:pl-16 2xl:w-4/12"
                ref={subtitleRef}
              >
                <Paragraph
                  text={
                    <span>
                      {currentLanguage === "PT"
                        ? "A área de Investigação e Desenvolvimento é um dos pontos com franca aposta da SENQUAL nos últimos anos. Privilegiando a estreita colaboração com entidades de ensino superior, são realizados trabalhos em colaboração com as mesmas, disponibilizando os recursos e meios necessários para a realização de estágios e teses."
                        : "Research and Development is one of SENQUAL's strong points in recent years. Focusing on close collaboration with higher education institutions, collaborative projects are carried out, providing the necessary resources and means for internships and theses."}
                    </span>
                  }
                />
              </div>
            </div>
          </div>
          <div
            className="container mx-auto flex px-8 lg:mt-16"
            data-aos="fade-up"
          >
            <ArrowSubtitle
              text={
                currentLanguage === "PT"
                  ? "Artigos Destacados"
                  : "Featured Articles"
              }
              classes="mb-16"
            />
          </div>
          <div className="relative">
            <div
              className="z-10 xl:absolute xl:bottom-0 xl:w-full"
              data-aos="fade-right"
              data-aos-offset={
                currentBreakpoint === "xs" ||
                currentBreakpoint === "sm" ||
                currentBreakpoint === "md" ||
                currentBreakpoint === "lg"
                  ? "200"
                  : "500"
              }
            >
              <div className=" md:mask-left-2 animation-1 relative -mt-8 mb-16 mr-8 bg-green-1 after:absolute after:bottom-0 after:right-0 after:h-0 after:w-0 after:rotate-90 after:-scale-x-100 after:border-r-[6rem]  after:border-t-[6rem] after:border-transparent after:border-t-light-2 after:content-[''] md:after:hidden xl:h-96 xl:w-[calc(50%-2rem)] xl:translate-y-[calc(50%-2rem)]">
                <div className="container mx-auto flex px-8 py-16">
                  <UndelinedQuote
                    classes="pr-16 text-white lg:px-4 xl:hidden"
                    text={
                      <span>
                        {currentLanguage === "PT"
                          ? "A publicação de artigos e apresentações representa um compromisso constante na partilha de conhecimento e desenvolvimentos que impulsionam o setor, fortalecendo a nossa presença no mercado."
                          : "Publishing articles and presentations represents a constant commitment to sharing knowledge and developments that drive the sector, strengthening our presence in the market."}
                      </span>
                    }
                  />
                </div>
              </div>
            </div>
            <div className="container relative z-20 mx-auto mb-16 flex flex-col px-8 lg:px-24 xl:px-8">
              <div className="flex flex-col gap-y-10 px-8 md:flex-row md:flex-wrap md:justify-between md:px-0">
                {articleItems}
              </div>
            </div>

            <div
              className="z-10 xl:absolute xl:top-0 xl:w-full"
              data-aos="fade-left"
              data-aos-offset="100"
            >
              <div className="mask-right-2 lg:mask-right animation-1 float-right mb-16 ml-8 w-[calc(100%-2rem)] bg-green-3 xl:h-96 xl:w-[calc(50%-2rem)] xl:-translate-y-[calc(50%-2rem)]">
                <div className="container mx-auto flex px-8 py-16">
                  <UndelinedQuote
                    classes="text-green-1 text-right w-full pl-16 xl:hidden"
                    text={
                      <span>
                        {currentLanguage === "PT"
                          ? "Partilha de experiências e aplicações realizadas no contexto profissional."
                          : "Sharing experiences and applications carried out in the professional context."}
                      </span>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div data-aos="fade-up">
          <img
            src="img/hat-id-mobile.svg"
            alt="Teses que acompanhamos"
            className="w-full sm:hidden"
          />
          <img
            src={process.env.PUBLIC_URL + "/img/hat-id-2.svg"}
            alt={
              currentLanguage === "PT"
                ? "Teses que acompanhamos"
                : "Theses we follow"
            }
            className="hidden w-full sm:block"
          />
        </div>

        <div className="bg-white bg-lines-id-mobile bg-[length:auto_9rem] bg-left-bottom bg-no-repeat lg:-mt-1 lg:bg-lines-id lg:bg-[length:12rem_auto] lg:bg-left">
          <div className="container mx-auto flex flex-col items-center px-8 lg:px-24">
            <h3
              className="animation-opacity z-10 mb-16 mt-8 text-center text-xl font-medium uppercase text-green-3 lg:-mt-8 lg:w-1/2"
              data-aos="fade-up"
            >
              {currentLanguage === "PT"
                ? "Repositório de faculdades de teses que acompanhamos"
                : "Repository of university theses we follow"}
            </h3>
            <div className="mb-16 flex flex-col gap-y-10 px-8 lg:mb-48 lg:flex-row lg:flex-wrap lg:gap-x-12 lg:gap-y-12">
              {thesisItems}
            </div>
            <p className="mb-24 px-16 text-center text-lg text-green-1 underline lg:hidden">
              {currentLanguage === "PT"
                ? "Alguns trabalhos realizados em colaboração com diversas entidades de ensino superior."
                : "Some projects carried out in collaboration with various higher education institutions."}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
