import React from "react";

export default function Pill({ text }) {
  return (
    <div className="pointer-events-none flex items-center gap-x-6 rounded-3xl border-2 border-green-1 p-1 pr-5">
      <img
        src={process.env.PUBLIC_URL + "/img/pill-tick.svg"}
        alt="Tick"
        className="h-auto"
      />
      <span className="text-sm uppercase leading-none text-green-3">
        "{text}"
      </span>
    </div>
  );
}
