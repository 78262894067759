import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
const Dropdown = ({ options, onSelect, currentPage }) => {
  const dropdownRef = useRef(null);
  // Define state for the dropdown visibility and selected option
  const [dropdownVisible, setDropdownVisible] = useState(false);

  // Handler to toggle dropdown visibility
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  // Close the dropdown when clicking outside the dropdown list
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="lg:hidden">
      <button
        onClick={toggleDropdown}
        className={classNames(
          "font-regular after:mask-chevron after:bg-grey-4 relative px-2 pr-6 text-xl uppercase  outline-none after:absolute after:right-0 after:top-0.5 after:block after:h-5 after:w-5  after:transition-transform after:content-['']",
          dropdownVisible && "after:-scale-y-100",
          currentPage !== false
            ? "font-medium text-green-1 underline after:bg-green-1 hover:text-green-2 hover:after:bg-green-2  focus:text-green-2 focus:after:bg-green-2 active:text-green-2 active:after:bg-green-2 active:hover:text-green-3 active:hover:after:bg-green-2"
            : "text-light-4 after:bg-light-4 hover:text-green-1 hover:after:bg-green-1  focus:text-light-4 focus:after:bg-light-4 active:text-green-1 active:after:bg-green-1 active:hover:text-green-2 active:hover:after:bg-green-2",
        )}
      >
        {currentPage === false ? "Selecionar Categoria" : currentPage.label}
      </button>
      <ul
        className={classNames(
          dropdownVisible ? "visible" : "hidden",
          "absolute left-0 right-0 mx-auto mt-2 w-72 bg-white py-3",
        )}
      >
        {options.map((option) => (
          <li
            key={option.value}
            className="flex h-12 justify-center bg-white text-lg text-light-4"
          >
            <button
              onClick={() => {
                onSelect(option);
                setDropdownVisible(false);
              }}
              className="uppercase"
            >
              {option.label}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Dropdown;
