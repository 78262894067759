import React from "react";
import classNames from "classnames";

export default function UndelinedQuote({ text, classes = false }) {
  return (
    <p
      className={classNames(
        "text-left font-bold text-green-1 underline",
        classes && classes,
      )}
    >
      {text}
    </p>
  );
}
