import classNames from "classnames";
import React from "react";

export default function Input({
  name,
  label,
  type,
  register,
  required,
  color,
  autocomplete,
}) {
  let textColor,
    borderColor,
    borderColorHover,
    borderColorActive,
    borderColorActiveHover,
    focusColor;
  if (color === "light") {
    textColor = "text-green-1";
    borderColor = "border-green-1";
    borderColorHover = "hover:border-green-2";
    borderColorActive = "active:border-green-2";
    borderColorActiveHover = "active:hover:border-green-3";
    focusColor = "focus:border-green-2";
  } else {
    textColor = "text-dark-2";
    borderColor = "border-dark-2";
    borderColorHover = "hover:border-green-1";
    borderColorActive = "active:border-green-1";
    borderColorActiveHover = "active:hover:border-green-2";
    focusColor = "focus:border-green-1";
  }

  return (
    <div className="flex flex-col">
      <label className={classNames(textColor, "mb-2 uppercase tracking-tight")}>
        {label}
        {required && "*"}
      </label>
      <input
        className={classNames(
          borderColor,
          borderColorHover,
          borderColorActive,
          borderColorActiveHover,
          focusColor,
          "border-b-[1.5px] bg-transparent text-green-3 outline-none transition-colors 2xl:text-xl",
        )}
        type={type}
        autocomplete={autocomplete}
        {...register(name, { required })}
      />
    </div>
  );
}
