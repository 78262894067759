import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useBreakpoint from "../../../../helpers/useBreakpoint";
gsap.registerPlugin(ScrollTrigger);

export default function Contador({ item, index }) {
  const currentBreakpoint = useBreakpoint();
  const containerRef = useRef(null);
  const contadorRef = useRef(null);

  useEffect(() => {
    if (
      currentBreakpoint === "sm" ||
      currentBreakpoint === "md" ||
      currentBreakpoint === "lg" ||
      currentBreakpoint === "xl" ||
      currentBreakpoint === "2xl"
    ) {
      const containerElement = containerRef.current;
      const numberElement = contadorRef.current;

      gsap.set(containerElement, { opacity: 0, y: 50 });

      // GSAP timeline for the opacity transition of the parent div and number animation
      const animationTimeline = gsap.timeline({
        scrollTrigger: {
          trigger: numberElement,
          start: "top bottom-=200",
          end: "top bottom-=300",
          scrub: 0.5,
        },
      });

      // Animation for the parent div opacity
      animationTimeline.to(containerElement, {
        opacity: 1,
        y: 0,
        duration: 1,
      });

      // Animation for the number element with a specific duration (e.g., 2 seconds)
      animationTimeline.to(
        numberElement,
        {
          innerHTML: item.quantity,
          onUpdate: () => {
            numberElement.textContent = Math.round(numberElement.innerHTML);
          },
          duration: 2, // Change this to the desired duration in seconds
        },
        "<",
      );
    }
  }, [item.quantity, currentBreakpoint]);

  return (
    <div
      className="flex w-[calc(50%-1rem)] flex-col items-center lg:w-auto"
      ref={containerRef}
    >
      <p className="mb-4 text-5xl font-semibold text-green-1 lg:mb-2 lg:text-6xl xl:mb-4 xl:text-7xl">
        +
        <span ref={contadorRef}>
          {currentBreakpoint === "sm" ||
          currentBreakpoint === "md" ||
          currentBreakpoint === "lg" ||
          currentBreakpoint === "xl" ||
          currentBreakpoint === "2xl"
            ? 0
            : item.quantity}
        </span>
      </p>
      <p
        className="text-center text-lg uppercase text-dark-2 lg:text-xl xl:text-2xl"
        dangerouslySetInnerHTML={{ __html: item.title }}
      ></p>
    </div>
  );
}
