import {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useContext,
} from "react";
import Title from "../components/global/Title";
import PortefolioGraph from "../components/specific/portefolio/PortefolioGraph";
import Modal from "react-modal";
import Dropdown from "../components/specific/portefolio/Dropdown";
import { gsap } from "gsap";
import { Helmet } from "react-helmet";
import ScrollToTop from "../helpers/ScrollToTop";
import { LanguageContext } from "../App";
import AOS from "aos";
import "aos/dist/aos.css"; // Import the CSS for AOS animations

import classNames from "classnames";
import slugify from "slugify";
import useBreakpoint from "../helpers/useBreakpoint";

export default function Portefolio() {
  const { currentLanguage } = useContext(LanguageContext);
  const currentBreakpoint = useBreakpoint();

  const [currentPage, setCurrentPage] = useState(false);
  const [firstAnimation, setFirstAnimation] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [moreLogosVisible, setMoreLogosVisible] = useState(false);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  const titleRef = useRef(null);
  const menuRef = useRef(null);
  const textRef = useRef(null);
  const graphRef = useRef(null);
  const legendaRef = useRef(null);

  const toggleMoreLogosVisible = () => {
    setMoreLogosVisible(!moreLogosVisible); // toggle the menu visibility
  };

  // Initial Animation
  useLayoutEffect(() => {
    if (
      currentBreakpoint === "sm" ||
      currentBreakpoint === "md" ||
      currentBreakpoint === "lg" ||
      currentBreakpoint === "xl" ||
      currentBreakpoint === "2xl"
    ) {
      const initialState = {
        opacity: 0,
        y: 50,
      };

      const finalState = {
        opacity: 1,
        y: 0,
        duration: 0.5,
        ease: "power2.out",
      };

      // Set initial opacity to 0 before the animation starts
      gsap.set(titleRef.current, initialState);
      gsap.set(menuRef.current, initialState);
      gsap.set(textRef.current, initialState);
      gsap.set(graphRef.current, initialState);
      gsap.set(legendaRef.current, initialState);

      // GSAP animation timeline
      const animationTimeline = gsap.timeline();

      animationTimeline.to(titleRef.current, {
        opacity: 1,
        y: 0,
        duration: 0.5,
        ease: "power2.out",
        delay: 0.5,
      });

      animationTimeline.to(menuRef.current, finalState);

      animationTimeline.to(textRef.current, finalState);

      animationTimeline.to(graphRef.current, finalState);

      animationTimeline.to(legendaRef.current, finalState);

      return () => {
        animationTimeline.kill();
      };
    }
  }, [currentBreakpoint]);

  useEffect(() => {
    AOS.init({ disable: "mobile" });

    // Event listener to update viewport width when the window is resized
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [currentPage]); // Empty dependency array ensures that the effect runs only once after the initial render

  let storedScrollPosition;

  const openModal = (img) => {
    // Store the current scroll position
    const storedScrollPosition = window.scrollY;
    setModalIsOpen(img);

    // Disable body scrolling when the modal is open
    document.body.style.position = "fixed";
    document.body.style.top = `-${storedScrollPosition}px`;
  };

  const closeModal = () => {
    // Enable body scrolling and restore the scroll position after closing the modal
    const storedScrollPosition = parseInt(document.body.style.top || "0", 10);
    document.body.style.position = "";
    document.body.style.top = "";
    window.scrollTo(0, -storedScrollPosition);
    setModalIsOpen(false);
  };

  const portefolioData = [
    {
      title: currentLanguage === "PT" ? "Pavimentos" : "Pavements",
      img: "pavimentos",
      color: "#004E38",
      projects: [
        {
          title:
            currentLanguage === "PT"
              ? "Pavimento Industrial (Mercadona) – Antequera"
              : "Industrial Pavement (Mercadona) – Antequera",
          client: "MG Soleras Industriales",
          year: 2012,
          endYear: null,
          kind: currentLanguage === "PT" ? "Equipa em obra" : "On-site Team",
          img: false,
        },
        {
          title:
            currentLanguage === "PT"
              ? "Pavimento Industrial (Michelin) – Vitoria"
              : "Industrial Pavement (Michelin) – Vitoria",
          client: "M. G. Soleras Industriales",
          year: 2015,
          endYear: null,
          kind: currentLanguage === "PT" ? "Equipa em obra" : "On-site Team",
          img: "p2",
        },
        {
          title:
            currentLanguage === "PT"
              ? "Pavimento Industrial (Logicor) – Valência"
              : "Industrial Pavement (Logicor) – Valencia",
          client: "Pavimentos Industriales e Ingeniería",
          year: 2020,
          endYear: null,
          kind: currentLanguage === "PT" ? "Equipa em obra" : "On-site Team",
          img: "p3",
        },
        {
          title:
            currentLanguage === "PT"
              ? "Pavimento Industrial (Merlin) – Castanheira do Ribatejo"
              : "Industrial Pavement (Merlin) – Castanheira do Ribatejo",
          client: "TPB - Tecnologia em pavimentos e construção, S.A",
          year: 2021,
          endYear: null,
          kind: currentLanguage === "PT" ? "Equipa em obra" : "On-site Team",
          img: "p4",
        },
        {
          title:
            currentLanguage === "PT"
              ? "Pavimento Industrial (Amazon) – Ilhescas"
              : "Industrial Pavement (Amazon) – Ilhescas",
          client: "Pavimentos Industriales e Ingeniería",
          year: 2021,
          endYear: null,
          kind: currentLanguage === "PT" ? "Equipa em obra" : "On-site Team",
          img: "p5",
        },
        {
          title:
            currentLanguage === "PT"
              ? "Pavimento Industrial (GSE) – Azambuja"
              : "Industrial Pavement (GSE) – Azambuja",
          client: "TPB - Tecnologia em pavimentos e construção, S.A.",
          year: 2022,
          endYear: null,
          kind: currentLanguage === "PT" ? "Equipa em obra" : "On-site Team",
          img: "p6",
        },
        {
          title:
            currentLanguage === "PT"
              ? "Pavimento Industrial (VGP) – Sevilha"
              : "Industrial Pavement (VGP) – Seville",
          client: "Pavimentos Industriales e Ingeniería",
          year: 2022,
          endYear: null,
          kind: currentLanguage === "PT" ? "Equipa em obra" : "On-site Team",
          img: "p7",
        },
        {
          title:
            currentLanguage === "PT"
              ? "Pavimento Industrial (Garland) – Valadares"
              : "Industrial Pavement (Garland) – Valadares",
          client: "Garcia Garcia, SA",
          year: 2022,
          endYear: null,
          kind:
            currentLanguage === "PT"
              ? "Ensaios realizados no Laboratório Central e Equipa em obra"
              : "Tests conducted in Central Laboratory and On-site Team",
          img: "p8",
        },
      ],
    },
    {
      title: currentLanguage === "PT" ? "Barragens" : "Dams",
      img: "barragens",
      color: "#82C341",
      projects: [
        {
          title:
            currentLanguage === "PT"
              ? "Descarregador de cheias Complementar - Barragem da Paradela"
              : "Flood Overflow - Paradela Dam",
          client: "Teixeira Duarte",
          year: 2009,
          endYear: 2011,
          kind:
            currentLanguage === "PT"
              ? "Instalação de laboratório de obra"
              : "On-site Laboratory Installation",
          img: "b1",
        },
        {
          title:
            currentLanguage === "PT"
              ? "Reforço de potência - Salamonde II"
              : "Power Enhancement - Salamonde II",
          client: "Construsalamonde, ACE",
          year: 2011,
          endYear: 2015,
          kind:
            currentLanguage === "PT"
              ? "Instalação de laboratório de obra"
              : "On-site Laboratory Installation",
          img: "b2",
        },
        {
          title:
            currentLanguage === "PT"
              ? "Aproveitamento Hidroelétrico Crestuma-Lever (descarregador)"
              : "Hydroelectric Power Plant Crestuma-Lever (Overflow Outlet)",
          client: "Teixeira Duarte",
          year: 2012,
          endYear: 2013,
          kind:
            currentLanguage === "PT"
              ? "Ensaios realizados no Laboratório Central e Equipa em obra"
              : "Tests conducted in Central Laboratory and On-site Team",
          img: "b3",
        },
        {
          title:
            currentLanguage === "PT"
              ? "Descarregador de cheias Complementar – Barragem de Caniçada"
              : "Flood Overflow - Caniçada Dam",
          client: "Mota-Engil e Somague Engenharia S.A.",
          year: 2014,
          endYear: 2018,
          kind:
            currentLanguage === "PT"
              ? "Instalação de laboratório de obra"
              : "On-site Laboratory Installation",
          img: "b4",
        },
        {
          title:
            currentLanguage === "PT"
              ? "Aproveitamentos Hidroelétricos de Gouvães, Alto Tâmega e Daivões"
              : "Hydroelectric Power Plants Gouvães, Alto Tâmega, and Daivões",
          client: "Iberdrola",
          year: 2017,
          endYear: currentLanguage === "PT" ? "até à presente data" : "now",
          kind:
            currentLanguage === "PT"
              ? "Instalação de laboratório de obra"
              : "On-site Laboratory Installation",
          img: "b5",
        },
      ],
    },
    {
      title: currentLanguage === "PT" ? "Ferrovia" : "Railway",
      img: "ferrovia",
      color: "#C0E992",
      projects: [
        {
          title:
            currentLanguage === "PT"
              ? "Linha do Douro – Troço Caíde/Marco"
              : "Douro Line – Section Caíde/Marco",
          client: "Obrecol",
          year: 2009,
          endYear: 2010,
          kind:
            currentLanguage === "PT"
              ? "Ensaios realizados no Laboratório Central e Equipa em obra"
              : "Tests conducted in Central Laboratory and On-site Team",
          img: "f1",
        },
        {
          title:
            currentLanguage === "PT"
              ? "Linha do Minho, troço entre Viana do Castelo e Valença"
              : "Minho Line, section between Viana do Castelo and Valença",
          client: "Consórcio SacyrNeopul / Fergrupo",
          year: 2018,
          endYear: 2021,
          kind:
            currentLanguage === "PT"
              ? "Ensaios realizados no Laboratório Central e Equipa em obra"
              : "Tests conducted in Central Laboratory and On-site Team",
          img: "f2",
        },
        {
          title:
            currentLanguage === "PT"
              ? "Linha do Norte. Subtroço 3.3 – Ovar/Gaia"
              : "North Line. Subsection 3.3 – Ovar/Gaia",
          client: "DST/AZVI Linha Férrea, ACE",
          year: 2020,
          endYear: currentLanguage === "PT" ? "até à presente data" : "now",
          kind:
            currentLanguage === "PT"
              ? "Ensaios realizados no Laboratório Central e Equipa em obra"
              : "Tests conducted in Central Laboratory and On-site Team",
          img: "f3",
        },
        {
          title:
            currentLanguage === "PT"
              ? "Modernização da Linha da Beira Alta (Pampilhosa-Santa Comba Dão e Concordância da Mealhada)"
              : "Modernization of Beira Alta Line (Pampilhosa-Santa Comba Dão and Concordância da Mealhada)",
          client: "COMSA Sucursal em Portugal",
          year: 2020,
          endYear: currentLanguage === "PT" ? "até à presente data" : "now",
          kind:
            currentLanguage === "PT"
              ? "Instalação de laboratório de obra"
              : "On-site Laboratory Installation",
          img: "f4",
        },
        {
          title:
            currentLanguage === "PT"
              ? "Metro: Linha Amarela, Santo Ovídio a Vila D’ Este"
              : "Metro: Yellow Line, Santo Ovídio to Vila D’ Este",
          client: "Ferrovial / Alberto Couto Alves - Linha Amarela, ACE",
          year: 2021,
          endYear: currentLanguage === "PT" ? "até à presente data" : "now",
          kind:
            currentLanguage === "PT"
              ? "Ensaios realizados no Laboratório Central e Equipa em obra"
              : "Tests conducted in Central Laboratory and On-site Team",
          img: "f5",
        },
        {
          title:
            currentLanguage === "PT"
              ? "Metro: Linha Circular, Praça da Liberdade – Casa da Música"
              : "Metro: Circular Line, Praça da Liberdade to Casa da Música",
          client: "Ferrovial / Alberto Couto Alves - Linha Circular, ACE",
          year: 2021,
          endYear: currentLanguage === "PT" ? "até à presente data" : "now",
          kind:
            currentLanguage === "PT"
              ? "Ensaios realizados no Laboratório Central e Equipa em obra"
              : "Tests conducted in Central Laboratory and On-site Team",
          img: "f6",
        },
      ],
    },

    {
      title: currentLanguage === "PT" ? "Rodovia" : "Highways",
      img: "rodovia",
      color: "#798F54",
      projects: [
        {
          title:
            currentLanguage === "PT"
              ? "Auto-estrada Transmontana"
              : "Transmontana Highway",
          client: "CAETXXI",
          year: 2011,
          endYear: 2012,
          kind:
            currentLanguage === "PT"
              ? "Ensaios realizados no Laboratório Central e Equipa em obra"
              : "Tests conducted in Central Laboratory and On-site Team",
          img: "r1",
        },
        {
          title:
            currentLanguage === "PT"
              ? "IP3 – Ponte sobre a Foz do Rio Dão"
              : "IP3 – Bridge over Rio Dão Estuary",
          client: "CASAIS",
          year: 2014,
          endYear: 2015,
          kind:
            currentLanguage === "PT"
              ? "Instalação de laboratório de obra"
              : "On-site Laboratory Installation",
          img: "r2",
        },
        {
          title:
            currentLanguage === "PT"
              ? "IP4(A4) – Túnel do Marão"
              : "IP4(A4) – Marão Tunnel",
          client: "EPOS / Teixeira Duarte",
          year: 2015,
          endYear: 2017,
          kind:
            currentLanguage === "PT"
              ? "Instalação de laboratório de obra"
              : "On-site Laboratory Installation",
          img: "r3",
        },
        {
          title:
            currentLanguage === "PT"
              ? "Beneficiação do Pavimento - Concessão Grande Porto"
              : "Pavement Improvement - Grande Porto Concession",
          client: "Quadrante",
          year: 2020,
          endYear: 2021,
          kind:
            currentLanguage === "PT"
              ? "Ensaios realizados no Laboratório Central"
              : "Tests conducted in Central Laboratory",
          img: "r4",
        },
        {
          title:
            currentLanguage === "PT"
              ? "Sistema de Mobilidade do Mondego"
              : "Mondego Mobility System",
          client: "COMSA Sucursal em Portugal",
          year: 2020,
          endYear: currentLanguage === "PT" ? "até à presente data" : "now",
          kind:
            currentLanguage === "PT"
              ? "Ensaios realizados no Laboratório Central e Equipa em obra"
              : "Tests conducted in Central Laboratory and On-site Team",
          img: "r5",
        },
      ],
    },
    {
      title:
        currentLanguage === "PT"
          ? "Inspeção / Reabilitação"
          : "Inspection / Rehabilitation",
      img: "inspecao-reabilitacao",
      color: "#C0C179",
      projects: [
        {
          title:
            currentLanguage === "PT"
              ? "Análise de patologias de um pavimento industrial"
              : "Analysis of Industrial Pavement Pathologies",
          client: "Domingos da Silva Teixeira",
          year: 2007,
          endYear: null,
          kind: currentLanguage === "PT" ? "Equipa em obra" : "On-site Team",
          img: "i1",
        },
        {
          title:
            currentLanguage === "PT"
              ? "Subconcessão Auto-Estrada Transmontana"
              : "Transmontana Highway Subconcession",
          client: "CAET XXI Construções, ACE",
          year: 2011,
          endYear: null,
          kind:
            currentLanguage === "PT"
              ? "Ensaios realizados no Laboratório Central e Equipa em obra"
              : "Tests conducted in Central Laboratory and On-site Team",
          img: "i2",
        },
        {
          title:
            currentLanguage === "PT"
              ? "A44: Inspeção de passagem superior e passagem inferior"
              : "A44: Overpass and Underpass Inspection",
          client: "Estrutovia",
          year: 2016,
          endYear: null,
          kind: currentLanguage === "PT" ? "Equipa em obra" : "On-site Team",
          img: "i3",
        },
        {
          title:
            currentLanguage === "PT"
              ? "Inspeção de bancada de Estádio"
              : "Stadium Bench Inspection",
          client: "Rio Ave Clube (Em parceria com ISEP)",
          year: 2019,
          endYear: null,
          kind:
            currentLanguage === "PT"
              ? "Ensaios realizados no Laboratório Central e Equipa em obra"
              : "Tests conducted in Central Laboratory and On-site Team",
          img: "i4",
        },
        {
          title:
            currentLanguage === "PT"
              ? "Ensaios em edifício – Albufeira"
              : "Building Tests – Albufeira",
          client: "Buildgest - Fiscalização e Gestão de Obras Lda",
          year: 2019,
          endYear: null,
          kind:
            currentLanguage === "PT"
              ? "Ensaios realizados no Laboratório Central e Equipa em obra"
              : "Tests conducted in Central Laboratory and On-site Team",
          img: "i5",
        },
        {
          title:
            currentLanguage === "PT"
              ? "Inspeção de Estádio Municipal"
              : "Municipal Stadium Inspection",
          client: "Câmara Municipal de Cinfães",
          year: 2020,
          endYear: null,
          kind:
            currentLanguage === "PT"
              ? "Ensaios realizados no Laboratório Central e Equipa em obra"
              : "Tests conducted in Central Laboratory and On-site Team",
          img: "i6",
        },
      ],
    },
  ];

  const legendaItems = portefolioData.map((item, index) => {
    return (
      <button
        onClick={() => setCurrentPage({ value: index, label: item.title })}
      >
        <li className="flex items-center gap-x-6 text-left text-dark-2 hover:text-green-1 focus:text-green-1 active:text-green-1 active:hover:text-green-2">
          <div
            className="h-4 w-4"
            style={{ backgroundColor: item.color }}
          ></div>
          {item.title}
        </li>
      </button>
    );
  });

  let portefolioOptions = portefolioData.map((item, index) => {
    return { value: index, label: item.title };
  });

  const portefolioButtons = portefolioData.map((item, index) => {
    return (
      <button
        type="button"
        onClick={() => setCurrentPage({ value: index, label: item.title })}
        className={classNames(
          currentPage.value === index
            ? "font-medium text-green-1 underline"
            : "font-regular text-light-4",
          "hidden px-2 text-xl uppercase outline-none hover:text-green-1 focus:text-green-1 active:text-green-1 active:hover:text-green-2 lg:block",
        )}
      >
        {item.title}
      </button>
    );
  });

  const portefolioPages = portefolioData.map((page, index) => {
    return (
      currentPage.value === index && (
        <>
          <ScrollToTop />
          <div className="mb-24 mt-12 flex flex-col gap-y-10 bg-light-2 lg:mt-20">
            {page.projects
              .sort(function (a, b) {
                return b.year - a.year;
              })
              .map((item, index) => {
                return (
                  <div
                    className="relative flex flex-col bg-light-6"
                    data-aos="fade-in"
                  >
                    <div className="flex">
                      <div
                        data-aos={
                          currentBreakpoint === "xs" ||
                          currentBreakpoint === "sm" ||
                          currentBreakpoint === "md"
                            ? "fade-up"
                            : index % 2 === 0
                            ? "fade-left"
                            : "fade-right"
                        }
                        data-aos-delay="500"
                        className={classNames(
                          index % 2 === 0 && "md:ml-auto",
                          "relative z-20 h-64 w-full md:h-[unset] md:w-[calc(40%-1rem)] lg:w-[calc(50%-1rem)]",
                        )}
                      >
                        <div
                          className={classNames(
                            index % 2 === 0
                              ? "after:md:bg-gradient-to-r"
                              : "after:md:bg-gradient-to-l",
                            item.img !== false
                              ? "bg-green-3 after:bg-gradient-to-t after:from-light-6"
                              : "bg-light-6 ",
                            "relative h-64 after:pointer-events-none after:absolute after:top-0 after:h-full after:w-full after:content-[''] md:-ml-0 md:h-96",
                          )}
                        >
                          {item.img !== false ? (
                            <a
                              href="#"
                              onClick={() => setModalIsOpen(item)}
                              className="cursor-pointer"
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/img/portefolio/${item.img}.jpg`}
                                //srcSet={process.env.PUBLIC_URL + "/img/portfolio/main-1-2x.jpg"}
                                alt={`${item.title} - Senqual`}
                                className="h-full w-full object-cover"
                              />
                            </a>
                          ) : (
                            <img
                              src={`${process.env.PUBLIC_URL}/img/portefolio/senqual.svg`}
                              alt={`${item.title} - Senqual`}
                              className="h-full w-full object-contain object-bottom"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className={classNames(
                        "container mx-auto h-full w-full px-8 pb-16 pt-8 md:absolute md:left-0 md:right-0 md:flex md:justify-between md:pb-0 md:pt-0",
                      )}
                    >
                      <div
                        data-aos={
                          currentBreakpoint === "xs" ||
                          currentBreakpoint === "sm" ||
                          currentBreakpoint === "md"
                            ? "fade-up"
                            : index % 2 === 0
                            ? "fade-right"
                            : "fade-left"
                        }
                        data-aos-delay="500"
                        className={classNames(
                          index % 2 !== 0 && "md:ml-auto",
                          "flex flex-col justify-center md:w-[calc(60%-1rem)] md:py-12 lg:w-[calc(50%-1rem)]",
                        )}
                      >
                        <p className="mb-1 uppercase text-dark-2">
                          {currentLanguage === "PT"
                            ? "Designação:"
                            : "Description:"}
                        </p>

                        <h3 className="mb-10 text-xl font-medium uppercase text-green-3">
                          {item.title}
                        </h3>
                        <div className="mb-6">
                          <p className="uppercase text-dark-2">
                            {currentLanguage === "PT" ? "Ano:" : "Year:"}
                            &nbsp;&nbsp;&nbsp;
                            <span className="font-medium">
                              {item.year}
                              {item.endYear !== null
                                ? " - " + item.endYear
                                : ""}
                            </span>
                          </p>
                        </div>
                        <div className="mb-6">
                          <p className="uppercase text-dark-2">
                            {currentLanguage === "PT" ? "Cliente:" : "Client:"}
                            &nbsp;&nbsp;&nbsp;
                            <span className="font-medium">{item.client}</span>
                          </p>
                        </div>
                        <div className="flex">
                          <p className="uppercase text-dark-2">
                            {currentLanguage === "PT" ? "Trabalhos:" : "Works:"}
                            &nbsp;&nbsp;&nbsp;
                            <span className="font-medium">{item.kind}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </>
      )
    );
  });

  const handleSelectChange = (event) => {
    console.log(event);
    setCurrentPage(event);
  };

  const logosMobile = [
    "Somague Engenharia",
    "Martifer Group",
    "EDP",
    "Teixeira Duarte Engenharia e Construções S.A.",
    "LIDL",
    "Tbetão",
    "Ancorpor Geotecnia e Fundações",
    "Ascendi",
    "Conduril",
    "Mota Engil",
  ];

  const logos = [
    "Ferrovial",
    "Buildgest",
    "LIDL",
    "EDP",
    "Mota Engil",
    "Iberdrola",
    "EngGeo",
    "Comsa Corporación - Fergrupo",
    "Ancorpor",
    "Domingos da Silva Teixeira S.A.",
    "Teixeira Duarte Engenharia e Construções S.A.",
    "Ascendi",
    "ACA Grupo",
    "Grupo TPB",
    "NCREP",
    "Grupo Casais",
    "Pavieste",
    "A400",
    "Almina",
    "Grupo Alexandre Barbosa Borges",
    "ANA - Aeroportos de Portugal",
    "Ângulo Recto Construções",
    "Britevi",
    "Bureau Veritas",
    "Cobelba",
    "CJR Renewables",
    "Cofra Build",
    "Estrutovia",
    "EQS",
    "Costa Almeida Ambiente",
    "Magna",
    "Mecanotubo",
    "EPOS",
    "FDO Grupo",
    "Betãoconcept",
    "VSL",
    "Omatapalo",
    "Construtora do Tâmega",
    "Tecnovia",
    "Solei",
    "Garcia Garcia",
    "Ferreira Build Power",
    "SACYR Neopul",
    "Granitos do Castro",
    "Grupo Parapedra",
    "Gabriel Couto",
    "Unibetão",
    "Rebuild Solutions",
    "Vibeiras",
    "Soares da Costa",
    "Lucios",
    "Geona",
    "SACYR Somague",
    "ASA Pedreiras",
    "Amândio Carvalho",
    "Martifer Group",
    "RRC",
    "Lena Construções",
    "ISQ",
    "Norton Edifícios Industriais",
    "Restradas",
    "Quadrante",
    "Granbeira",
    "Limpacanal",
    "Namesma Inertes",
    "Megasa",
    "Conduril",
    "Edifer",
  ];

  const logoItems = logos.map((logo, index) => {
    return [
      <div
        className={classNames(
          "w-1/3 transition-all md:w-1/5 lg:w-auto",
          index > 16 &&
            index <= 9 &&
            !moreLogosVisible &&
            "pointer-events-none h-0 opacity-0 lg:h-auto lg:opacity-100",
          index > 16 &&
            !moreLogosVisible &&
            "pointer-events-none h-0 opacity-0",
        )}
      >
        <img
          key={index}
          src={`${process.env.PUBLIC_URL}/img/portefolio/logos/${slugify(logo, {
            lower: true,
            remove: /[*+~.()'"!:@]/g,
          })}-logo.png`}
          srcSet={`${process.env.PUBLIC_URL}/img/portefolio/logos/${slugify(
            logo,
            { lower: true, remove: /[*+~.()'"!:@]/g },
          )}-logo-2x.png 2x`}
          alt={`${logo} - ${
            currentLanguage === "PT" ? "Parceiro" : "Partner"
          } - Senqual Group`}
          className="mb-12"
        />
      </div>,
      [2, 5, 8, 11, 14, 16, 19, 22, 25, 28, 31].includes(index) && (
        <div className="m-0 hidden h-0 basis-full md:block lg:hidden"></div>
      ),
      [3, 16].includes(index) && (
        <div className="m-0 hidden h-0 basis-full lg:block xl:hidden"></div>
      ),
      [5, 10, 16, 22, 27, 58, 63].includes(index) && (
        <div className="m-0 hidden h-0 basis-full xl:block"></div>
      ),

      /*index === 9 && <div className="m-0 hidden h-0 basis-full lg:block"></div>,
      index === 14 && (
        <div className="m-0 hidden h-0 basis-full lg:block"></div>
      ),*/
    ];
  });

  const imageMenuItems = portefolioData.map((item, index) => {
    return (
      <div
        className={classNames(
          [1, 2, 3].includes(index)
            ? "order-0 w-[calc(33%-calc(1.5rem/3))]"
            : "order-2 w-[calc(50%-0.375rem)]",
          "hover:z-20 lg:order-none lg:w-[calc(20%-1.2rem)]",
        )}
        data-aos="fade-up"
        data-aos-delay={
          currentBreakpoint === "xs" ||
          currentBreakpoint === "sm" ||
          currentBreakpoint === "md"
            ? 0
            : index * 300
        }
      >
        <button
          onClick={() => setCurrentPage({ value: index, label: item.title })}
          className={classNames(
            "relative block w-full cursor-pointer bg-green-1 text-left outline-none drop-shadow-[8px_4px_26px_rgba(0,_0,_0,_0)] transition-all after:absolute after:left-0 after:top-0 after:h-full after:w-full after:bg-gradient-to-t after:from-green-3  hover:z-20 focus:z-20 hover:lg:scale-125 hover:lg:drop-shadow-[8px_4px_26px_rgba(0,_0,_0,_0.25)] focus:lg:scale-125 focus:lg:drop-shadow-[8px_4px_26px_rgba(0,_0,_0,_0.25)]",
            index === 0 && "origin-left",
            index === 0 && "origin-left",
            index === portefolioData.length - 1 && "origin-right",
          )}
        >
          <img
            src={process.env.PUBLIC_URL + `/img/portefolio/${item.img}.jpg`}
            srcSet={
              process.env.PUBLIC_URL + `/img/portefolio/${item.img}-2x.jpg 2x`
            }
            alt={`${item.title} - Senqual`}
            className="hidden h-auto w-full lg:block"
          />
          <img
            src={
              process.env.PUBLIC_URL +
              `/img/portefolio/${slugify(item.img, {
                lower: true,
                remove: /[*+~.()'"!:@/]/g,
              })}-mobile-2x.jpg`
            }
            srcSet={
              process.env.PUBLIC_URL +
              `/img/portefolio/${slugify(item.img, {
                lower: true,
                remove: /[*+~.()'"!:@/]/g,
              })}-mobile-2x.jpg 2x`
            }
            alt={`${item.title} - Senqual`}
            className="h-auto w-full lg:hidden"
          />
          <div className="absolute left-0 top-0 flex h-full w-full items-end lg:block">
            <div className="relative h-full w-full">
              <h3
                className="absolute bottom-0 z-20 w-full py-4 text-center font-bold uppercase leading-tight text-white md:px-6 md:py-6 lg:right-0 lg:float-right lg:h-full lg:w-auto lg:rotate-180 lg:py-10 lg:text-left lg:text-2xl 2xl:text-4xl"
                style={{
                  writingMode:
                    currentBreakpoint === "xs" ||
                    currentBreakpoint === "sm" ||
                    currentBreakpoint === "md"
                      ? ""
                      : "vertical-rl",
                }}
              >
                {item.title}
              </h3>
            </div>
          </div>
        </button>
      </div>
    );
  });

  return (
    <>
      <Helmet>
        <title>
          {currentLanguage === "PT" ? "Portefólio" : "Portfolio"} - Senqual
        </title>
        <meta
          name="description"
          content={
            currentLanguage === "PT"
              ? "Cada projeto, uma história de sucesso. Mais 1200 obras controladas pela nossa equipa de profissionais especializados."
              : "Each project, a success story. Over 1200 projects controlled by our team of specialized professionals."
          }
        />
      </Helmet>
      <div className="mt-14 flex flex-col bg-light-2 ">
        <div className="container mx-auto px-8">
          <div className="h-6 w-1/2 md:mb-8 lg:mb-0 lg:mt-12 lg:w-1/5"></div>
          <div ref={titleRef}>
            <Title
              text={
                <span>
                  {currentLanguage === "PT" ? "Portefólio" : "Portfolio"}
                </span>
              }
            />
          </div>
          <nav
            className="relative z-10 mt-6 flex flex-col items-center gap-y-3 md:mb-12 md:mt-8 md:flex-row md:justify-around lg:mb-0 lg:justify-between xl:justify-center xl:gap-x-10"
            ref={menuRef}
          >
            <button
              type="button"
              onClick={() => {
                firstAnimation && setFirstAnimation(false);
                setCurrentPage(false);
              }}
              className={classNames(
                currentPage === false
                  ? "font-medium text-green-1 underline"
                  : "font-regular text-light-4",
                "px-2 text-xl uppercase outline-none hover:text-green-1 focus:text-green-1 active:text-green-1 active:hover:text-green-2",
              )}
            >
              Início
            </button>
            {portefolioButtons}
            <Dropdown
              options={portefolioOptions}
              onSelect={handleSelectChange}
              currentPage={currentPage !== undefined ? currentPage : false}
            />
          </nav>
        </div>
      </div>
      {currentPage === false && (
        <>
          <div className="flex flex-col bg-light-2">
            <div className="container relative z-0 mx-auto px-8">
              <div className="mb-20 flex flex-col flex-wrap items-center md:flex-row lg:mb-36 lg:mt-40">
                <div
                  class="px-8 md:w-full lg:w-1/4 lg:px-0"
                  ref={textRef}
                  {...(!firstAnimation ? { "data-aos": "fade-up" } : {})}
                >
                  <h3 className="mb-3 mt-12 text-center text-xl font-medium uppercase text-green-3 md:mb-1 md:mt-0 lg:text-left">
                    {currentLanguage === "PT"
                      ? "Cada projeto, uma história de sucesso"
                      : "Each project, a success story"}
                  </h3>
                  <p className="mb-16 text-center text-dark-4 lg:px-0 lg:text-left">
                    {currentLanguage === "PT"
                      ? "+1200 obras controladas pela nossa equipa de profissionais especializados."
                      : "+1200 projects controlled by our team of specialized professionals."}
                  </p>
                </div>
                <div
                  className="flex flex-col items-center md:w-2/3 md:items-start lg:w-2/4 lg:items-center"
                  {...(!firstAnimation ? { "data-aos": "fade-up" } : {})}
                  ref={graphRef}
                >
                  <PortefolioGraph
                    width={370 > viewportWidth - 64 ? viewportWidth - 64 : 370}
                    height={370 > viewportWidth - 64 ? viewportWidth - 64 : 370}
                    data={[100, 75, 40, 20, 10]}
                    items={["30%", "20%", "18%", "8%", "4%"]}
                    delay={
                      currentBreakpoint === "sm" ||
                      currentBreakpoint === "md" ||
                      currentBreakpoint === "lg" ||
                      currentBreakpoint === "xl" ||
                      currentBreakpoint === "2xl"
                        ? firstAnimation
                        : false
                    }
                  />
                  <div className="text-dm mb-16 mt-8 h-6 w-4/5 max-w-[370px] rounded-[100%] bg-light-3 md:mb-0"></div>
                </div>
                <ul
                  className="mx-auto flex w-1/2 flex-col gap-y-4 md:w-1/3 lg:w-1/4"
                  ref={legendaRef}
                  {...(!firstAnimation ? { "data-aos": "fade-up" } : {})}
                >
                  {legendaItems}
                </ul>
              </div>
            </div>
          </div>
          <div className="mb-24 flex flex-wrap justify-between gap-x-3 gap-y-3 lg:mb-36 lg:gap-x-6">
            {imageMenuItems}
          </div>
          <div className="flex flex-col bg-light-2">
            <div className="container mx-auto px-8">
              <h2
                className="relative z-10 px-8 text-center text-4xl font-bold uppercase text-green-1 md:text-5xl lg:text-6xl"
                data-aos="fade-up"
              >
                {currentLanguage === "PT"
                  ? "Conheça os nossos clientes"
                  : "Meet our clients"}
              </h2>
            </div>
          </div>
          <div
            className="-mt-10 flex flex-col bg-white md:-mt-6 lg:-mt-8"
            data-aos="fade-up"
          >
            <div className="container mx-auto flex flex-col items-center px-8 pt-20">
              <p
                className="mb-16 text-center text-dark-2 lg:w-1/2"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                {currentLanguage === "PT" ? (
                  <>
                    Na Senqual, orgulhámo-nos de servir uma ampla gama de
                    clientes de renome. É por meio do nosso compromisso pela
                    excelência e por parcerias sólidas que fomentamos a nossa
                    reputação.
                  </>
                ) : (
                  <>
                    At Senqual, we pride ourselves on serving a wide range of
                    renowned clients. It is through our commitment to excellence
                    and strong partnerships that we foster our reputation.
                  </>
                )}
              </p>
              <div
                className="flex flex-wrap items-center justify-center gap-x-10 md:justify-around"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                {logoItems}
              </div>

              <button
                className={classNames(
                  moreLogosVisible && "after:-scale-y-100",
                  "after:mask-chevron group relative mb-32 ml-auto mt-6 flex text-[15px] font-medium uppercase text-green-1 underline transition-transform after:relative after:h-5 after:w-5 after:bg-green-1 after:content-[''] hover:text-green-2 after:hover:bg-green-2 focus:text-green-2 after:focus:bg-green-2 active:text-green-2 after:active:bg-green-2 active:hover:text-green-3 after:active:hover:bg-green-3",
                )}
                type="button"
                onClick={() => toggleMoreLogosVisible()}
              >
                {currentLanguage === "PT" ? (
                  <>
                    Conheça {moreLogosVisible ? "menos" : "mais"} dos nossos
                    clientes
                  </>
                ) : (
                  <>
                    Discover {moreLogosVisible ? "less" : "more"} of our clients
                  </>
                )}
              </button>
            </div>
          </div>
        </>
      )}

      {portefolioPages}
      <Modal
        className="absolute inset-0 flex h-screen justify-center overflow-hidden rounded-[3px] p-8 outline-none transition-opacity"
        overlayClassName="fixed inset-0 bg-[#000] bg-opacity-75 z-50 transition-opacity"
        closeTimeoutMS={300}
        isOpen={modalIsOpen !== false}
        onRequestClose={closeModal}
        bodyOpenClassName="overflow-hidden"
        htmlOpenClassName="overflow-hidden"
        appElement={document.getElementById("root")}
      >
        <div className="absolute left-0 top-0 w-full">
          <div className="container mx-auto px-8 pt-5">
            <button
              className="mask-close float-right h-5 w-5  bg-white hover:bg-green-1 focus:bg-green-1 active:bg-green-1 active:hover:bg-green-2"
              onClick={closeModal}
            ></button>
          </div>
        </div>
        <div className="container mx-auto px-8">
          {modalIsOpen !== false && (
            <img
              src={`${process.env.PUBLIC_URL}/img/portefolio/${modalIsOpen.img}.jpg`}
              alt={modalIsOpen.title + " - Senqual"}
              className="pointer-events-none h-full w-full object-contain"
            />
          )}
        </div>
      </Modal>
    </>
  );
}
