import { useState, useLayoutEffect, useRef } from "react";
import { useLocation, Outlet } from "react-router-dom";
import Header from "../components/global/Header";
import Footer from "../components/global/Footer";
import Menu from "../components/global/Menu";
import Modal from "react-modal";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function Layout() {
  const location = useLocation();
  const main = useRef();

  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen); // toggle the menu visibility
  };

  useLayoutEffect(() => {
    if (location.pathname === "/") {
      gsap.set(".header-animation-button", { backgroundColor: "#fff" });

      gsap.set(".header-animation-dropdown-white", {
        opacity: 1,
        pointerEvents: "all",
      });
      gsap.set(".header-animation-dropdown-green", {
        opacity: 0,
        pointerEvents: "none",
      });

      const ctx = gsap.context((self) => {
        const scrollTrigger = {
          trigger: ".main-slider",
          start: "bottom top",
          end: "bottom top",
          scrub: 2,
        };
        gsap.from(".header-animation", {
          backgroundColor: "transparent",
          scrollTrigger: scrollTrigger,
        });
        gsap.to(".header-animation-dropdown-white", {
          opacity: 0,
          pointerEvents: "none",
          scrollTrigger: scrollTrigger,
        });
        gsap.to(".header-animation-dropdown-green", {
          opacity: 1,
          pointerEvents: "all",
          scrollTrigger: scrollTrigger,
        });
        gsap.to(".header-animation-button", {
          backgroundColor: "#82c341",
          scrollTrigger: scrollTrigger,
        });
        gsap.from(".header-animation-logo-1", {
          opacity: 0,
          scrollTrigger: scrollTrigger,
        });
        gsap.from(".header-animation-logo-2", {
          opacity: 1,
          scrollTrigger: scrollTrigger,
        });
      }, main); // <- Scope!
      return () => ctx.revert(); // <- Cleanup!
    } else {
      gsap.set(".header-animation-button", { backgroundColor: "#82c341" });
      gsap.set(".header-animation-dropdown-white", {
        opacity: 0,
        pointerEvents: "none",
      });
      gsap.set(".header-animation-dropdown-green", {
        opacity: 1,
        pointerEvents: "all",
      });
    }
  }, [location.pathname]);
  return (
    <div ref={main}>
      <Header toggleMenu={toggleMenu} />
      <Modal
        className="relative h-screen overflow-y-scroll bg-green-1"
        overlayClassName="fixed inset-0 z-50 h-screen"
        closeTimeoutMS={600}
        isOpen={isMenuOpen}
        onRequestClose={toggleMenu}
        bodyOpenClassName="overflow-hidden"
        htmlOpenClassName="overflow-hidden"
        appElement={document.getElementById("root")}
      >
        <Menu toggleMenu={toggleMenu} />
      </Modal>
      <Outlet />
      <Footer />
    </div>
  );
}

export default Layout;
