import classNames from "classnames";
import React from "react";

export default function Address({ title, addresses, link = false }) {
  const addressesItems = addresses.map((address, index) => {
    let href = addresses[index];

    if (link && title === "Email") {
      href = "mailto:" + href;
    } else if (link && title === "Telefone") {
      href = "tel:+351" + href;
    }

    return link ? (
      <a
        href={href}
        className={classNames(
          index !== addresses.length - 1 && "mb-1 2xl:mb-3",
          "block text-lg text-green-3 transition-colors hover:text-green-4 active:text-green-4 hover:active:underline 2xl:text-xl",
        )}
      >
        {addresses[index]}
      </a>
    ) : (
      <p
        className={classNames(
          index !== addresses.length - 1 && "mb-1 2xl:mb-3",
          "text-lg text-green-3 2xl:text-xl",
        )}
      >
        {addresses[index]}
      </p>
    );
  });

  return (
    <>
      <h3 className="mb-4 text-2xl font-bold uppercase text-green-3 2xl:mb-6">
        {title}
      </h3>
      {addressesItems}
    </>
  );
}
