import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useBreakpoint from "../../../../helpers/useBreakpoint";

gsap.registerPlugin(ScrollTrigger);

export default function ContadorSmall({ item, index }) {
  const currentBreakpoint = useBreakpoint();
  const containerRef = useRef(null);
  const contadorRef = useRef(null);

  useEffect(() => {
    if (
      currentBreakpoint === "sm" ||
      currentBreakpoint === "md" ||
      currentBreakpoint === "lg" ||
      currentBreakpoint === "xl" ||
      currentBreakpoint === "2xl"
    ) {
      const containerElement = containerRef.current;
      const numberElement = contadorRef.current;

      gsap.set(containerElement, { opacity: 0 });

      // GSAP timeline for the opacity transition of the parent div and number animation
      const animationTimeline3 = gsap.timeline({
        scrollTrigger: {
          trigger: numberElement,
          start: "top bottom-=200",
        },
      });

      // Animation for the parent div opacity
      animationTimeline3.to(containerElement, {
        opacity: 1,
        duration: 1,
      });

      // Animation for the number element with a specific duration (e.g., 2 seconds)
      animationTimeline3.to(
        numberElement,
        {
          innerHTML: item.quantity,
          onUpdate: () => {
            numberElement.textContent = Math.round(numberElement.innerHTML);
          },
          duration: 2, // Change this to the desired duration in seconds
        },
        "<",
      );

      const externalElement = document.getElementById("animated-path");

      gsap.set(externalElement, { opacity: 0 });

      // GSAP timeline for the opacity transition of the external element
      const animationTimeline2 = gsap.timeline({
        scrollTrigger: {
          trigger: numberElement,
          start: "top bottom-=200",
          onEnter: () => {
            // Add a class when ScrollTrigger start is hit
            externalElement.classList.add("animation-line"); // Replace 'yourClassName' with your desired class name
          },
        },
      });

      // Animation for the external element opacity
      animationTimeline2.to(externalElement, {
        opacity: 1,
        duration: 1,
      });
    }
  }, [item.quantity, currentBreakpoint]);

  return (
    <div
      className="mb-8 flex flex-col items-center md:mt-12 xl:mt-24"
      ref={containerRef}
    >
      <p className="mb-1 text-5xl font-semibold text-green-3">
        +
        <span ref={contadorRef}>
          {currentBreakpoint === "sm" ||
          currentBreakpoint === "md" ||
          currentBreakpoint === "lg" ||
          currentBreakpoint === "xl" ||
          currentBreakpoint === "2xl"
            ? 0
            : item.quantity}
        </span>
      </p>
      <p className="text-center font-medium uppercase text-white">
        {item.title}
      </p>
    </div>
  );
}
