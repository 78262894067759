import { Route, Routes } from "react-router-dom";
import { createContext, useState, useEffect } from "react";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Consultoria from "./pages/Consultoria";
import Sobre from "./pages/Sobre";
import ContacteNos from "./pages/ContacteNos";
import FadeTransition from "./components/transitions/FadeTransition";
import Portefolio from "./pages/Portefolio";
import PoliticasDePrivacidade from "./pages/PoliticasDePrivacidade";
import Ensaios from "./pages/Ensaios";

import ID from "./pages/ID";

export const LanguageContext = createContext();

function App() {
  const storedLanguage = localStorage.getItem("language");

  const [currentLanguage, setCurrentLanguage] = useState(
    storedLanguage || "PT",
  );

  useEffect(() => {
    const userLanguage = navigator.language || navigator.userLanguage;
    // Check if the user's language is Portuguese (Portugal)
    if (storedLanguage) {
      setCurrentLanguage(storedLanguage);
    } else if (
      userLanguage.startsWith("pt-PT") ||
      userLanguage.startsWith("pt")
    ) {
      setCurrentLanguage("PT");
    } else {
      setCurrentLanguage("EN");
    }
  }, []); // Empty dependency array ensures that this effect runs once after the initial render

  const updateLanguage = (language) => {
    setCurrentLanguage(language);
    localStorage.setItem("language", language);
  };

  return (
    <LanguageContext.Provider value={{ currentLanguage, updateLanguage }}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/consultoria" element={<Consultoria />} />
          <Route path="/sobre-nos" element={<Sobre />} />
          <Route path="/contacte-nos" element={<ContacteNos />} />
          <Route path="/portefolio" element={<Portefolio />} />
          <Route path="/id" element={<ID />} />
          <Route path="/ensaios" element={<Ensaios />} />
          <Route
            path="/politicas-de-privacidade"
            element={<PoliticasDePrivacidade />}
          />
        </Route>
      </Routes>
    </LanguageContext.Provider>
  );
}

export default App;
