import React, { useState, useEffect, useRef, useContext } from "react";
import classNames from "classnames";
import { LanguageContext } from "../../../App";
export default function LangDropdown({
  menu = false,
  greenBackground = false,
  header = false,
}) {
  const dropdownRef = useRef(null);

  const { currentLanguage, updateLanguage } = useContext(LanguageContext);

  const [dropdownVisible, setDropdownVisible] = useState(false);

  // Handler to toggle dropdown visibility
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  // Close the dropdown when clicking outside the dropdown list
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div ref={dropdownRef} className="relative">
      <button
        onClick={toggleDropdown}
        className={classNames(
          menu
            ? "text-white after:bg-white hover:text-green-1 hover:after:bg-green-1  focus:text-green-1 focus:after:bg-green-1 active:text-green-1 active:after:bg-green-1 active:hover:text-green-1 active:hover:after:bg-green-2 "
            : greenBackground
            ? "text-white after:bg-white hover:text-green-1 hover:after:bg-green-1  focus:text-green-1 focus:after:bg-green-1 active:text-green-1 active:after:bg-green-1 active:hover:text-green-1 active:hover:after:bg-green-2"
            : "text-green-3 after:bg-green-3 hover:text-green-2 hover:after:bg-green-2  focus:text-green-2 focus:after:bg-green-2 active:text-green-2 active:after:bg-green-2 active:hover:text-green-2 active:hover:after:bg-green-2",
          "after:mask-chevron after:bg-grey-4 relative px-2 pr-6  uppercase outline-none transition-colors after:absolute after:right-0 after:top-0.5 after:block after:h-5  after:w-5 after:transition-all after:content-['']",
          dropdownVisible && "after:-scale-y-100",
          header ? "text-lg" : "text-xl",
        )}
      >
        {currentLanguage}
      </button>
      <ul
        className={classNames(
          menu
            ? "-left-2 bottom-8 origin-bottom"
            : "-left-3 mx-auto origin-top",
          dropdownVisible ? "scale-y-100" : "scale-y-0",
          "absolute left-0 right-0 mt-2 w-20 rounded-sm bg-white py-3  opacity-95 transition-transform",
        )}
      >
        {["PT", "EN"].map((option) => (
          <li key={option} className="flex h-8 justify-center">
            <button
              onClick={() => {
                updateLanguage(option);
                setDropdownVisible(false);
              }}
              className="text-lg uppercase text-light-4 hover:text-green-1  focus:text-green-1  active:text-green-1 active:hover:text-green-1"
            >
              {option}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}
