import {
  useRef,
  useLayoutEffect,
  useState,
  useEffect,
  useContext,
} from "react";
import { gsap } from "gsap";
import EnsaiosSearch from "../components/specific/ensaios/EnsaiosSearch";
import EnsaiosGraph from "../components/specific/ensaios/EnsaiosGraph";
import UndelinedQuote from "../components/global/UndelinedQuote";
import Title from "../components/global/Title";
import Paragraph from "../components/global/Paragraph";
import { Helmet } from "react-helmet";
import { LanguageContext } from "../App";

import { ScrollTrigger } from "gsap/ScrollTrigger";

import AOS from "aos";
import "aos/dist/aos.css"; // Import the CSS for AOS animations
import useBreakpoint from "../helpers/useBreakpoint";

gsap.registerPlugin(ScrollTrigger);

export default function Ensaios() {
  const { currentLanguage } = useContext(LanguageContext);
  const currentBreakpoint = useBreakpoint();

  const graphRef = useRef(null);
  const [playAnimation, setPlayAnimation] = useState(false);

  const titleRef = useRef(null);
  const imageRef = useRef(null);
  const image2Ref = useRef(null);
  const imageContainerRef = useRef(null);
  const image2ContainerRef = useRef(null);
  const subtitleRef = useRef(null);
  const introSubtitleRef = useRef(null);

  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Event listener to update viewport width when the window is resized
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures that the effect runs only once after the initial render

  useLayoutEffect(() => {
    const graphElement = graphRef.current;
    const animationTimeline3 = gsap.timeline({
      scrollTrigger: {
        trigger: graphElement,
        start: "top bottom-=400",
        onEnter: () => {
          setPlayAnimation(true); // Set playAnimation to true when scrollTrigger is triggered
        },
      },
    });
    if (
      currentBreakpoint === "sm" ||
      currentBreakpoint === "md" ||
      currentBreakpoint === "lg" ||
      currentBreakpoint === "xl" ||
      currentBreakpoint === "2xl"
    ) {
      // Set initial opacity to 0 before the animation starts
      gsap.set(imageContainerRef.current, {
        scaleY: 0,
        transformOrigin: "top",
      });
      gsap.set(imageRef.current, { opacity: 0 });
      gsap.set(image2ContainerRef.current, {
        scaleY: 0,
        transformOrigin: "top",
      });
      gsap.set(image2Ref.current, { opacity: 0 });
      gsap.set(titleRef.current, { opacity: 0 });
      gsap.set(subtitleRef.current, { opacity: 0 });
      gsap.set(introSubtitleRef.current, { opacity: 0 });

      // GSAP animation timeline
      const animationTimeline = gsap.timeline();

      animationTimeline.to(titleRef.current, {
        opacity: 1,
        duration: 0.5,
        ease: "power2.out",
        delay: 0.5,
      });

      animationTimeline.addLabel("imageAnimations");

      animationTimeline.to(imageContainerRef.current, {
        scaleY: 1,
        duration: 0.75,
        ease: "power2.out",
      });

      animationTimeline.to(
        image2ContainerRef.current,
        {
          scaleY: 1,
          duration: 0.75,
          ease: "power2.out",
        },
        "imageAnimations+=0.1",
      );

      animationTimeline.addLabel("textAnimations");

      animationTimeline.to(image2Ref.current, {
        opacity: 1,
        duration: 0.5,
        ease: "power2.out",
      });

      animationTimeline.to(
        imageRef.current,
        {
          opacity: 1,
          duration: 0.5,
          ease: "power2.out",
        },
        "textAnimations",
      );

      animationTimeline.to(
        subtitleRef.current,
        {
          opacity: 1,
          duration: 0.5,
          ease: "power2.out",
        },
        "textAnimations",
      );

      animationTimeline.to(
        introSubtitleRef.current,
        {
          opacity: 1,
          duration: 0.5,
          ease: "power2.out",
        },
        "textAnimations",
      );

      return () => {
        animationTimeline.kill();
      };
    }
  }, [currentBreakpoint]);

  useEffect(() => {
    AOS.init({ disable: "phone" });
  }, [playAnimation]); // Run this effect only once after initial render

  return (
    <>
      <Helmet>
        <title>
          {currentLanguage === "PT" ? "Ensaios - Senqual" : "Tests - Senqual"}
        </title>

        <meta
          name="description"
          content={
            currentLanguage === "PT"
              ? "Os laboratórios estão preparados com equipamento de elevada qualidade, modernos e fiáveis, adequados à realização dos trabalhos e ensaios requeridos."
              : "The laboratories are equipped with high-quality, modern, and reliable equipment suitable for carrying out the required work and tests."
          }
        />
      </Helmet>
      <div className="mt-14 flex flex-col overflow-x-hidden bg-light-2 lg:pb-32">
        <div className="container mx-auto mb-16 px-8 lg:mb-0">
          <div className="h-6 w-1/2 md:mb-8 md:h-24 lg:mb-0 lg:mt-12 lg:w-1/5"></div>
          <div ref={titleRef}>
            <Title
              text={
                currentLanguage === "PT" ? (
                  <span>Ensaios</span>
                ) : (
                  <span>Tests</span>
                )
              }
              classes="md:-mt-16 lg:-mt-8 xl:-mt-16"
            />
          </div>
          <div className="-mt-5 flex w-full flex-wrap justify-between lg:mb-32 xl:-mt-6 2xl:-mt-8 2xl:flex-nowrap 2xl:gap-x-20">
            <div className="2xl:w-auto] flex w-[calc(40%-0.375rem)] lg:w-1/4">
              <figure
                className="flex h-60 w-full items-center justify-center overflow-hidden bg-green-4 sm:h-80 md:h-96 lg:h-auto lg:self-end"
                ref={imageContainerRef}
              >
                <img
                  src={process.env.PUBLIC_URL + "/img/ensaios/main-1.jpg"}
                  srcSet={process.env.PUBLIC_URL + "/img/ensaios/main-1-2x.jpg"}
                  alt={
                    currentLanguage === "PT"
                      ? "Ensaios - Senqual"
                      : "Tests - Senqual"
                  }
                  className="h-full w-full object-cover"
                  ref={imageRef}
                />
              </figure>
            </div>
            <div className="flex w-[calc(60%-0.375rem)] lg:w-2/5">
              <figure
                className="flex h-60 w-full items-center justify-center overflow-hidden bg-green-4 sm:h-80 md:h-96 lg:h-auto"
                ref={image2ContainerRef}
              >
                <img
                  src={process.env.PUBLIC_URL + "/img/ensaios/main-2.jpg"}
                  srcSet={process.env.PUBLIC_URL + "/img/ensaios/main-2-2x.jpg"}
                  alt={
                    currentLanguage === "PT"
                      ? "Ensaios - Senqual"
                      : "Tests - Senqual"
                  }
                  className="h-full w-full object-cover"
                  ref={image2Ref}
                />
              </figure>
            </div>
            <div
              className="mb-20 mt-12 w-full md:w-3/4 lg:mt-0 lg:w-1/4 lg:self-center  2xl:w-1/4"
              ref={subtitleRef}
            >
              <Paragraph
                classes="font-medium"
                text={
                  <span>
                    {currentLanguage === "PT"
                      ? "Os laboratórios estão preparados com equipamento de elevada qualidade, modernos e fiáveis, adequados à realização dos trabalhos e ensaios requeridos."
                      : "The laboratories are equipped with high-quality, modern, and reliable equipment suitable for carrying out the required work and tests."}
                  </span>
                }
              />
            </div>
          </div>
          <div ref={introSubtitleRef}>
            <EnsaiosSearch />
          </div>
          <div ref={graphRef}>
            <EnsaiosGraph
              width={450 > viewportWidth - 220 ? viewportWidth - 220 : 450}
              height={450 > viewportWidth - 220 ? viewportWidth - 220 : 450}
              playAnimation={playAnimation}
            />
          </div>
        </div>

        <div className="relative mt-16 block w-full lg:-mt-40 lg:flex lg:flex-wrap">
          <div
            className="container z-10 mx-auto hidden w-full px-8 py-16 lg:flex lg:py-24 xl:py-32"
            data-aos="fade-left"
          >
            <Paragraph
              classes="text-dark-2 w-2/5 ml-auto"
              text={
                <span>
                  {currentLanguage === "PT" ? (
                    <>
                      Os ensaios são realizados de acordo com as normas NP, EN,
                      Especificações NEC, ASTM, BS, DIN, UNE, conforme seja
                      solicitado pelo cliente ou caderno de encargos de obra.
                      <br />
                      <br />
                      Na ausência de regulamentação normativa aplicável, a
                      Senqual recorre a procedimentos internos de ensaio
                      baseados em comunicações científicas ou recomendações de
                      associações internacionais.
                    </>
                  ) : (
                    <>
                      Tests are carried out according to NP, EN, NEC
                      Specifications, ASTM, BS, DIN, UNE standards, as requested
                      by the client or construction specifications.
                      <br />
                      <br />
                      In the absence of applicable regulatory standards, Senqual
                      relies on internal testing procedures based on scientific
                      literature or recommendations from international
                      associations.
                    </>
                  )}
                </span>
              }
            />
          </div>
          <div
            className="container z-10 mx-auto -mt-44 hidden w-full px-8 py-16 lg:-mt-56 lg:flex lg:py-24 xl:-mt-60 xl:py-32"
            data-aos="fade-right"
          >
            <Paragraph
              classes="text-white w-2/5"
              text={
                <span>
                  {currentLanguage === "PT" ? (
                    <>
                      Em complemento, a Senqual realiza verificações internas
                      intermédias dos equipamentos, para os quais se justifique,
                      recorrendo a padrões internos calibrados.
                      <br />
                      <br />
                      Todos os equipamentos utilizados para a avaliação de
                      grandezas físicas, tais como massas, forças, temperaturas,
                      etc., são calibrados periodicamente em laboratórios
                      metrológicos acreditados pelo IPAC.
                    </>
                  ) : (
                    <>
                      Additionally, Senqual conducts intermediate internal
                      checks of the equipment, when justified, using calibrated
                      internal standards.
                      <br />
                      <br />
                      All equipment used for the evaluation of physical
                      quantities, such as masses, forces, temperatures, etc.,
                      are periodically calibrated in metrological laboratories
                      accredited by IPAC.
                    </>
                  )}
                </span>
              }
            />
          </div>

          <div className="lg:absolute lg:top-0 lg:w-full" data-aos="fade-left">
            <div className="lg:mask-right-3 -mt-8 ml-auto bg-light-3 lg:mb-0 lg:mt-0 lg:h-[27rem] lg:w-[60%] xl:h-[28rem]">
              <div className="container mx-auto flex px-8 pb-32 pt-16 lg:hidden lg:pb-16">
                <Paragraph
                  classes="px-8 lg:pl-28 text-dark-2 w-full "
                  text={
                    <span>
                      {currentLanguage === "PT" ? (
                        <>
                          Os ensaios são realizados de acordo com as normas NP,
                          EN, Especificações NEC, ASTM, BS, DIN, UNE, conforme
                          seja solicitado pelo cliente ou caderno de encargos de
                          obra.
                          <br />
                          <br />
                          Na ausência de regulamentação normativa aplicável, a
                          Senqual recorre a procedimentos internos de ensaio
                          baseados em comunicações científicas ou recomendações
                          de associações internacionais.
                        </>
                      ) : (
                        <>
                          Tests are carried out according to NP, EN, NEC
                          Specifications, ASTM, BS, DIN, UNE standards, as
                          requested by the client or construction
                          specifications.
                          <br />
                          <br />
                          In the absence of applicable regulatory standards,
                          Senqual relies on internal testing procedures based on
                          scientific literature or recommendations from
                          international associations.
                        </>
                      )}
                    </span>
                  }
                />
              </div>
            </div>
          </div>

          <div
            className="lg:absolute lg:top-52 lg:w-full"
            data-aos="fade-right"
          >
            <div className="lg:mask-left-3 -mt-16 mb-16 bg-green-3 lg:mb-0 lg:mt-0 lg:h-[27rem] lg:w-[55%] xl:h-[28rem]">
              <div className="container mx-auto flex px-8 py-16 lg:hidden">
                <Paragraph
                  classes="px-8 lg:pr-28 text-white w-full"
                  text={
                    <span>
                      {currentLanguage === "PT" ? (
                        <>
                          Em complemento, a Senqual realiza verificações
                          internas intermédias dos equipamentos, para os quais
                          se justifique, recorrendo a padrões internos
                          calibrados.
                          <br />
                          <br />
                          Todos os equipamentos utilizados para a avaliação de
                          grandezas físicas, tais como massas, forças,
                          temperaturas, etc., são calibrados periodicamente em
                          laboratórios metrológicos acreditados pelo IPAC.
                        </>
                      ) : (
                        <>
                          Additionally, Senqual conducts intermediate internal
                          checks of the equipment, when justified, using
                          calibrated internal standards.
                          <br />
                          <br />
                          All equipment used for the evaluation of physical
                          quantities, such as masses, forces, temperatures,
                          etc., are periodically calibrated in metrological
                          laboratories accredited by IPAC.
                        </>
                      )}
                    </span>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
