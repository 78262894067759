import { useContext } from "react";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../App";
export default function Footer() {
  const { currentLanguage } = useContext(LanguageContext);

  const menuInfo = [
    {
      name: currentLanguage === "PT" ? "Contactos" : "Contacts",
      href: "/contacte-nos",
    },
    {
      name: currentLanguage === "PT" ? "Recrutamento" : "Recruitment",
      href: "/contacte-nos",
    },
    {
      name:
        currentLanguage === "PT"
          ? "Políticas de Privacidade"
          : "Privacy Policy",
      href: "/politicas-de-privacidade",
    },
    {
      name:
        currentLanguage === "PT" ? "Livro de Reclamações" : "Complaints Book",
      href: "https://www.livroreclamacoes.pt/Inicio/",
    },
  ];

  const menuItems = menuInfo.map((item, index) => {
    return index === 3 ? (
      <a
        key={index}
        href={item.href}
        className="w-full text-center text-sm uppercase text-white transition-colors hover:text-green-1 focus:text-green-1 active:text-green-1 md:w-auto md:text-base"
      >
        {item.name}
      </a>
    ) : (
      <Link
        to={item.href}
        className="w-full text-center text-sm uppercase text-white outline-none transition-colors hover:text-green-1 focus:text-green-1 active:text-green-1 md:w-auto md:text-base"
      >
        {item.name}
      </Link>
    );
  });

  return (
    <footer className="relative">
      <div className="flex flex-col items-center bg-green-3 pb-16 pt-20 md:py-20">
        <Link to={"/"}>
          <img
            src={process.env.PUBLIC_URL + "/img/logo-white-small.svg"}
            alt="Senqual Group"
          />
        </Link>
        <p className="mb-8 mt-8 text-lg font-semibold uppercase text-green-1 md:mb-16">
          {currentLanguage === "PT"
            ? "Há mais de 25 anos no mercado"
            : "Over 25 years in the market"}
        </p>
        <nav className="flex flex-wrap justify-center gap-x-14 gap-y-4 md:gap-y-10 lg:gap-y-14">
          {menuItems}
        </nav>
      </div>
      <div className="flex gap-x-4 bg-green-1 px-4 py-4 text-center sm:px-8 md:justify-center md:gap-x-9 md:px-0">
        <span className="text-[0.7rem] text-white sm:text-[0.8125rem]">
          2023 © SENQUAL
        </span>
        <span className="text-[0.7rem] text-white sm:text-[0.8125rem]">|</span>

        <a
          href="http://www.nor267.com"
          target="_blank"
          rel="noopener noreferrer"
          className="relative z-20 flex"
        >
          <span className="font-mono text-[0.7rem] text-white sm:text-sm">
            Made by Nor267
          </span>
        </a>
        <div className="bottom-0 left-0 ml-auto md:absolute md:w-full">
          <div className="container mb-0.5 flex items-center justify-end gap-x-4 md:mx-auto md:h-12 md:px-8">
            <a
              target="_blank"
              href="https://www.facebook.com/senqual/"
              className="mask-facebook block h-4 w-4 bg-white hover:bg-green-3 focus:bg-green-3 active:bg-green-3 active:hover:bg-green-4"
            ></a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/senqual---sociedade-de-engenharia-e-qualidade-lda/"
              className="mask-linkedin block h-4 w-4 bg-white hover:bg-green-3 focus:bg-green-3 active:bg-green-3 active:hover:bg-green-4"
            ></a>
          </div>
        </div>
      </div>
    </footer>
  );
}
