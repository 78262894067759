import React, { useLayoutEffect, useRef, useContext, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import classNames from "classnames";
import slugify from "slugify";

import Title from "../components/global/Title";
import ArrowSubtitle from "../components/global/ArrowSubtitle";
import UndelinedQuote from "../components/global/UndelinedQuote";
import { Helmet } from "react-helmet";
import useBreakpoint from "../helpers/useBreakpoint";

import AOS from "aos";
import "aos/dist/aos.css"; // Import the CSS for AOS animations

import { LanguageContext } from "../App";

gsap.registerPlugin(ScrollTrigger);

export default function Consultoria() {
  const { currentLanguage } = useContext(LanguageContext);
  const currentBreakpoint = useBreakpoint();
  const main = useRef();

  const titleRef = useRef(null);
  const imageRef = useRef(null);
  const imageContainerRef = useRef(null);
  const subtitleRef = useRef(null);

  // Initial Animation
  useLayoutEffect(() => {
    if (
      currentBreakpoint === "sm" ||
      currentBreakpoint === "md" ||
      currentBreakpoint === "lg" ||
      currentBreakpoint === "xl" ||
      currentBreakpoint === "2xl"
    ) {
      // Set initial opacity to 0 before the animation starts
      gsap.set(imageContainerRef.current, {
        scaleY: 0,
        transformOrigin: "top",
        zIndex: 10,
        position: "relative",
      });
      gsap.set(imageRef.current, { opacity: 0 });
      gsap.set(titleRef.current, {
        opacity: 0,
        y: 50,
        zIndex: 20,
        position: "relative",
      });
      gsap.set(subtitleRef.current, { opacity: 0 });

      // GSAP animation timeline
      const animationTimeline = gsap.timeline();

      animationTimeline.to(titleRef.current, {
        opacity: 1,
        y: 0,
        duration: 0.5,
        ease: "power2.out",
        delay: 0.5,
      });

      animationTimeline.to(imageContainerRef.current, {
        scaleY: 1,
        duration: 0.75,
        ease: "power2.out",
      });

      animationTimeline.addLabel("textAnimations");

      animationTimeline.to(imageRef.current, {
        opacity: 1,
        duration: 0.5,
        ease: "power2.out",
      });

      animationTimeline.to(
        subtitleRef.current,
        {
          opacity: 1,
          duration: 0.5,
          ease: "power2.out",
        },
        "textAnimations",
      );

      return () => {
        animationTimeline.kill();
      };
    }
  }, [currentBreakpoint]);

  useEffect(() => {
    AOS.init({ anchorPlacement: "top-bottom", disable: "phone" });
  }, []);

  // Counters Animation

  const consultoria =
    currentLanguage === "PT"
      ? [
          {
            title: "Pavimentos industriais",
            img: "pavimentos-industriais",
            text: [
              "Fundação",
              "Controlo de betão",
              "Nivelamento: durante a execução",
              "Planimetria: evaluation of surface regularity: TR34; ASTM E\u20111155M; DIN 18202",
              "Acompanhamento da execução dos trabalhos em obra: daily reports of work done, final conclusive report",
              "Análise do projeto",
            ],
          },
          {
            title: "Estudo de composições",
            img: "estudo-de-composicoes",
            text: [
              "Estudo/análise de composições de betões",
              "Estudo da evolução da resistência à compressão de betões através do método de cura de provetes a temperatura controlada ou maturometria",
              "Estudo de composições de misturas betuminosas",
              "Estudo de composições de agregado-cimento e apoio na fase de produção em obra",
            ],
          },
          {
            title: "Marcação CE",
            img: "marcacao-ce",
            text: [
              "Apoio na seleção/caracterização dos materiais",
              "Ensaios iniciais de tipo",
              "Apoio no controlo de produção",
              "Apoio na preparação de documentação necessária para a marcação CE do produto",
              "Auditorias internas",
              "Acompanhamento e controlo de qualidade durante a produção",
            ],
          },
          {
            title: "Classificação de resíduos de construção",
            img: "classificacao-de-residuos-de-construcao",
            text: [
              "Realização de ensaios para caracterização dos produtos",
              "Classificação dos produtos de acordo com Especificação LNEC adequada para utilização de materiais reciclados: E474; E473; E485; E472; E483; …",
              "Marcação CE dos resíduos e acordo com a norma adequada",
            ],
          },
          {
            title: "Inspeção de estruturas",
            img: "inspecao-de-estruturas",
            text: [
              "Levantamento de patologias",
              "Ensaios não destrutivos e ensaios destrutivos",
              "Ensaios de carga de estruturas",
              "Cálculo estrutural / avaliação do comportamento das estruturas",
            ],
          },
        ]
      : [
          {
            title: "Industrial Pavements",
            img: "pavimentos-industriais",
            text: [
              "Foundation Engineering",
              "Concrete Control",
              "Leveling: during execution",
              "Surface Regularity Assessment: TR34; ASTM E\u20111155M; DIN 18202",
              "On-site Work Supervision: daily reports of work done, final conclusive report",
              "Project Analysis",
            ],
          },
          {
            title: "Composition Study",
            img: "estudo-de-composicoes",
            text: [
              "Study/analysis of concrete compositions",
              "Study of the evolution of concrete compressive strength through controlled temperature curing or maturity method",
              "Study of asphalt mix compositions",
              "Study of aggregate-cement compositions and support during the production phase on site",
            ],
          },
          {
            title: "CE Marking",
            img: "marcacao-ce",
            text: [
              "Support in material selection/characterization",
              "Initial type testing",
              "Support in production control",
              "Support in preparing necessary documentation for CE marking of the product",
              "Internal audits",
              "Monitoring and quality control during production",
            ],
          },
          {
            title: "Construction Waste Classification",
            img: "classificacao-de-residuos-de-construcao",
            text: [
              "Conducting tests for product characterization",
              "Classification of products according to suitable LNEC specifications for the use of recycled materials: E474; E473; E485; E472; E483; …",
              "CE marking of waste according to the appropriate standard",
            ],
          },
          {
            title: "Structural Inspection",
            img: "inspecao-de-estruturas",
            text: [
              "Pathology survey",
              "Non-destructive testing and destructive testing",
              "Structural load testing",
              "Structural calculation/assessment of structural behavior",
            ],
          },
        ];

  const consultoriaItems = consultoria.map((item, index) => {
    return [
      <div
        key={index}
        className={classNames(
          index % 2 !== 0 && "lg:flex-row-reverse",
          "w-100 relative flex flex-col-reverse lg:mb-16 lg:flex-row",
        )}
      >
        <div
          data-aos={
            currentBreakpoint === "sm" || currentBreakpoint === "md"
              ? "fade-up"
              : index % 2 !== 0
              ? "fade-left"
              : "fade-right"
          }
          className={classNames(
            index % 2 !== 0 ? " mask-right" : "mask-left",
            "animation-1 z-10 mb-20 bg-green-4 drop-shadow-md md:h-80 lg:mb-0 lg:h-[38rem] lg:w-1/2",
          )}
        >
          <img
            src={process.env.PUBLIC_URL + `/img/consultoria/${item.img}.jpg`}
            alt={item.title}
            className="h-full w-full object-cover"
          />
        </div>
        {index % 2 === 0 && (
          <div
            data-aos="fade-left"
            className="animation-3 absolute inset-x-0 inset-y-0 m-auto hidden h-4/5 w-full bg-white lg:block"
          ></div>
        )}
        <div
          data-aos={
            currentBreakpoint === "sm" || currentBreakpoint === "md"
              ? "fade-up"
              : index % 2 !== 0
              ? "fade-right"
              : "fade-left"
          }
          className={classNames(
            index % 2 !== 0 && "flex-row-reverse",
            "animation-2 container inset-x-0 inset-y-0 m-auto flex flex-wrap items-center justify-end lg:absolute",
          )}
        >
          <div
            className={classNames(
              index % 2 !== 0
                ? "items-start pr-8 text-left"
                : "items-end pl-8 text-right",
              "mb-10 flex flex-col px-8 md:w-3/4 lg:mb-0 lg:w-1/2",
            )}
          >
            <ArrowSubtitle
              text={item.title}
              left={index % 2 === 0}
              classes="mb-8 md:mb-16"
            />
            <ul>
              {item.text.map((text, textIndex) => (
                <li
                  key={textIndex}
                  className={classNames(
                    index % 2 !== 0
                      ? "pl-16 after:left-0 after:bg-arrow-right"
                      : "pr-16 after:right-0 after:bg-arrow-left",
                    textIndex !== item.text.length - 1 && "mb-2",
                    "relative text-dark-2 after:absolute after:top-0 after:block  after:h-6 after:w-4 after:bg-center after:bg-no-repeat after:content-['']",
                  )}
                >
                  {text}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>,
    ];
  });

  return (
    <>
      <Helmet>
        <title>
          {currentLanguage === "PT"
            ? "Consultoria e Controlo de Qualidade"
            : "Consulting and Quality Control"}{" "}
          - Senqual
        </title>
        <meta
          name="description"
          content={
            currentLanguage === "PT"
              ? "A par com a execução dos trabalhos laboratoriais ou in situ é fornecido todo o apoio técnico necessário à realização dos mesmos."
              : "Alongside the execution of laboratory or on-site work, all necessary technical support for their completion is provided."
          }
        />
      </Helmet>
      <div
        className="mt-14 flex flex-col overflow-x-hidden bg-light-2 lg:pb-32"
        ref={main}
      >
        <div className="container mx-auto mb-16 px-8 lg:mb-0">
          <div className="h-6 w-1/2 md:mb-8 md:h-24 lg:mb-0 lg:mt-12 lg:w-1/5"></div>

          <div ref={titleRef}>
            <Title
              text={
                <span className="-ml-4 block w-[calc(100%+32px)] sm:ml-0 sm:inline sm:w-auto">
                  {currentLanguage === "PT"
                    ? "Consultoria e Controlo de Qualidade"
                    : "Consulting and Quality Control"}
                </span>
              }
              classes="md:-mt-16 lg:-mt-8 xl:-mt-16"
            />
          </div>
          <div className="-mt-5 flex w-full flex-wrap justify-between lg:mb-24 lg:flex-row xl:-mt-6 xl:mb-32 2xl:-mt-8 2xl:flex-nowrap 2xl:gap-x-20">
            <div
              className="order-2 ml-auto mt-12 w-3/4 self-center lg:order-1 lg:mt-0 lg:w-1/2 lg:pr-20 xl:w-1/3"
              ref={subtitleRef}
            >
              <UndelinedQuote
                classes="text-right"
                style
                text={
                  <span>
                    {currentLanguage === "PT"
                      ? "A par com a execução dos trabalhos laboratoriais ou in situ é fornecido todo o apoio técnico necessário à realização dos mesmos."
                      : "Alongside the execution of laboratory or on-site work, all necessary technical support for their completion is provided."}
                  </span>
                }
              />
            </div>
            <div className="order-1 flex w-full lg:order-2 lg:w-1/2">
              <figure
                className="flex h-60 w-full items-center justify-center overflow-hidden bg-green-4 sm:h-80 md:h-96 lg:h-auto lg:self-end"
                ref={imageContainerRef}
              >
                <img
                  src={process.env.PUBLIC_URL + "/img/consultoria/main.jpg"}
                  srcSet={
                    process.env.PUBLIC_URL + "/img/consultoria/main-2x.jpg 2x"
                  }
                  alt="Consultoria e Controlo de Qualidade - Senqual"
                  className="h-full w-full object-cover"
                  ref={imageRef}
                />
              </figure>
            </div>
          </div>
        </div>
        {consultoriaItems}
      </div>
    </>
  );
}
