import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Input from "../../global/Input";
import Textarea from "../../global/Textarea";
import FormButton from "../../global/FormButton";
import { LanguageContext } from "../../../App";

export default function ContactForm() {
  const { currentLanguage } = useContext(LanguageContext);
  const [isFormSent, setIsFormSent] = useState(false);
  const { register, reset, handleSubmit, errors } = useForm();
  const onSubmit = (data) => {
    axios
      .post(process.env.PUBLIC_URL + "/api/contact-form.php", data, {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        reset()
        console.log(response.data);
        setIsFormSent(true);
        // Handle success, e.g., show a success message to the user
      })
      .catch((error) => {
        console.error(error);
        // Handle errors, e.g., show an error message to the user
      });
  };

  const fields = [
    {
      type: "text",
      name: "name",
      label: currentLanguage === "PT" ? "Nome" : "Name",
      autocomplete: "name",
      required: true,
    },
    {
      type: "email",
      name: "email",
      label: currentLanguage === "PT" ? "Email" : "Email",
      autocomplete: "email",
      required: true,
    },
    {
      type: "phone",
      name: "phone",
      label: currentLanguage === "PT" ? "Telemóvel" : "Phone",
      autocomplete: "tel",
      required: false,
    },
    {
      type: "text",
      name: "company",
      label: currentLanguage === "PT" ? "Empresa" : "Company",
      autocomplete: "organization",
      required: false,
    },
    {
      type: "textarea",
      name: "message",
      label: currentLanguage === "PT" ? "Mensagem" : "Message",
      autocomplete: "new-text", //em vez de off, faz com que não funcione
      required: true,
    },
  ];

  const fieldsItems = fields.map((item, index) => {
    let field;

    if (item.type === "textarea") {
      field = [
        <div key={index} className="w-full">
          <Textarea
            name={item.name}
            label={item.label}
            color="light"
            register={register}
            required={item.required}
            autocomplete={item.autocomplete}
          />
        </div>,
      ];
    } else {
      field = [
        <div key={index} className="w-full sm:w-[calc(50%-1rem)]">
          <Input
            type={item.type}
            name={item.name}
            label={item.label}
            color="light"
            register={register}
            required={item.required}
            autocomplete={item.autocomplete}
          />
        </div>,
      ];
    }
    return field;
  });

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex w-full flex-wrap gap-x-8 gap-y-8 md:gap-y-12 lg:gap-y-10 2xl:gap-y-16"
    >
      {fieldsItems}
      <div className="ml-auto mt-6 w-full sm:w-[calc(50%-1rem)]  lg:-mt-2">
        <FormButton
          color="light"
          label={currentLanguage === "PT" ? "Enviar Mensagem" : "Send Message"}
        />
        { isFormSent && <div className="mt-2">{currentLanguage === "PT" ? "Mensagem enviada com sucesso!" : "Message sent successfully!"}</div>}
      </div>
    </form>
  );
}
