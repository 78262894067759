import React from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useState } from "react";
import LangDropdown from "../specific/layout/LangDropdown";

export default function Header({ toggleMenu }) {
  const [currentLanguage] = useState("PT");

  return (
    <nav className="header-animation fixed top-0 z-50 flex h-14 w-full max-w-[100vw] justify-center bg-light-2">
      <div className="container relative flex items-center justify-between px-8">
        <Link to={"/"}>
          <div className="md:hidden">
            <img
              src={process.env.PUBLIC_URL + "/img/logo-mobile.svg"}
              alt="Senqual Group"
              className="header-animation-logo-1 h-8"
            />
            <img
              src={process.env.PUBLIC_URL + "/img/logo-white-mobile.svg"}
              alt="Senqual Group"
              className="header-animation-logo-2 absolute left-8 top-3 h-8 opacity-0"
            />
          </div>
          <div className="hidden md:block">
            <img
              src={process.env.PUBLIC_URL + "/img/logo-color.svg"}
              alt="Senqual Group"
              className="header-animation-logo-1 h-8"
            />
            <img
              src={process.env.PUBLIC_URL + "/img/logo-white.svg"}
              alt="Senqual Group"
              className="header-animation-logo-2 absolute left-8 top-3 h-8 opacity-0"
            />
          </div>
        </Link>
        <div className="relative md:ml-auto">
          <div className="header-animation-dropdown-green relative">
            <LangDropdown header={true} />
          </div>
          <div className="header-animation-dropdown-white absolute top-0">
            <LangDropdown header={true} greenBackground={true} />
          </div>
        </div>
        <button
          className="mask-menu header-animation-button ml-4 h-5 w-5 bg-green-1"
          onClick={toggleMenu}
        ></button>
      </div>
    </nav>
  );
}
