import { useLayoutEffect, useRef, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import classNames from "classnames";
import { gsap } from "gsap";
import AOS from "aos";
import "aos/dist/aos.css"; // Import the CSS for AOS animations
import Contador from "../components/specific/home/sobre/Contador";
import useBreakpoint from "../helpers/useBreakpoint";
import { LanguageContext } from "../App";

export default function Home() {
  const { currentLanguage } = useContext(LanguageContext);

  const currentBreakpoint = useBreakpoint();
  const titleRef = useRef(null);
  const imageRef = useRef(null);
  const imageContainerRef = useRef(null);
  const subtitleRef = useRef(null);

  // Initial Animation
  useLayoutEffect(() => {
    if (
      currentBreakpoint === "sm" ||
      currentBreakpoint === "md" ||
      currentBreakpoint === "lg" ||
      currentBreakpoint === "xl" ||
      currentBreakpoint === "2xl"
    ) {
      // Set initial opacity to 0 before the animation starts
      gsap.set(imageContainerRef.current, {
        scaleY: 0,
        transformOrigin: "top",
      });
      gsap.set(imageRef.current, { opacity: 0 });
      gsap.set(titleRef.current, { opacity: 0, y: 50 });
      gsap.set(subtitleRef.current, { opacity: 0, y: 50 });

      // GSAP animation timeline
      const animationTimeline = gsap.timeline();

      animationTimeline.to(imageContainerRef.current, {
        scaleY: 1,
        duration: 0.75,
        ease: "power2.out",
      });

      animationTimeline.addLabel("textAnimations");

      animationTimeline.to(imageRef.current, {
        opacity: 1,
        duration: 0.5,
        ease: "power2.out",
      });

      animationTimeline.to(
        titleRef.current,
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          ease: "power2.out",
          delay: 0.5,
        },
        "textAnimations",
      );

      animationTimeline.to(
        subtitleRef.current,
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          delay: 1,
          ease: "power2.out",
        },
        "textAnimations",
      );

      return () => {
        animationTimeline.kill();
      };
    }
  }, [currentBreakpoint]);

  useEffect(() => {
    AOS.init({ anchorPlacement: "top-bottom", disable: "phone" });
  }, []); // Run this effect only once after initial render

  const areas =
    currentLanguage === "PT"
      ? [
          {
            title: `Ensaios em Laboratório e "In Situ"`,
            categories: [
              "Agregados e inertes",
              "Enrocamento",
              "Pedra natural",
              "Solos",
              "Betão",
              "Cimentos e adições",
              "Argamassas e Caldas",
              "Adjuvantes",
              "Misturas",
              "Betuminosas",
              "Misturas tratadas com ligantes hidráulicos",
              "Aços",
              "Durabilidade do betão",
            ],
            link: "/ensaios",
          },
          {
            title: "Consultoria e controlo de qualidade",
            categories: [
              "Pavimentos industriais",
              "Planimetria",
              "Estudo de composições",
              "Marcação CE",
              "Classificação de resíduos de construção",
              "Inspeção de estruturas",
            ],
            link: "/consultoria",
          },
        ]
      : [
          {
            title: `Laboratory and "In Situ" Testing`,
            categories: [
              "Aggregates and inert materials",
              "Rockfill",
              "Natural stone",
              "Soils",
              "Concrete",
              "Cements and additives",
              "Mortars and Grouts",
              "Admixtures",
              "Mixtures",
              "Bituminous materials",
              "Mixtures treated with hydraulic binders",
              "Steels",
              "Concrete durability",
            ],
            link: "/tests",
          },
          {
            title: "Consulting and Quality Control",
            categories: [
              "Industrial pavements",
              "Surface regularity",
              "Composition study",
              "CE marking",
              "Construction waste classification",
              "Structural inspection",
            ],
            link: "/consulting",
          },
        ];

  const areaBlocks = areas.map((item, index) => {
    return [
      <div
        key={index}
        className={classNames(
          index === 0 ? "bg-light-2" : "bg-green-1",
          "flex flex-col items-center px-8 py-20 md:px-20 lg:w-1/2 lg:px-32 lg:py-32",
        )}
        data-aos={
          currentBreakpoint === "sm" || currentBreakpoint === "md"
            ? "fade-up"
            : index === 0
            ? "fade-right"
            : "fade-left"
        }
      >
        <div className="flex h-full flex-col items-center">
          <h2
            className={classNames(
              index === 0 ? "text-green-1" : "text-green-3",
              "mb-12 text-center text-3xl font-bold uppercase",
            )}
          >
            {item.title}:
          </h2>
          <p
            className={classNames(
              index === 0 ? "text-light-4" : "text-white",
              "mb-16 text-center text-xl",
            )}
          >
            {item.categories.map((category, indexCategory) => {
              return [
                <span
                  key={indexCategory}
                  className={classNames(indexCategory % 2 === 0 && "font-bold")}
                >
                  {category}
                </span>,
                indexCategory !== item.categories.length - 1 && <span>, </span>,
              ];
            })}
          </p>
          <Link to={item.link} className="mt-auto">
            <button
              className={classNames(
                index === 0
                  ? "bg-green-1 font-bold text-light-2 hover:bg-green-2 focus:bg-green-2 active:bg-green-2 active:hover:bg-green-3"
                  : "bg-green-3 font-bold text-light-3 hover:bg-green-4 focus:bg-green-4 active:bg-green-4 active:hover:bg-dark-0",
                "rounded-[3px] px-8 py-3 text-lg uppercase tracking-tight outline-none transition-colors",
              )}
            >
              {currentLanguage === "PT" ? "Conhecer Mais" : "Know More"}
            </button>
          </Link>
        </div>
      </div>,
    ];
  });

  const contadoresData = [
    {
      title: currentLanguage === "PT" ? "Anos" : "Years",
      quantity: 25,
    },
    {
      title: currentLanguage === "PT" ? "Colaboradores" : "Employees",
      quantity: 30,
    },
    {
      title: currentLanguage === "PT" ? "M² de Laboratório" : "Sq. Ft. of Lab",
      quantity: 1500,
    },
    {
      title: currentLanguage === "PT" ? "Clientes" : "Clients",
      quantity: 200,
    },
  ];

  const contadores = contadoresData.map((item, index) => {
    return <Contador key={index} index={index} item={item} />;
  });

  return (
    <>
      <Helmet>
        <title>
          Senqual -{" "}
          {currentLanguage === "PT"
            ? "Na vanguarda da técnica"
            : "At the forefront of technology"}
        </title>
        <meta
          name="description"
          content={
            currentLanguage === "PT"
              ? "A Senqual está no mercado há mais de 25 anos. Somos os seus parceiros na qualidade."
              : "Senqual has been in the market for over 25 years. We're your partners in quality."
          }
        />
      </Helmet>
      <div
        className="relative flex h-screen justify-center overflow-hidden bg-green-4"
        ref={imageContainerRef}
      >
        <div
          className="main-slider bg-gradient-home scale-up-center absolute left-0 top-0 h-full w-full bg-home-main bg-cover bg-center after:absolute after:top-0 after:h-full after:w-full after:opacity-90 after:content-['']"
          ref={imageRef}
        ></div>
        <div className="container z-10 flex flex-col justify-center px-8 lg:block">
          {currentLanguage === "PT" ? (
            <h1
              className="text-6xl font-light uppercase text-white drop-shadow-[0_4px_18px_rgba(0,0,0,0.25)] md:text-7xl lg:mt-44 lg:text-8xl"
              ref={titleRef}
            >
              No mercado <br /> há mais de
              <br />
              <span className="font-semibold text-green-1">25 anos</span>
            </h1>
          ) : (
            <h1
              className="text-6xl font-light uppercase text-white drop-shadow-[0_4px_18px_rgba(0,0,0,0.25)] md:text-7xl lg:mt-44 lg:text-8xl"
              ref={titleRef}
            >
              In the market <br /> for more than
              <br />
              <span className="font-semibold text-green-1">25 years</span>
            </h1>
          )}

          <h3
            className="mt-12 pr-12 text-2xl text-white underline md:w-1/2 lg:w-1/3 lg:pr-0"
            ref={subtitleRef}
          >
            {currentLanguage === "PT"
              ? "Os seus parceiros na qualidade."
              : "Your partners in quality."}
          </h3>
        </div>
      </div>
      <div className="flex w-full bg-light-5">
        <section className="container mx-auto mt-16 px-8 lg:mt-24 xl:mt-32">
          <div className="mb-16 flex flex-wrap justify-between gap-y-16 lg:mb-24 xl:mb-32">
            {contadores}
          </div>
        </section>
      </div>

      <div className="flex flex-wrap">{areaBlocks}</div>
      <div className="w-100 relative bg-light-5">
        <div className="container relative z-10 mx-auto overflow-hidden bg-pattern bg-contain px-8 lg:bg-none lg:py-24">
          <div
            className="relative -mx-8 after:absolute after:left-0 after:top-0 after:h-full after:w-full after:bg-gradient-to-t after:from-light-5 after:content-[''] lg:hidden"
            data-aos={
              currentBreakpoint === "sm" || currentBreakpoint === "md"
                ? "fade-up"
                : false
            }
          >
            <img
              src={process.env.PUBLIC_URL + "/img/home/acreditacoes-mobile.jpg"}
              alt="Acreditações"
            />
          </div>
          <div
            className="ml-auto flex flex-col items-center py-24 lg:w-1/2 lg:py-0 lg:pl-24"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <p className="mb-3 text-center text-xl text-dark-2">
              {currentLanguage === "PT"
                ? "A SENQUAL encontra-se acreditada pelo IPAC no âmbito de"
                : "SENQUAL is accredited by IPAC in the field of"}
            </p>
            <p className="mb-12 text-center text-xl font-bold uppercase text-green-3">
              {currentLanguage === "PT"
                ? "“Betões, cimentos e argamassas”, “Agregados e Inertes” e “Metais e ligas metálicas”"
                : "“Concrete, cements, and mortars”, “Aggregates and Inert Materials”, and “Metals and Metal Alloys”"}
            </p>
            <p className="mb-16 text-center text-xl text-dark-2">
              {currentLanguage === "PT"
                ? "A Senqual foi distinguida pela IAPMEI pela qualidade do seu desempenho e perfil de risco como "
                : "Senqual has been distinguished by IAPMEI for the quality of its performance and risk profile as "}
              <span className="font-bold uppercase">
                {currentLanguage === "PT"
                  ? "Pequena e Média Empresa Líder"
                  : "Small and Medium Enterprise Leader"}
              </span>{" "}
              {currentLanguage === "PT" ? "e" : "and"}{" "}
              <span className="font-bold uppercase">
                {currentLanguage === "PT"
                  ? "Pequena e Média Empresa Excelência"
                  : "Small and Medium Enterprise Excellence"}
              </span>
              .
            </p>
            <img
              src={process.env.PUBLIC_URL + "/img/home/logos.svg"}
              alt={currentLanguage === "PT" ? "Acreditações" : "Accreditations"}
            />
          </div>
        </div>
        <div
          className="absolute left-0 top-0 z-0 hidden h-full w-full bg-pattern bg-cover lg:flex"
          data-aos="fade-left"
        ></div>
        <div
          className="absolute left-0 top-0 z-0 hidden h-full w-full lg:flex"
          data-aos="fade-right"
        >
          <div className="relative w-1/2 after:absolute after:left-0 after:top-0 after:h-full after:w-full after:bg-gradient-to-l after:from-light-5 after:content-['']">
            <img
              src={process.env.PUBLIC_URL + "/img/home/acreditacoes-mobile.jpg"}
              alt={currentLanguage === "PT" ? "Acreditações" : "Accreditations"}
              className="h-full w-full object-cover"
            />
          </div>
        </div>
      </div>
    </>
  );
}
