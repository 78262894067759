import { useContext, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Input from "../../global/Input";
import FormButton from "../../global/FormButton";

import classNames from "classnames";
import { LanguageContext } from "../../../App";
import InputButton from "../../global/InputButton";

export default function RecruitForm() {
  const { currentLanguage } = useContext(LanguageContext);
  const [cv, setCV] = useState(null);
  const inputFile = useRef();
  const [isFormSent, setIsFormSent] = useState(false);

  const { register, handleSubmit, reset, errors } = useForm();

  const getCVLabel = () => {
    if (cv) {
      return currentLanguage === "PT" ? "CV Carregado" : "CV Uploaded";
    } else {
      return currentLanguage === "PT" ? "Carregar CV" : "Upload CV";
    }
  }

  const onSubmit = (data) => {
    reset();
    const formData = new FormData();
    // Add all the data to the form data
    for (const key in data) {
      formData.append(key, data[key]);
    }
    formData.append("cv", cv);

    axios.post(process.env.PUBLIC_URL + "/api/recruit-form.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          setIsFormSent(true)
          // Handle success, e.g., show a success message to the user
        })
        .catch((error) => {
          console.error(error);
          // Handle errors, e.g., show an error message to the user
        });
  };

  const fields = [
    {
      type: "text",
      name: "name",
      label: currentLanguage === "PT" ? "Nome" : "Name",
      autocomplete: "name",
      required: true,
    },
    {
      type: "text",
      name: "address",
      label: currentLanguage === "PT" ? "Morada" : "Address",
      autocomplete: "street-address",
      required: true,
    },
    {
      type: "text",
      name: "postalCode",
      label: currentLanguage === "PT" ? "Código Postal" : "Postal Code",
      autocomplete: "postal-code",
      required: true,
    },
    {
      type: "text",
      name: "city",
      label: currentLanguage === "PT" ? "Localidade" : "City",
      autocomplete: "address-level2",
      required: true,
    },
    {
      type: "phone",
      name: "phone",
      label: currentLanguage === "PT" ? "Telemóvel" : "Phone",
      autocomplete: "tel",
      required: true,
    },
    {
      type: "email",
      name: "email",
      label: currentLanguage === "PT" ? "Email" : "Email",
      autocomplete: "email",
      required: true,
    },
    {
      type: "text",
      name: "job",
      label: currentLanguage === "PT" ? "Profissão" : "Job",
      autocomplete: "organization-title",
      required: true,
    },
  ];

  const fieldsItems = fields.map((item, index) => {
    return [
      <div
        key={index}
        className={classNames(
          item.name === "name" || item.name === "address"
            ? "w-full"
            : "w-full sm:w-[calc(50%-1rem)]",
        )}
      >
        <Input
          type={item.type}
          name={item.name}
          label={item.label}
          color="dark"
          register={register}
          required={item.required}
        />
      </div>,
    ];
  });

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex w-full flex-wrap gap-x-8 gap-y-8 2xl:gap-y-10"
    >
      { fieldsItems}
        <div className="mt-6 flex w-full flex-wrap gap-y-4 lg:mt-0">
        <div className="mr-auto w-full sm:w-[calc(50%-1rem)]">
          <input 
            type="file"
            name="cv"
            className="hidden"
            onChange={(e) => setCV(e.target.files[0])}
            ref={inputFile} />
          <InputButton
          onClick={() => inputFile.current.click()}
          isSubmitted={cv}
            name="cv"
            type="upload"
            color="grey"
            label={getCVLabel()}
             
          />
        </div>
        <div className="ml-auto w-full sm:w-[calc(50%-1rem)]">
          <FormButton
            color="dark"
            label={currentLanguage === "PT" ? "Junte-se a Nós" : "Join Us"}
          />
          {isFormSent && <div className="mt-2 text-green-3">
            {currentLanguage === "PT" ? "Formulário enviado com sucesso" : "Form sent successfully"}
            </div>}
        </div>
      </div>

    </form>
  );
}
