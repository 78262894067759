import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import slugify from "slugify";
import classNames from "classnames";
import LangDropdown from "../specific/layout/LangDropdown";
import { LanguageContext } from "../../App";

export default function Menu({ toggleMenu }) {
  const { currentLanguage, updateLanguage } = useContext(LanguageContext);

  const [hoveredMenuItem, setHoveredMenuItem] = useState(false);

  const changeHoveredMenuItem = (index) => {
    setHoveredMenuItem(index); // toggle the menu visibility
  };

  const menuBig = [
    {
      title: currentLanguage === "PT" ? "Sobre Nós" : "About Us",
      link: "/sobre-nos",
      img: "sobre-nos",
    },
    {
      title: currentLanguage === "PT" ? "I&D" : "I&D",
      link: "/id",
      img: "id",
    },
    {
      title: currentLanguage === "PT" ? "Ensaios" : "Tests",
      link: "/ensaios",
      img: "ensaios",
    },
    {
      title:
        currentLanguage === "PT"
          ? "Consultoria e Controlo de Qualidade"
          : "Consulting and Quality Control",
      link: "/consultoria",
      img: "consultoria-e-controlo-de-qualidade",
    },
    {
      title: currentLanguage === "PT" ? "Portefólio" : "Portfolio",
      link: "/portefolio",
      img: "portefolio",
    },
    {
      title: currentLanguage === "PT" ? "Contacte-nos" : "Contact Us",
      link: "/contacte-nos",
      img: "contacte-nos",
    },
  ];

  const menuSmall = [
    {
      title:
        currentLanguage === "PT"
          ? "Políticas de Privacidade"
          : "Privacy Policy",
      link: "/politicas-de-privacidade",
    },
    {
      title:
        currentLanguage === "PT" ? "Livro de Reclamações" : "Complaints Book",
      link: "/livro-de-reclamacoes",
    },
  ];

  const menuBigImages = menuBig.map((item, index) => {
    return [
      <img
        key={index}
        src={`${process.env.PUBLIC_URL}/img/menu/${item.img}.jpg`}
        alt={item.title}
        className={classNames(
          hoveredMenuItem !== index && "opacity-0",
          "absolute left-0 top-0 h-full w-full object-cover transition-opacity",
        )}
      />,
    ];
  });

  const menuBigItems = menuBig.map((item, index) => {
    return [
      <li key={index} className="text-right">
        <Link
          to={item.link}
          onClick={toggleMenu}
          onMouseEnter={() => changeHoveredMenuItem(index)}
          onMouseLeave={() => changeHoveredMenuItem(false)}
          onFocus={() => changeHoveredMenuItem(index)}
          onBlur={() => changeHoveredMenuItem(false)}
          className="text-2xl font-semibold uppercase text-green-3 outline-none transition-colors hover:text-white focus:text-white active:text-white"
        >
          {item.title}
        </Link>
      </li>,
    ];
  });

  const menuSmallItems = menuSmall.map((item, index) => {
    return [
      <li key={index} className="text-right">
        <Link
          onClick={toggleMenu}
          to={item.link}
          className="text-xl uppercase text-green-3 outline-none transition-colors hover:text-white focus:text-white active:text-white"
        >
          {item.title}
        </Link>
      </li>,
    ];
  });

  return (
    <>
      <div className="container relative mx-auto flex h-full flex-col px-8">
        <div className="flex lg:h-full lg:pb-16">
          <div className="relative hidden w-3/5 flex-wrap content-between lg:flex lg:h-full lg:flex-col">
            <div className="absolute left-0 right-0 z-20 m-auto flex flex-col items-start lg:bottom-52 xl:bottom-64 2xl:bottom-72">
              {["PT", "EN"].map((item, index) => (
                <button
                  className={classNames(
                    currentLanguage === item ? "text-white" : "text-green-3",
                    index === 0 && "mb-8 2xl:mb-10",
                    "text-2xl font-semibold uppercase outline-none transition-colors hover:text-white focus:text-white active:text-white",
                  )}
                  key={index}
                  onClick={() => updateLanguage(item)}
                >
                  {item}
                </button>
              ))}
            </div>
            <div className="relative flex-1">
              <div className="mask-triangle-menu absolute inset-0 bg-light-2">
                {menuBigImages}
              </div>
            </div>
            <img
              src={`${process.env.PUBLIC_URL}/img/menu-logo.svg`}
              alt="Senqual Group"
              className="h-auto w-full"
            />
          </div>
          <div className="relative flex w-full flex-col items-end pb-52 md:pb-16 lg:w-2/5 lg:justify-center  2xl:pt-16 ">
            <button
              className="mask-close -ml-8 mb-16 mt-5 h-5 w-5 bg-green-3 hover:bg-white focus:bg-white active:bg-white lg:mt-5 2xl:absolute 2xl:right-0 2xl:top-0 2xl:mb-0 2xl:ml-0"
              onClick={toggleMenu}
            ></button>
            <ul className="flex flex-col gap-y-6 pr-4 md:gap-y-9 md:pr-0">
              {menuBigItems}
            </ul>
            <div className="my-9 mr-4 h-[2px] w-7 bg-green-3 md:mr-0"></div>
            <ul className="flex flex-col gap-y-4 pr-4 md:pr-0">
              {menuSmallItems}
            </ul>
            <div className="fixed bottom-0 left-0 w-full bg-green-3 lg:hidden">
              <div className="container mx-auto flex h-16 items-center px-12">
                <div className="mr-auto">
                  <LangDropdown menu={true} />
                </div>
                <div className="ml-auto flex gap-x-2">
                  <a
                    target="_blank"
                    href="https://www.facebook.com/senqual/"
                    className="mask-facebook block h-5 w-5 bg-green-1 hover:bg-green-2 focus:bg-green-2 active:bg-green-2 active:hover:bg-white"
                  ></a>
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/company/senqual---sociedade-de-engenharia-e-qualidade-lda/"
                    className="mask-linkedin block h-5 w-5 bg-green-1 hover:bg-green-2 focus:bg-green-2 active:bg-green-2 active:hover:bg-white"
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 hidden w-full bg-green-3 lg:block">
        <div className="container mx-auto flex  h-16 items-center px-8">
          <div className="ml-auto flex gap-x-2">
            <a
              target="_blank"
              href="https://www.facebook.com/senqual/"
              className="mask-facebook block h-5 w-5 bg-green-1 hover:bg-green-2 focus:bg-green-2 active:bg-green-2 active:hover:bg-white"
            ></a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/senqual---sociedade-de-engenharia-e-qualidade-lda/"
              className="mask-linkedin block h-5 w-5 bg-green-1 hover:bg-green-2 focus:bg-green-2 active:bg-green-2 active:hover:bg-white"
            ></a>
          </div>
        </div>
      </div>
    </>
  );
}
