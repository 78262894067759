const ensaiosDataEng = [
  {
    title: "Tensile test of steel bars",
    norm: "EN ISO 15630-1 (ISO 6892-1)",
    category: "Steels",
  },
  {
    title: "Determination of geometric characteristics",
    norm: "EN ISO 15630-1",
    category: "Steels",
  },
  {
    title: "Carbonation depth",
    norm: "Internal Procedure",
    category: "Concrete durability",
  },
  {
    title: "Chloride penetration depth",
    norm: "Internal Procedure",
    category: "Concrete durability",
  },
  {
    title: "Determination of reinforcement cover thickness",
    norm: "Internal Procedure",
    category: "Concrete durability",
  },
  {
    title: "Reinforcement mapping",
    norm: "Internal Procedure",
    category: "Concrete durability",
  },
  {
    title: "Corrosion potential determination",
    norm: "ASTM C876",
    category: "Concrete durability",
  },
  {
    title: "Concrete electrical resistivity determination",
    norm: "Internal Procedure",
    category: "Concrete durability",
  },
  {
    title: "Strength assessment by rebound hammer",
    norm: "EN 12504-2",
    category: "Concrete durability",
  },
  {
    title: "Installation of crack meters",
    norm: "Internal Procedure",
    category: "Concrete durability",
  },
  {
    title: "Compressive strength determination",
    norm: "NP EN 13286-41",
    category: "Hydraulic bound mixtures",
  },
  {
    title: "Indirect tensile strength determination",
    norm: "NP EN 13286-42",
    category: "Hydraulic bound mixtures",
  },
  {
    title: "Manufacture of test specimens by compaction with vibrating hammer",
    norm: "NP EN 13286-51",
    category: "Hydraulic bound mixtures",
  },
  {
    title: "Particle size analysis",
    norm: "E 233",
    category: "Aggregates",
  },
  {
    title: "Los Angeles abrasion test",
    norm: "E 237",
    category: "Aggregates",
  },
  {
    title: "Determining flakiness index",
    norm: "B.S. 812 Part 105.1",
    category: "Aggregates",
  },
  {
    title: "Determining elongation index",
    norm: "B.S. 812 Part 105.2",
    category: "Aggregates",
  },
  // ... other entries ...
  {
    title: "Determination of total sulfur content",
    norm: "EN 1744-1:Part 11",
    category: "Aggregates",
  },
  {
    title: "Determination of soluble sulfates in acid",
    norm: "EN 1744-1:Part 12",
    category: "Aggregates",
  },
  {
    title: "Determination of soluble sulfides in acid",
    norm: "EN 1744-1:Part 13",
    category: "Aggregates",
  },
  {
    title: "Analysis regarding the presence of reactive iron sulfide particles",
    norm: "EN 1744-1:Part 14.1",
    category: "Aggregates",
  },
  {
    title: "Determination of lightweight contaminants",
    norm: "EN 1744-1:Part 14.2",
    category: "Aggregates",
  },
  {
    title: "Determination of humus content",
    norm: "EN 1744-1:Part 15.1",
    category: "Aggregates",
  },
  {
    title: "Determination of fulvic acid content",
    norm: "EN 1744-1:Part 15.2",
    category: "Aggregates",
  },
  {
    title: "Water solubility determination",
    norm: "EN 1744-1:Part 16",
    category: "Aggregates",
  },
  {
    title: "Ignition loss determination",
    norm: "EN 1744-1:Part 17",
    category: "Aggregates",
  },
  {
    title: "Determination of free lime by complexometry (reference method)",
    norm: "EN 1744-1:Part 18.2",
    category: "Aggregates",
  },
  {
    title: "Determination of free lime by conductometry (alternative method)",
    norm: "EN 1744-1:Part 18.3",
    category: "Aggregates",
  },
  {
    title: "Determination of free lime by acidimetry (alternative method)",
    norm: "EN 1744-1:Part 18.4",
    category: "Aggregates",
  },
  {
    title:
      "Determination of disintegration of calcium silicate in air-cooled blast furnace slag",
    norm: "EN 1744-1:Part 19.1",
    category: "Aggregates",
  },
  {
    title:
      "Determination of iron disintegration in air-cooled blast furnace slags",
    norm: "EN 1744-1:Part 19.2",
    category: "Aggregates",
  },
  {
    title: "Determination of steel slag expansion",
    norm: "EN 1744-1:Part 19.3",
    category: "Aggregates",
  },
  {
    title: "Determination of soluble chlorides in water",
    norm: "EN 1744-1:Part 7",
    category: "Aggregates",
  },
  {
    title:
      "Potentiometric determination of water-soluble chlorides (alternative method)",
    norm: "EN 1744-1:Part 8",
    category: "Aggregates",
  },
  {
    title:
      "Determination of water-soluble chlorides using Mohr method (alternative method)",
    norm: "EN 1744-1:Part 9",
    category: "Aggregates",
  },
  {
    title: "Determination of soluble sulfates in water",
    norm: "EN 1744-1:Part 10",
    category: "Aggregates",
  },
  {
    title: "Determination of total sulfur content",
    norm: "EN 1744-1:Part 11",
    category: "Aggregates",
  },
  {
    title: "Determination of soluble sulfates in acid",
    norm: "EN 1744-1:Part 12",
    category: "Aggregates",
  },
  {
    title: "Determination of soluble sulfides in acid",
    norm: "EN 1744-1:Part 13",
    category: "Aggregates",
  },
  {
    title: "Analysis of the presence of reactive iron sulfide particles",
    norm: "EN 1744-1:Part 14.1",
    category: "Aggregates",
  },
  {
    title: "Determination of light contaminants",
    norm: "EN 1744-1:Part 14.2",
    category: "Aggregates",
  },
  {
    title: "Determination of humus content",
    norm: "EN 1744-1:Parte 15.1",
    category: "Aggregates",
  },
  {
    title: "Determination of fulvic acid content",
    norm: "EN 1744-1:Parte 15.2",
    category: "Aggregates",
  },
  {
    title: "Water solubility determination",
    norm: "EN 1744-1:Parte 16",
    category: "Aggregates",
  },
  {
    title: "Loss on ignition determination",
    norm: "EN 1744-1:Parte 17",
    category: "Aggregates",
  },
  {
    title: "Determination of free lime by complexometry (reference method)",
    norm: "EN 1744-1:Parte 18.2",
    category: "Aggregates",
  },
  {
    title: "Determination of free lime by conductometry (alternative method)",
    norm: "EN 1744-1:Parte 18.3",
    category: "Aggregates",
  },
  {
    title: "Determination of free lime by acidimetry (alternative method)",
    norm: "EN 1744-1:Parte 18.4",
    category: "Aggregates",
  },
  {
    title:
      "Determination of disintegration of air-cooled blast furnace silicate",
    norm: "EN 1744-1:Parte 19.1",
    category: "Aggregates",
  },
  {
    title:
      "Determination of disintegration of iron in air-cooled blast furnace slag",
    norm: "EN 1744-1:Parte 19.2",
    category: "Aggregates",
  },
  {
    title: "Determination of expansion of steel slag",
    norm: "EN 1744-1:Parte 19.3",
    category: "Aggregates",
  },
  {
    title: "Determination of soluble chlorides in acid",
    norm: "NP EN 1744-5",
    category: "Aggregates",
  },
  {
    title: "Determination of soluble alkalis. Flame spectrophotometry method.",
    norm: "NP 1382",
    category: "Aggregates",
  },
  {
    title:
      "Compaction test of granular material stabilized with hydraulic binders (Vibro-Compactor Method)",
    norm: "BS 1924-2: Section 2.1.5",
    category: "Aggregates",
  },
  {
    title: "Determination of voids in compacted dry filler",
    norm: "NP EN 1097-4",
    category: "Aggregates",
  },
  {
    title: "Magnesium sulfate test",
    norm: "NP EN 1367-2",
    category: "Aggregates",
  },
  {
    title: "Boiling test for basalts “Sonnenbrand”",
    norm: "NP EN 1367-3",
    category: "Aggregates",
  },
  {
    title: "Determination of resistance to thermal shock",
    norm: "EN 1367-5",
    category: "Aggregates",
  },
  {
    title: "Preparation of eluates by leaching aggregates",
    norm: "NP EN 1744-3",
    category: "Aggregates",
  },
  {
    title:
      "Determination of the influence of recycled aggregates extract on cement setting time",
    norm: "NP EN 1744-6",
    category: "Aggregates",
  },
  {
    title: "Concrete resistivity",
    norm: "PIE.031.1",
    category: "Concrete",
  },
  {
    title: "Concrete carbonation depth",
    norm: "PIE.032.1",
    category: "Concrete",
  },
  {
    title: "Determination of modulus of elasticity",
    norm: "EN 12390-13 (Method B)",
    category: "Concrete",
  },
  {
    title: "Modulus of elasticity",
    norm: "DIN 1048-5",
    category: "Concrete",
  },
  {
    title: "Determination of concrete shrinkage and expansion",
    norm: "E 398",
    category: "Concrete",
  },
  {
    title: "Determination of chloride penetration resistance. Immersion test.",
    norm: "E 390",
    category: "Concrete",
  },
  {
    title:
      "Determination of chloride diffusion coefficient by non-steady-state migration test.",
    norm: "E 463",
    category: "Concrete",
  },
  {
    title: "Determination of capillary absorption of water.",
    norm: "E 393",
    category: "Concrete",
  },
  {
    title: "Depth of water penetration under pressure",
    norm: "NP EN 12390-8",
    category: "Concrete",
  },
  {
    title:
      "Determination of energy absorption capacity of fiber-reinforced slab specimens",
    norm: "NP EN 14488-5",
    category: "Concrete",
  },
  {
    title: "Determination of ultrasonic pulse velocity",
    norm: "NP EN 12504-4",
    category: "Concrete",
  },
  {
    title: "Reinforcement detection",
    norm: "BS 1881:Part 204",
    category: "Concrete",
  },
  {
    title: "Concrete control with a rebound hammer",
    norm: "NP EN 12504-2",
    category: "Concrete",
  },
  {
    title: "Vebe test",
    norm: "NP EN 12350-3",
    category: "Concrete",
  },
  {
    title: "Compaction factor test",
    norm: "NP EN 12350-4",
    category: "Concrete",
  },
  {
    title: "Flow table test",
    norm: "NP EN 12350-5",
    category: "Concrete",
  },
  {
    title: "Determination of water immersion. Vacuum test.",
    norm: "E 395",
    category: "Concrete",
  },
  {
    title: "Self-compacting concrete. Slump flow test.",
    norm: "NP EN 12350-8",
    category: "Concrete",
  },
  {
    title: "Flexural strength of specimens",
    norm: "NP EN 12390-5",
    category: "Concrete",
  },
  {
    title: "Self-compacting concrete. L-box test.",
    norm: "NP EN 12350-10",
    category: "Concrete",
  },
  {
    title: "Self-compacting concrete. Sieve segregation test.",
    norm: "NP EN 12350-11",
    category: "Concrete",
  },
  {
    title: "Self-compacting concrete. J-ring test.",
    norm: "NP EN 12350-12",
    category: "Concrete",
  },
  {
    title: "Preparation and curing of specimens for mechanical strength tests",
    norm: "NP EN 12390-2",
    category: "Concrete",
  },
  {
    title: "Hardened concrete density",
    norm: "NP EN 12390-7",
    category: "Concrete",
  },
  {
    title: "Cores. Extraction, examination, and compression test.",
    norm: "NP EN 12504-1",
    category: "Concrete",
  },
  {
    title: "Compressive strength of young sprayed concrete",
    norm: "NP EN 14488-2",
    category: "Concrete",
  },
  {
    title:
      "Flexural strength (maximum, ultimate, and residual) of fiber-reinforced beams",
    norm: "NP EN 14488-3",
    category: "Concrete",
  },
  {
    title: "Simple tensile bond strength in cores",
    norm: "NP EN 14488-4",
    category: "Concrete",
  },
  {
    title: "Concrete thickness over a substrate",
    norm: "NP EN 14488-6",
    category: "Concrete",
  },
  {
    title: "Oxygen permeability determination",
    norm: "E 392",
    category: "Concrete",
  },
  {
    title: "Determination of modulus of elasticity in compression",
    norm: "E 397",
    category: "Concrete",
  },
  {
    title: "Fresh mortar consistency determination",
    norm: "EN 1015-2",
    category: "Mortars and Injection Grouts",
  },
  {
    title: "Mechanical strengths",
    norm: "NP EN 196-1",
    category: "Mortars and Injection Grouts",
  },
  {
    title: "Determination of mixing water for standardized spreading",
    norm: "PIE.017.1",
    category: "Mortars and Injection Grouts",
  },
  {
    title: "Adhesion test: Pull-off",
    norm: "Internal Procedure",
    category: "Mortars and Injection Grouts",
  },
  {
    title: "Flow, exudation, and volume change test",
    norm: "NP EN 445: Section 4.3 + 4.4",
    category: "Mortars and Injection Grouts",
  },
  {
    title: "Compressive strength test",
    norm: "NP EN 445: Section 4.6",
    category: "Mortars and Injection Grouts",
  },
  {
    title: "Density test",
    norm: "NP 445: Section 4.7",
    category: "Mortars and Injection Grouts",
  },
  {
    title: "Cements. Fineness determination.",
    norm: "NP EN 196-6",
    category: "Cements and Additions",
  },
  {
    title: "Cements. Determination of mechanical strengths.",
    norm: "NP EN 196-1",
    category: "Cements and Additions",
  },
  {
    title: "Cements. Determination of heat of hydration.",
    norm: "E 68",
    category: "Cements and Additions",
  },
  {
    title: "Cements. Expansibility test – Le Chatelier method.",
    norm: "E 330",
    category: "Cements and Additions",
  },
  {
    title: "Cements. Setting time and expansibility determination.",
    norm: "NP EN 196-3",
    category: "Cements and Additions",
  },
  {
    title: "Cements. Density determination.",
    norm: "E 64",
    category: "Cements and Additions",
  },
  {
    title: "Cements. Determination of loss on ignition.",
    norm: "NP EN 196-2: Section 4.4.1",
    category: "Cements and Additions",
  },
  {
    title: "Cements. Determination of sulfate content.",
    norm: "NP EN 196-2: Section 4.4.2",
    category: "Cements and Additions",
  },
  {
    title:
      "Cements. Determination of insoluble residue in hydrochloric acid and sodium carbonate.",
    norm: "NP EN 196-2: Section 4.4.3",
    category: "Cements and Additions",
  },
  {
    title:
      "Cements. Determination of insoluble residue in hydrochloric acid and potassium hydroxide.",
    norm: "NP EN 196-2: Section 4.4.4",
    category: "Cements and Additions",
  },
  {
    title: "Cements. Determination of sulfide content.",
    norm: "NP EN 196-2: Section 4.4.5",
    category: "Cements and Additions",
  },
  {
    title: "Cements. Determination of manganese by photometry.",
    norm: "NP EN 196-2: Section 4.4.6",
    category: "Cements and Additions",
  },
  {
    title: "Cements. Disintegration by sodium peroxide.",
    norm: "NP EN 196-2: Section 4.5.2",
    category: "Cements and Additions",
  },
  {
    title:
      "Cements. Insolubilization and determination of silica – Double insolubilization method (reference method).",
    norm: "NP EN 196-2: Section 4.5.3",
    category: "Cements and Additions",
  },
  {
    title:
      "Cements. Decomposition by hydrochloric acid and ammonium chloride and precipitation of silica (alternative method).",
    norm: "NP EN 196-2: Section 4.5.5",
    category: "Cements and Additions",
  },
  {
    title: "Cements. Determination of pure silica.",
    norm: "NP EN 196-2: Section 4.5.6",
    category: "Cements and Additions",
  },
  {
    title: "Cements. Disintegration of evaporation residue.",
    norm: "NP EN 196-2: Section 4.5.7",
    category: "Cements and Additions",
  },
  {
    title: "Cements. Determination of soluble silica.",
    norm: "NP EN 196-2: Section 4.5.8",
    category: "Cements and Additions",
  },
  {
    title: "Cements. Determination of total silica.",
    norm: "NP EN 196-2: Section 4.5.9",
    category: "Cements and Additions",
  },
  {
    title: "Cements. Determination of iron (III) oxide.",
    norm: "NP EN 196-2: Section 4.5.10",
    category: "Cements and Additions",
  },
  {
    title: "Cements. Determination of aluminum oxide.",
    norm: "NP EN 196-2: Section 4.5.11",
    category: "Cements and Additions",
  },
  {
    title:
      "Cements. Determination of calcium oxide by EGTA (reference method).",
    norm: "NP EN 196-2: Section 4.5.12",
    category: "Cements and Additions",
  },
  {
    title:
      "Cements. Determination of magnesium oxide by DCTA (reference method).",
    norm: "NP EN 196-2: Section 4.5.13",
    category: "Cements and Additions",
  },
  {
    title: "Cements. Determination of chlorides.",
    norm: "NP EN 196-2: Section 4.5.16",
    category: "Cements and Additions",
  },
  {
    title:
      "Cements. Determination of carbon dioxide content (reference method).",
    norm: "NP EN 196-2: Section 4.5.17",
    category: "Cements and Additions",
  },
  {
    title: "Cements. Determination of alkali content (reference method).",
    norm: "NP EN 196-2: Section 4.5.19",
    category: "Cements and Additions",
  },
  {
    title: "Pozzolanicity test for pozzolanic cements.",
    norm: "NP EN 196-5",
    category: "Cements and Additions",
  },
  {
    title: "Cements. Heat of hydration. Dissolution method.",
    norm: "NP EN 196-8",
    category: "Cements and Additions",
  },
  {
    title: "Cement pastes. Determination of autogenous shrinkage.",
    norm: "E 476",
    category: "Cements and Additions",
  },
  {
    title: "Fly ash. Fineness determination.",
    norm: "NP EN 451-2",
    category: "Cements and Additions",
  },
  {
    title: "Fly ash. Density determination.",
    norm: "E 64",
    category: "Cements and Additions",
  },
  {
    title: "Fly ash. Determination of activity index.",
    norm: "NP EN 450-1 (NP EN 196-1)",
    category: "Cements and Additions",
  },
  {
    title: "Fly ash. Setting time and expansibility determination.",
    norm: "NP EN 450-1 (NP EN 196-1)",
    category: "Cements and Additions",
  },
  {
    title: "Fly ash. Determination of free lime content.",
    norm: "NP EN 451-1",
    category: "Cements and Additions",
  },
  {
    title:
      "Limestone filler for concrete. Determination of methylene blue value.",
    norm: "E 385",
    category: "Cements and Additions",
  },
  {
    title:
      "Limestone filler for concrete. Determination of total organic carbon (TOC) content.",
    norm: "E 386",
    category: "Cements and Additions",
  },
  {
    title: "Fly ash. Determination of loss on ignition.",
    norm: "NP EN 196-2: Section 4.4.1",
    category: "Cements and Additions",
  },
  {
    title: "Fly ash. Determination of sulfate content.",
    norm: "NP EN 196-2: Section 4.4.2",
    category: "Cements and Additions",
  },
  {
    title: "Fly ash. Determination of chlorides.",
    norm: "NP EN 196-2: Section 4.5.16",
    category: "Cements and Additions",
  },
  {
    title: "Granulometric analysis",
    norm: "EN 13383-2: Section 5",
    category: "Rockfill",
  },
  {
    title: "Particle shape determination",
    norm: "EN 13383-2: Section 7",
    category: "Rockfill",
  },
  {
    title: "Particle density and water absorption determination",
    norm: "EN 13383-2: Section 8",
    category: "Rockfill",
  },
  {
    title: "Bulk density and porosity determination",
    norm: "LNEC Procedure",
    category: "Rockfill",
  },
  {
    title: "Mass distribution determination (Reference Method)",
    norm: "EN 13383-2: Section 6",
    category: "Rockfill",
  },
  {
    title: "Uniaxial compressive strength determination",
    norm: "LNEC Procedure",
    category: "Rockfill",
  },
  {
    title: "Slake Durability Test",
    norm: "LNEC Procedure",
    category: "Rockfill",
  },
  {
    title: "Expansivity determination",
    norm: "LNEC Procedure",
    category: "Rockfill",
  },
  {
    title: "Crushing strength determination",
    norm: "LNEC Procedure",
    category: "Rockfill",
  },
  {
    title: "Void Index determination (Macro Test)",
    norm: "Internal Procedure",
    category: "Rockfill",
  },
  {
    title: "Determination of Aggregate-Binder Affinity",
    norm: "EN 12697-11",
    category: "Bituminous Mixtures",
  },
  {
    title: "Determination of Bulk Density of Specimens",
    norm: "EN 12697-6",
    category: "Bituminous Mixtures",
  },
  {
    title: "Determination of Theoretical Maximum Specific Gravity",
    norm: "EN 12697-5",
    category: "Bituminous Mixtures",
  },
  {
    title: "Particle Size Analysis",
    norm: "EN 12697-2",
    category: "Bituminous Mixtures",
  },
  {
    title: "Marshall Test",
    norm: "EN 12697-34",
    category: "Bituminous Mixtures",
  },
  {
    title: "Determination of Layer Thickness",
    norm: "EN 12697-36",
    category: "Bituminous Mixtures",
  },
  {
    title: "Calculation of Porosity of Specimens",
    norm: "EN 12697-8",
    category: "Bituminous Mixtures",
  },
  {
    title: "Determination of Retained Stability",
    norm: "MIL-STD-620A: Method 104",
    category: "Bituminous Mixtures",
  },
  {
    title: "Determination of Apparent Density",
    norm: "E 267",
    category: "Bituminous Mixtures",
  },
  {
    title:
      "Determination of Bitumen Content and Water Content with Kumagawa Extractor",
    norm: "E 268",
    category: "Bituminous Mixtures",
  },
  {
    title:
      "Determination of Bitumen Content and Determination of Total Inert Content of a Mixture with Reflux Extractor",
    norm: "EN 12697-1",
    category: "Bituminous Mixtures",
  },
  {
    title: "Determination of Density with Pycnometer",
    norm: "E 35",
    category: "Bituminous Mixtures",
  },
  {
    title: "Optimization of Bitumen Content (Marshall Method)",
    norm: "J.A.E. P. 14",
    category: "Bituminous Mixtures",
  },
  {
    title: "Adhesion Test",
    norm: "J.A.E. P. 9",
    category: "Bituminous Mixtures",
  },
  {
    title:
      "Determination of Theoretical Maximum Specific Gravity by Vacuum Pycnometer Method",
    norm: "ASTM D 2041",
    category: "Bituminous Mixtures",
  },
  {
    title:
      "Measurement of Pavement Surface Macrotexture Depth through Spot Volumetric Technique",
    norm: "NP EN 13036-1",
    category: "Bituminous Mixtures",
  },
  {
    title: "Determination of Bulk Density of Compacted Mixtures",
    norm: "ASTM D 2726",
    category: "Bituminous Mixtures",
  },
  {
    title:
      "Effect of Water on Cohesion of Open Bituminous Mixtures Using Cantabro Abrasion Loss Test",
    norm: "NLT 362",
    category: "Bituminous Mixtures",
  },
  {
    title:
      "Permeability (Porous Bituminous Mixtures) In-situ Measurement with LCS",
    norm: "NLT 327",
    category: "Bituminous Mixtures",
  },
  {
    title: "Measurement of Pavement Layer Irregularity: Test with Straightedge",
    norm: "EN 13036-7",
    category: "Bituminous Mixtures",
  },
  {
    title: "Determination of Bulk Density",
    norm: "J.A.E. P. 13",
    category: "Bituminous Mixtures",
  },
  {
    title: "Determination of Soil Resistivity by Wenner Method",
    norm: "ASTM G57",
    category: "Soils",
  },
  {
    title: "Particle Size Analysis",
    norm: "E 196",
    category: "Soils",
  },
  {
    title: "Particle Size Analysis by Wet Sieving",
    norm: "E 239",
    category: "Soils",
  },
  {
    title: "Compaction Test",
    norm: "E 197",
    category: "Soils",
  },
  {
    title: "Determination of CBR (California Bearing Ratio)",
    norm: "E 198",
    category: "Soils",
  },
  {
    title: "CBR Correction",
    norm: "-",
    category: "Soils",
  },
  {
    title: "Determination of Particle Density",
    norm: "NP 83",
    category: "Soils",
  },
  {
    title: "Determination of Water Content",
    norm: "NP 84",
    category: "Soils",
  },
  {
    title: "Determination of Water Content",
    norm: "EN ISO 17892-1",
    category: "Soils",
  },
  {
    title: "Sand Equivalent Test",
    norm: "E 199",
    category: "Soils",
  },
  {
    title: "Expansion Test",
    norm: "E 200",
    category: "Soils",
  },
  {
    title: "Determination of Dry Bulk Density In-situ using Sand Bottle Method",
    norm: "E 204",
    category: "Soils",
  },
  {
    title: "Permeability Test - Constant Head",
    norm: "PIE.005.1",
    category: "Soils",
  },
  {
    title: "Permeability Test - Falling Head",
    norm: "PIE.004.1",
    category: "Soils",
  },
  {
    title: "Plate Load Test",
    norm: "NF 9-94-117-1 / ASTM D 1194 / ENV 1997-3 / DIN 18134",
    category: "Soils",
  },
  {
    title: "Organic Matter Content",
    norm: "E 201 / J.A.E. S.9 / NLT 117",
    category: "Soils",
  },
  {
    title: "Immediate CBR Test (CBR without soaking and without surcharge)",
    norm: "NF P94-078",
    category: "Soils",
  },
  {
    title: "PDL Test (Light Dynamic Penetrometer)",
    norm: "PIE.001.1",
    category: "Soils",
  },
  {
    title: "Compaction Control with Nuclear Density Gauge",
    norm: "ASTM D 6938",
    category: "Soils",
  },
  {
    title: "Direct Shear Test",
    norm: "BS 1377:Part 1",
    category: "Soils",
  },
  {
    title: "Edometric Test",
    norm: "PIE.033.1",
    category: "Soils",
  },
  {
    title: "Triaxial Tests",
    norm: "PIE.034.1",
    category: "Soils",
  },
  {
    title: "Compaction with Vibrating Hammer",
    norm: "BS 1924-2: Section 2.1.5",
    category: "Soils",
  },
  {
    title: "Determination of Consistency Limits",
    norm: "NP 143",
    category: "Soils",
  },
  {
    title: "Unified Classification",
    norm: "Regulation for Small Earth Dams – Decree 48373 of 08/05/1968",
    category: "Soils",
  },
  {
    title: "Classification for Road Purposes",
    norm: "E 240",
    category: "Soils",
  },
  {
    title: "Determination of Methylene Blue Value",
    norm: "AFNOR P 18-592",
    category: "Soils",
  },
  {
    title:
      "Determination of Organic Matter. Hydrogen Peroxide Reduction Method.",
    norm: "PIE.003.1",
    category: "Soils",
  },
  {
    title: "Determination of Chloride Content. Potentiometric Method.",
    norm: "PIE.010.1",
    category: "Soils",
  },
  {
    title: "pH Determination",
    norm: "E 203",
    category: "Soils",
  },
  {
    title: "Determination of Sulfate Content in Soil",
    norm: "E 202",
    category: "Soils",
  },
  {
    title: "Resistivity Test with Wenner Probe",
    norm: "PIE.011.1",
    category: "Soils",
  },
  {
    title: "Resistivity Test with Conductivity Probe",
    norm: "PIE.012.1",
    category: "Soils",
  },
  {
    title: "Fragmentability Coefficient of Rock Materials",
    norm: "NF P 94-066",
    category: "Soils",
  },
  {
    title: "Degradability Coefficient of Rock Materials",
    norm: "NF P 94-067",
    category: "Soils",
  },
  {
    title: "Dry Bulk Density Determination",
    norm: "PIE.008.1",
    category: "Soils",
  },
  {
    title: "Particle Size Analysis. Macro-Test.",
    norm: "PIE.027.1",
    category: "Soils",
  },
  {
    title:
      "Determination of Apparent Bulk Density and Open Porosity in Natural Stones",
    norm: "NP EN 1936",
    category: "Natural Stones",
  },
  {
    title: "Point Load Test",
    norm: "ASTM D 5731",
    category: "Natural Stones",
  },
  {
    title: "Determination of Uniaxial Compressive Strength",
    norm: "NP EN 1926",
    category: "Natural Stones",
  },
  {
    title: "Slake Test",
    norm: "ASTM D 4644",
    category: "Natural Stones",
  },
  {
    title: "Point Load Test (EN 1926 – Annex B)",
    norm: "PIE.009.1",
    category: "Natural Stones",
  },
  {
    title: "Determination of Breaking Load by Compression",
    norm: "NP 1040",
    category: "Natural Stones",
  },
  {
    title: "Determination of Coefficient of Linear Thermal Expansion",
    norm: "NP EN 14581: Method A",
    category: "Natural Stones",
  },
];

export default ensaiosDataEng;
