import { useState, useEffect, useContext } from "react";
import ensaiosData from "../../../data/ensaios";
import ensaiosDataEng from "../../../data/ensaiosEng";
import ArrowSubtitle from "../../global/ArrowSubtitle";
import Modal from "react-modal";
import classNames from "classnames";
import { LanguageContext } from "../../../App";

import AOS from "aos";
import "aos/dist/aos.css"; // Import the CSS for AOS animations

export default function EnsaiosSearch() {
  const { currentLanguage } = useContext(LanguageContext);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    AOS.init({ anchorPlacement: "top-bottom" });
  }, []); // Run this effect only once after initial render

  const openModal = (type) => {
    setModalIsOpen(type);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleSearch = () => {
    const filteredResults = (
      currentLanguage === "PT" ? ensaiosData : ensaiosDataEng
    ).filter((item) => {
      const { title, norm, category } = item;
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      return (
        title.toLowerCase().includes(lowerCaseSearchTerm) ||
        norm.toLowerCase().includes(lowerCaseSearchTerm) ||
        category.toLowerCase().includes(lowerCaseSearchTerm)
      );
    });
    setSearchResults(filteredResults);
    openModal("search");
  };

  const handleCategoryClick = (category) => {
    const filteredResults = (
      currentLanguage === "PT" ? ensaiosData : ensaiosDataEng
    ).filter((item) => item.category === category);
    setSearchResults(filteredResults);
    openModal(category);
  };

  const categories = [
    ...new Set(
      (currentLanguage === "PT" ? ensaiosData : ensaiosDataEng).map(
        (item) => item.category,
      ),
    ),
  ];

  const categoryButtons = categories.map((item, index) => {
    return (
      <button
        key={index}
        className="rounded-[3px] bg-green-3 px-6 py-3 text-sm uppercase text-white outline-none transition-colors hover:bg-green-4 focus:bg-green-4 active:bg-green-4 active:hover:bg-dark-0 md:text-lg"
        onClick={() => handleCategoryClick(item)}
        data-aos="fade-up"
      >
        {item}
      </button>
    );
  });

  const searchBar = (
    <>
      <input
        className="h-12 w-full rounded-[3px] border-[1.5px] border-green-3 bg-transparent pl-4 pr-16 text-green-3 outline-none transition-colors hover:border-green-1 focus:border-green-1 active:border-green-1 active:hover:border-green-2"
        type="text"
        placeholder={
          currentLanguage === "PT"
            ? "Pesquisar por nome..."
            : "Search by name..."
        }
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyDown={(e) => e.key === "Enter" && handleSearch()}
      />
      <button
        className="mask-search absolute right-0 top-0 h-full w-16 bg-green-3 bg-clip-content transition-colors hover:bg-green-1 focus:bg-green-1 active:bg-green-1 active:hover:bg-green-2"
        onClick={() => handleSearch()}
      ></button>
    </>
  );

  return (
    <>
      <div
        className="mb-16 flex w-full flex-col flex-wrap items-center justify-center lg:mb-32"
        data-aos="fade-up"
      >
        <ArrowSubtitle text={"Áreas de Ensaio:"} classes="mb-10" />
        <div className="relative mb-14 w-full lg:mb-24 lg:w-1/2">
          {searchBar}
        </div>

        <div className="int flex w-full flex-wrap items-center justify-center gap-x-6  gap-y-6 lg:gap-x-12 lg:gap-y-20">
          {categoryButtons}
        </div>
      </div>
      <Modal
        className="absolute inset-0 mt-8 overflow-auto rounded-[3px] outline-none transition-opacity"
        overlayClassName="fixed inset-0 bg-[#000] bg-opacity-75 z-50 transition-opacity"
        closeTimeoutMS={300}
        isOpen={categories.includes(modalIsOpen) || modalIsOpen === "search"}
        onRequestClose={closeModal}
        bodyOpenClassName="overflow-hidden"
        htmlOpenClassName="overflow-hidden"
        appElement={document.getElementById("root")}
      >
        <div className="container mx-auto px-8 pb-8">
          <div className="w-full overflow-hidden rounded-[3px] bg-white">
            {categories.includes(modalIsOpen) && (
              <div className="relative mb-6 w-full bg-green-3 py-4 text-xl font-medium lg:mb-12">
                <h2 className="px-16 text-center uppercase text-white">
                  {modalIsOpen}
                </h2>
                <button
                  className="mask-close absolute right-0 top-0 h-16 w-16 bg-white bg-clip-content transition-colors hover:bg-green-1 focus:bg-green-1 active:bg-green-1 active:hover:bg-green-2"
                  onClick={() => closeModal()}
                ></button>
              </div>
            )}
            {modalIsOpen === "search" && (
              <>
                <div className="relative h-16 w-full py-4 text-xl font-medium">
                  <button
                    className="mask-close absolute right-0 top-0 h-16 w-16 bg-dark-4 bg-clip-content transition-colors hover:bg-green-1 focus:bg-green-1 active:bg-green-1 active:hover:bg-green-2"
                    onClick={() => closeModal()}
                  ></button>
                </div>
                <div className="mb-6 w-full px-4 lg:mb-12 lg:px-16">
                  <div className="relative w-full">{searchBar}</div>
                </div>
              </>
            )}
            {searchResults.length !== 0 && (
              <ul className="lg:px-16 lg:pb-12">
                <li className="flex border-b-[1.5px] border-green-3 pb-4 text-xl uppercase leading-none text-green-3">
                  <div className="w-2/3 pl-4">
                    {currentLanguage === "PT" ? "Ensaios" : "Tests"}
                  </div>
                  <div className="w-1/3">
                    {currentLanguage === "PT" ? "Normas" : "Standards"}
                  </div>
                </li>
                {searchResults.map((result, index) => (
                  <li
                    key={index}
                    className={classNames(
                      index % 2 !== 0 && "bg-light-2",
                      "flex py-6 font-medium uppercase text-dark-4",
                    )}
                  >
                    <div className="w-2/3 pl-4 pr-8">{result.title}</div>
                    <div className="w-1/3 pr-4">{result.norm}</div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}
