const ensaiosData = [
  {
    title: "Ensaio de tração de varões de aço",
    norm: "EN ISO 15630-1 (ISO 6892-1)",
    category: "Aços",
  },
  {
    title: "Determinação das características geométricas",
    norm: "EN ISO 15630-1",
    category: "Aços",
  },
  {
    title: "Profundidade de Carbonatação",
    norm: "Procedimento Interno",
    category: "Durabilidade do betão",
  },
  {
    title: "Profundidade de penetração de cloretos",
    norm: "Procedimento Interno",
    category: "Durabilidade do betão",
  },
  {
    title: "Determinação da espessura de recobrimento de armaduras",
    norm: "Procedimento Interno",
    category: "Durabilidade do betão",
  },
  {
    title: "Mapeamento de armaduras",
    norm: "Procedimento Interno",
    category: "Durabilidade do betão",
  },
  {
    title: "Determinação do potencial de corrosão",
    norm: "ASTM C876",
    category: "Durabilidade do betão",
  },
  {
    title: "Determinação da resistividade elétrica do betão",
    norm: "Procedimento Interno",
    category: "Durabilidade do betão",
  },
  {
    title: "Avaliação de resistência por esclerometria",
    norm: "EN 12504-2",
    category: "Durabilidade do betão",
  },
  {
    title: "Instalação de fissurometros",
    norm: "Procedimento Interno",
    category: "Durabilidade do betão",
  },
  {
    title: "Determinação da Resistência à Compressão",
    norm: "NP EN 13286-41",
    category: "Misturas tratadas com ligantes hidráulicos",
  },
  {
    title: "Determinação da Resistência à Tração indireta",
    norm: "NP EN 13286-42",
    category: "Misturas tratadas com ligantes hidráulicos",
  },
  {
    title: "Fabrico de provetes por compactação com martelo vibrocompactador",
    norm: "NP EN 13286-51",
    category: "Misturas tratadas com ligantes hidráulicos",
  },
  {
    title: "Análise granulométrica",
    norm: "E 233",
    category: "Agregados",
  },
  {
    title: "Ensaio de desgaste pela máquina de Los Angeles",
    norm: "E 237",
    category: "Agregados",
  },
  {
    title: "Determinação do índice de lamelação",
    norm: "B.S. 812 Part 105.1",
    category: "Agregados",
  },
  {
    title: "Determinação do índice de alongamento",
    norm: "B.S. 812 Part 105.2",
    category: "Agregados",
  },
  {
    title: "Análise granulométrica de fíler",
    norm: "E 234",
    category: "Agregados",
  },
  {
    title: "Determinação do índice volumétrico",
    norm: "E 223",
    category: "Agregados",
  },
  {
    title:
      "Determinação da quantidade de material que passa no peneiro de 0,074 mm",
    norm: "E 235",
    category: "Agregados",
  },
  {
    title: "Determinação do teor em partículas moles",
    norm: "E222",
    category: "Agregados",
  },
  {
    title: "Determinação da baridade",
    norm: "NP 955",
    category: "Agregados",
  },
  {
    title: "Métodos de amostragem de agregados",
    norm: "NP EN 932-1",
    category: "Agregados",
  },
  {
    title:
      "Determinação das massas volúmicas e da absorção de águas de britas e godos",
    norm: "NP 581",
    category: "Agregados",
  },
  {
    title: "Análise ganulométrica - Método de peneiração",
    norm: "NP EN 933-1",
    category: "Agregados",
  },
  {
    title: "Determinação das massas volúmicas e da absorção de água de areias",
    norm: "NP 954",
    category: "Agregados",
  },
  {
    title: "Determinação da forma das partículas Índice de achatamento",
    norm: "EN 933-3",
    category: "Agregados",
  },
  {
    title: "Determinação da forma das partículas - Índice de forma",
    norm: "EN 933-4",
    category: "Agregados",
  },
  {
    title:
      "Determinação da percentagem de superfícies esmagadas e partidas nos agregados grossos",
    norm: "NP EN 933-5",
    category: "Agregados",
  },
  {
    title:
      "Determinação do teor de conchas - Percentagem de conchas nos agregados",
    norm: "NP EN 933-7",
    category: "Agregados",
  },
  {
    title: "Determinação do teor de finos - Ensaio do equivalente de areia",
    norm: "EN 933-8",
    category: "Agregados",
  },
  {
    title: "Análise dos finos - Ensaio do azul metileno",
    norm: "EN 933-9 / AFNOR P 18-592",
    category: "Agregados",
  },
  {
    title: "Método para determinação da massa volúmica e dos vazios",
    norm: "NP EN 1097-3",
    category: "Agregados",
  },
  {
    title: "Determinação do teor de humidade por secagem em estufa ventilada",
    norm: "NP EN 1097-5",
    category: "Agregados",
  },
  {
    title: "Determinação da massa volúmica e da absorção de água",
    norm: "NP EN 1097-6",
    category: "Agregados",
  },
  {
    title: "Determinação da massa volúmica do fíler - Método do picnómetro",
    norm: "NP EN 1097-7",
    category: "Agregados",
  },
  {
    title: "Coeficiente de forma",
    norm: "AFNOR",
    category: "Agregados",
  },
  {
    title: "Reactividade dos álcalis dos agregados",
    norm: "ASTM C1260",
    category: "Agregados",
  },
  {
    title: "Determinação da resistência ao desgaste (Micro-Deval)",
    norm: "NP EN 1097-1",
    category: "Agregados",
  },
  {
    title: "Determinação dos teores em água total e em água superficial",
    norm: "NP 956",
    category: "Agregados",
  },
  {
    title: "Determinação do teor em água superficial de areias",
    norm: "NP 957",
    category: "Agregados",
  },
  {
    title: "Determinação da resistência ao esmagamento",
    norm: "NP 1039",
    category: "Agregados",
  },
  {
    title: "Determinação do teor em partículas muito finas e matérias solúveis",
    norm: "NP 86",
    category: "Agregados",
  },
  {
    title: "Pesquisa da matéria orgânica pelo processo do ácido tânico",
    norm: "NP 85",
    category: "Agregados",
  },
  {
    title: "Determinação do teor de partículas friáveis",
    norm: "NP 1380",
    category: "Agregados",
  },
  {
    title: "Determinação do teor em partículas leves",
    norm: "NP 953",
    category: "Agregados",
  },
  {
    title:
      "Determinação da reatividade com sulfatos em presença de hidróxido de cálcio",
    norm: "E 251",
    category: "Agregados",
  },
  {
    title: "Determinação da resistência à fragmentação (Los Angeles)",
    norm: "NP EN 1097-2",
    category: "Agregados",
  },
  {
    title: "Ensaio de desgaste pela máquina de Los Angeles",
    norm: "ASTM C535",
    category: "Agregados",
  },
  {
    title:
      "Determinação da baridade seca máxima - Ensaio de compactação Proctor",
    norm: "EN 13286-2",
    category: "Agregados",
  },
  {
    title: "Correcção do ensaio de compactação",
    norm: "PIE.002.1",
    category: "Agregados",
  },
  {
    title: "Controlo de compactação com gamadensímetro",
    norm: "ASTM D 6938",
    category: "Agregados",
  },
  {
    title: "Classificação dos componentes dos agregados grossos reciclados",
    norm: "NP EN 933-11",
    category: "Agregados",
  },
  {
    title:
      "Determinação de sais de cloreto solúveis em água utilizando o método de Volhard",
    norm: "EN 1744-1:Parte 7",
    category: "Agregados",
  },
  {
    title:
      "Determinação potenciométrica de sais de cloreto solúveis em água (método alternativo)",
    norm: "EN 1744-1:Parte 8",
    category: "Agregados",
  },
  {
    title:
      "Determinação de sais de cloreto solúveis em água por aplicação do método de Mohr (método alternativo)",
    norm: "EN 1744-1:Parte 9",
    category: "Agregados",
  },
  {
    title: "Determinação dos sulfatos solúveis em água",
    norm: "EN 1744-1:Parte 10",
    category: "Agregados",
  },
  {
    title: "Determinação do teor total de enxofre",
    norm: "EN 1744-1:Parte 11",
    category: "Agregados",
  },
  {
    title: "Determinação de sulfatos solúveis em ácido",
    norm: "EN 1744-1:Parte 12",
    category: "Agregados",
  },
  {
    title: "Determinação dos sulfuretos solúveis em ácido",
    norm: "EN 1744-1:Parte 13",
    category: "Agregados",
  },
  {
    title:
      "Análise relativa à presença de partículas de sulfureto de ferro reativas",
    norm: "EN 1744-1:Parte 14.1",
    category: "Agregados",
  },
  {
    title: "Determinação dos contaminadores leves",
    norm: "EN 1744-1:Parte 14.2",
    category: "Agregados",
  },
  {
    title: "Determinação do teor em húmus",
    norm: "EN 1744-1:Parte 15.1",
    category: "Agregados",
  },
  {
    title: "Determinação do teor de ácido fúlvico",
    norm: "EN 1744-1:Parte 15.2",
    category: "Agregados",
  },
  {
    title: "Determinação da solubilidade na água",
    norm: "EN 1744-1:Parte 16",
    category: "Agregados",
  },
  {
    title: "Determinação da perda por ignição",
    norm: "EN 1744-1:Parte 17",
    category: "Agregados",
  },
  {
    title:
      "Determinação de cal livre por complexometria (método de referência)",
    norm: "EN 1744-1:Parte 18.2",
    category: "Agregados",
  },
  {
    title: "Determinação de cal livre por condutometria (método alternativo)",
    norm: "EN 1744-1:Parte 18.3",
    category: "Agregados",
  },
  {
    title: "Determinação de cal livre por acidimetria (método alternativo)",
    norm: "EN 1744-1:Parte 18.4",
    category: "Agregados",
  },
  {
    title:
      "Determinação da desintegração do silicato dicálcico de escórias de alto forno arrefecido ao ar",
    norm: "EN 1744-1:Parte 19.1",
    category: "Agregados",
  },
  {
    title:
      "Determinação da desintegração do ferro de escórias de alto forno arrefecidas ao ar",
    norm: "EN 1744-1:Parte 19.2",
    category: "Agregados",
  },
  {
    title: "Determinação da expansão da escória de aço",
    norm: "EN 1744-1:Parte 19.3",
    category: "Agregados",
  },
  {
    title: "Determinação de sais de cloreto solúveis em ácido",
    norm: "NP EN 1744-5",
    category: "Agregados",
  },
  {
    title:
      "Determinação do teor de álcalis solúveis. Processo por espectrofotometria de chama.",
    norm: "NP 1382",
    category: "Agregados",
  },
  {
    title:
      "Ensaio de compactação de material granular estabilizado com ligantes hidráulicos (Método do Vibro-Compactador)",
    norm: "BS 1924-2: Section 2.1.5",
    category: "Agregados",
  },
  {
    title: "Determinação dos vazios do fíler seco compactado",
    norm: "NP EN 1097-4",
    category: "Agregados",
  },
  {
    title: "Ensaio do sulfato de magnésio",
    norm: "NP EN 1367-2",
    category: "Agregados",
  },
  {
    title: "Ensaio de ebulição para basaltos “Sonnenbrand”",
    norm: "NP EN 1367-3",
    category: "Agregados",
  },
  {
    title: "Determinação da resistência ao choque térmico",
    norm: "EN 1367-5",
    category: "Agregados",
  },
  {
    title: "Preparação de eluatos por lexiviação dos agregados",
    norm: "NP EN 1744-3",
    category: "Agregados",
  },
  {
    title:
      "Determinação da influência do extracto de agregados reciclados no tempo de inicio de presa do cimento",
    norm: "NP EN 1744-6",
    category: "Agregados",
  },
  {
    title: "Determinação da retracção por secagem",
    norm: "NP EN 1367-4",
    category: "Agregados",
  },
  {
    title: "Determinação do pH",
    norm: "ISO 4316",
    category: "Adjuvantes para betão, argamassa e caldas",
  },
  {
    title: "Determinação do teor de cloretos solúveis em água",
    norm: "NP EN 480-10",
    category: "Adjuvantes para betão, argamassa e caldas",
  },
  {
    title: "Determinação do teor de álcalis",
    norm: "NP EN 480-12",
    category: "Adjuvantes para betão, argamassa e caldas",
  },
  {
    title: "Determinação do teor de resíduo seco",
    norm: "NP EN 480-8",
    category: "Adjuvantes para betão, argamassa e caldas",
  },
  {
    title: "Determinação da massa volúmica",
    norm: "ISO 758",
    category: "Adjuvantes para betão, argamassa e caldas",
  },
  {
    title: "Análise por espectrofotometria de infravermelhos",
    norm: "NP EN 480-6",
    category: "Adjuvantes para betão, argamassa e caldas",
  },
  {
    title: "Betão de referência e argamassa de referência para ensaio",
    norm: "NP EN 480-1",
    category: "Adjuvantes para betão, argamassa e caldas",
  },
  {
    title: "Determinação do tempo de presa",
    norm: "NP EN 480-2",
    category: "Adjuvantes para betão, argamassa e caldas",
  },
  {
    title: "Determinação da exsudação do betão",
    norm: "NP EN 480-4",
    category: "Adjuvantes para betão, argamassa e caldas",
  },
  {
    title: "Determinação da absorção capilar",
    norm: "NP EN 480-5",
    category: "Adjuvantes para betão, argamassa e caldas",
  },
  {
    title:
      "Argamassa de alvenaria de referência para o ensaio de adjuvantes para argamassa",
    norm: "NP EN 480-13",
    category: "Adjuvantes para betão, argamassa e caldas",
  },
  {
    title: "Determinação da razão A/L do betão",
    norm: "Procedimento Interno",
    category: "Betões",
  },
  {
    title: "Moldagem e cura de provetes de betão",
    norm: "EN 12390-2",
    category: "Betões",
  },
  {
    title: "Ensaio de compressão",
    norm: "E 226",
    category: "Betões",
  },
  {
    title: "Ensaio de abaixamento",
    norm: "NP 87 / NP EN 12350-2",
    category: "Betões",
  },
  {
    title: "Determinação da massa volúmica do betão fresco",
    norm: "NP 1384 / NP EN 12350-6",
    category: "Betões",
  },
  {
    title: "Determinação da composição do betão fresco",
    norm: "NP 1385",
    category: "Betões",
  },
  {
    title: "Determinação dos tempos de presa",
    norm: "NP 1387",
    category: "Betões",
  },
  {
    title: "Determinação do teor em ar do betão fresco",
    norm: "EN 12350-7",
    category: "Betões",
  },
  {
    title: "Ensaio de compacidade",
    norm: "ISO 4111",
    category: "Betões",
  },
  {
    title: "Ensaio de espalhamento",
    norm: "NP 414",
    category: "Betões",
  },
  {
    title:
      "Resistência de betões hidráulicos à tarcção por compressão diametral em provetes fabricados em laboratório (Ensaio Brasileiro) aos i dias",
    norm: "ASTM C 496",
    category: "Betões",
  },
  {
    title: "Resistência à penetração da água",
    norm: "ISO 7031",
    category: "Betões",
  },
  {
    title: "Ensaio à compressão. Tratamento estatístico de resultados.",
    norm: "NP EN 206-1",
    category: "Betões",
  },
  {
    title: "Resistência à compressão",
    norm: "NP EN 12390-3",
    category: "Betões",
  },
  {
    title:
      "Resistência de betões hidráulicos à tracção, por flexão, aos i dias",
    norm: "E 227 / E 255",
    category: "Betões",
  },
  {
    title: "Dosagem de fibra no betão reforçado com fibras",
    norm: "NP EN 14488-7",
    category: "Betões",
  },
  {
    title: "Determinação da massa volúmica, da absorção e porosidade",
    norm: "ASTM C 642",
    category: "Betões",
  },
  {
    title: "Ensaio de arrancamento",
    norm: "NP EN 12504-3",
    category: "Betões",
  },
  {
    title: "Resistência à tracção por compressão",
    norm: "NP EN 12390-6",
    category: "Betões",
  },
  {
    title:
      "Determinação da absorção de água por imersão. Ensaio à pressão atmosférica",
    norm: "E 394",
    category: "Betões",
  },
  {
    title: "Determinação do teor de ar. Métodos pressiométricos.",
    norm: "NP EN 12350-7",
    category: "Betões",
  },
  {
    title: "Determinação do teor em cloretos no betão",
    norm: "PIE.029.1",
    category: "Betões",
  },
  {
    title: "Determinação do teor em álcalis no betão",
    norm: "PIE.030.1",
    category: "Betões",
  },
  {
    title: "Resistividade dos betões",
    norm: "PIE.031.1",
    category: "Betões",
  },
  {
    title: "Profundidade de carbonatação do betão",
    norm: "PIE.032.1",
    category: "Betões",
  },
  {
    title: "Determinação do módulo de elasticidade",
    norm: "EN 12390-13 (Método B)",
    category: "Betões",
  },
  {
    title: "Módulo de elasticidade",
    norm: "DIN 1048-5",
    category: "Betões",
  },
  {
    title: "Determinação da retracção e expansão de betões",
    norm: "E 398",
    category: "Betões",
  },
  {
    title:
      "Determinação da resistência à penetração de cloretos. Ensaio de imersão.",
    norm: "E 390",
    category: "Betões",
  },
  {
    title:
      "Determinação do coeficiente de difusão dos cloretos por ensaio de migração em regime não estacionário.",
    norm: "E 463",
    category: "Betões",
  },
  {
    title: "Determinação da absorção de água por capilaridade.",
    norm: "E 393",
    category: "Betões",
  },
  {
    title: "Profundidade da penetração da água sob pressão",
    norm: "NP EN 12390-8",
    category: "Betões",
  },
  {
    title:
      "Determinação da capacidade de absorção de energias de provetes de lajes reforçadas com fibras",
    norm: "NP EN 14488-5",
    category: "Betões",
  },
  {
    title: "Determinação da velocidade de propagação dos ultra-sons",
    norm: "NP EN 12504-4",
    category: "Betões",
  },
  {
    title: "Detecção de armaduras",
    norm: "BS 1881:Part 204",
    category: "Betões",
  },
  {
    title: "Controlo de betões com esclerómetro",
    norm: "NP EN 12504-2",
    category: "Betões",
  },
  {
    title: "Ensaio Vêbê",
    norm: "NP EN 12350-3",
    category: "Betões",
  },
  {
    title: "Grua de compactabilidade",
    norm: "NP EN 12350-4",
    category: "Betões",
  },
  {
    title: "Ensaio da mesa de espalhamento",
    norm: "NP EN 12350-5",
    category: "Betões",
  },
  {
    title: "Determinação de água por imersão. Ensaio no vácuo.",
    norm: "E 395",
    category: "Betões",
  },
  {
    title: "Betão autocompactável. Ensaio de espalhamento.",
    norm: "NP EN 12350-8",
    category: "Betões",
  },
  {
    title: "Resistência à flexão de provetes",
    norm: "NP EN 12390-5",
    category: "Betões",
  },
  {
    title: "Betão autocompactável. Ensaio de escoamento na caixa L.",
    norm: "NP EN 12350-10",
    category: "Betões",
  },
  {
    title: "Betão autocompactável. Ensaio de segregação no peneiro.",
    norm: "NP EN 12350-11",
    category: "Betões",
  },
  {
    title: "Betão autocompactável. Ensaio de espalhamento no anel J",
    norm: "NP EN 12350-12",
    category: "Betões",
  },
  {
    title: "Execução e cura de provetes para ensaios de resistência mecânica",
    norm: "NP EN 12390-2",
    category: "Betões",
  },
  {
    title: "Massa volúmica do betão endurecido",
    norm: "NP EN 12390-7",
    category: "Betões",
  },
  {
    title: "Carotes. Extracção, exame e ensaio à compressão.",
    norm: "NP EN 12504-1",
    category: "Betões",
  },
  {
    title: "Resistência à compressão do betão projectado jovem",
    norm: "NP EN 14488-2",
    category: "Betões",
  },
  {
    title:
      "Resistência à flexão (máxima, última e residual) de vigas reforçadas com fibras",
    norm: "NP EN 14488-3",
    category: "Betões",
  },
  {
    title: "Resistência de aderência em carotes à tracção simples",
    norm: "NP EN 14488-4",
    category: "Betões",
  },
  {
    title: "Espessura de betão sobre um substrato",
    norm: "NP EN 14488-6",
    category: "Betões",
  },
  {
    title: "Determinação da permeabilidade ao oxigénio",
    norm: "E 392",
    category: "Betões",
  },
  {
    title: "Determinação do módulo de elasticidade em compressão",
    norm: "E 397",
    category: "Betões",
  },
  {
    title: "Determinação da consistência da argamassa fresca",
    norm: "EN 1015-2",
    category: "Argamassas e Caldas de Injeção",
  },
  {
    title: "Resistências mecânicas",
    norm: "NP EN 196-1",
    category: "Argamassas e Caldas de Injeção",
  },
  {
    title: "Determinação da água de amassadura para espalhamento normalizado",
    norm: "PIE.017.1",
    category: "Argamassas e Caldas de Injeção",
  },
  {
    title: "Ensaio de aderência: Pull-off",
    norm: "Procedimento Interno",
    category: "Argamassas e Caldas de Injeção",
  },
  {
    title: "Ensaio de fluidez, exsudação e variação de volume",
    norm: "NP EN 445: Section 4.3 + 4.4",
    category: "Argamassas e Caldas de Injeção",
  },
  {
    title: "Ensaio de resistência à compressão",
    norm: "NP EN 445: Section 4.6",
    category: "Argamassas e Caldas de Injeção",
  },
  {
    title: "Ensaio da massa volúmica",
    norm: "NP 445: Section 4.7",
    category: "Argamassas e Caldas de Injeção",
  },
  {
    title: "Cimentos. Determinação da finura.",
    norm: "NP EN 196-6",
    category: "Cimentos e Adições",
  },
  {
    title: "Cimentos. Determinação das resistências mecânicas.",
    norm: "NP EN 196-1",
    category: "Cimentos e Adições",
  },
  {
    title: "Cimentos. Determinação do calor de hidratação.",
    norm: "E 68",
    category: "Cimentos e Adições",
  },
  {
    title: "Cimentos. Ensaio de expansibilidade – Processo de Le Chatelier.",
    norm: "E 330",
    category: "Cimentos e Adições",
  },
  {
    title: "Cimentos. Determinação do tempo de presa e expansibilidade.",
    norm: "NP EN 196-3",
    category: "Cimentos e Adições",
  },
  {
    title: "Cimentos. Determinação da massa volúmica.",
    norm: "E 64",
    category: "Cimentos e Adições",
  },
  {
    title: "Cimentos. Determinação da perda ao fogo.",
    norm: "NP EN 196-2:Secção 4.4.1",
    category: "Cimentos e Adições",
  },
  {
    title: "Cimentos. Determinação do sulfato.",
    norm: "NP EN 196-2:Secção 4.4.2",
    category: "Cimentos e Adições",
  },
  {
    title:
      "Cimentos. Determinação do resíduo insolúvel em ácido clorídrico e carbonato de sódio.",
    norm: "NP EN 196-2:Secção 4.4.3",
    category: "Cimentos e Adições",
  },
  {
    title:
      "Cimentos. Determinação do resíduo insolúvel em ácido clorídrico e hidróxido de potássio.",
    norm: "NP EN 196-2:Secção 4.4.4",
    category: "Cimentos e Adições",
  },
  {
    title: "Cimentos. Determinação do sulfureto.",
    norm: "NP EN 196-2:Secção 4.4.5",
    category: "Cimentos e Adições",
  },
  {
    title: "Cimentos. Determinação do manganês por fotometria.",
    norm: "NP EN 196-2:Secção 4.4.6",
    category: "Cimentos e Adições",
  },
  {
    title: "Cimentos. Desagregação pelo peróxido de sódio.",
    norm: "NP EN 196-2:Secção 4.5.2",
    category: "Cimentos e Adições",
  },
  {
    title:
      "Cimentos. Insolubilização e determinação da sílica – Método por dupla insolubilização (método de referência)",
    norm: "NP EN 196-2:Secção 4.5.3",
    category: "Cimentos e Adições",
  },
  {
    title:
      "Cimentos. Decomposição por ácido clorídrico e cloreto de amónio e precipitação da sílica (método alternativo)",
    norm: "NP EN 196-2:Secção 4.5.5",
    category: "Cimentos e Adições",
  },
  {
    title: "Cimentos. Determinação da sílica pura.",
    norm: "NP EN 196-2:Secção 4.5.6",
    category: "Cimentos e Adições",
  },
  {
    title: "Cimentos. Desagregação do resíduo de evaporação.",
    norm: "NP EN 196-2:Secção 4.5.7",
    category: "Cimentos e Adições",
  },
  {
    title: "Cimentos. Determinação da sílica solúvel.",
    norm: "NP EN 196-2:Secção 4.5.8",
    category: "Cimentos e Adições",
  },
  {
    title: "Cimentos. Determinação da sílica total.",
    norm: "NP EN 196-2:Secção 4.5.9",
    category: "Cimentos e Adições",
  },
  {
    title: "Cimentos. Determinação do óxido de ferro (III).",
    norm: "NP EN 196-2:Secção 4.5.10",
    category: "Cimentos e Adições",
  },
  {
    title: "Cimentos. Determinação do óxido de alumínio.",
    norm: "NP EN 196-2:Secção 4.5.11",
    category: "Cimentos e Adições",
  },
  {
    title:
      "Cimentos. Determinação do óxido de cálcio pelo EGTA (método de referência)",
    norm: "NP EN 196-2:Secção 4.5.12",
    category: "Cimentos e Adições",
  },
  {
    title:
      "Cimentos. Determinação do óxido de magnésio pelo DCTA (método de referência)",
    norm: "NP EN 196-2:Secção 4.5.13",
    category: "Cimentos e Adições",
  },
  {
    title: "Cimentos. Determinação dos cloretos",
    norm: "NP EN 196-2:Secção 4.5.16",
    category: "Cimentos e Adições",
  },
  {
    title:
      "Cimentos. Determinação do teor de dióxido de carbono (método de referência)",
    norm: "NP EN 196-2:Secção 4.5.17",
    category: "Cimentos e Adições",
  },
  {
    title: "Cimentos. Determinação do teor de álcalis (método de referência)",
    norm: "NP EN 196-2:Secção 4.5.19",
    category: "Cimentos e Adições",
  },
  {
    title: "Ensaio de pozolanicidade dos cimentos pozolânicos",
    norm: "NP EN 196-5",
    category: "Cimentos e Adições",
  },
  {
    title: "Cimentos. Calor de hidratação. Método da dissolução.",
    norm: "NP EN 196-8",
    category: "Cimentos e Adições",
  },
  {
    title: "Pastas de cimento. Determinação da retracção autogénea.",
    norm: "E 476",
    category: "Cimentos e Adições",
  },
  {
    title: "Cinzas. Determinação da finura.",
    norm: "NP EN 451-2",
    category: "Cimentos e Adições",
  },
  {
    title: "Cinzas. Determinação da massa volúmica.",
    norm: "E 64",
    category: "Cimentos e Adições",
  },
  {
    title: "Cinzas. Determinação do índice de actividade.",
    norm: "NP EN 450-1 (NP EN 196-1)",
    category: "Cimentos e Adições",
  },
  {
    title: "Cinzas. Determinação do tempo de presa e expansibilidade.",
    norm: "NP EN 450-1 (NP EN 196-1)",
    category: "Cimentos e Adições",
  },
  {
    title: "Cinzas. Determinação do teor de óxido de cálcio livre.",
    norm: "NP EN 451-1",
    category: "Cimentos e Adições",
  },
  {
    title:
      "Fíler calcário para betões. Determinação do valor do azul de metileno.",
    norm: "E 385",
    category: "Cimentos e Adições",
  },
  {
    title:
      "Fíler calcário para betões. Determinação do teor de carbono orgânico total (TOC).",
    norm: "E 386",
    category: "Cimentos e Adições",
  },
  {
    title: "Cinzas. Determinação da perda ao fogo.",
    norm: "NP EN 196-2:Secção 4.4.1",
    category: "Cimentos e Adições",
  },
  {
    title: "Cinzas. Determinação do sulfato.",
    norm: "NP EN 196-2:Secção 4.4.2",
    category: "Cimentos e Adições",
  },
  {
    title: "Cinzas. Determinação dos cloretos.",
    norm: "NP EN 196-2:Secção 4.5.16",
    category: "Cimentos e Adições",
  },
  {
    title: "Análise granulométrica",
    norm: "EN 13383-2: Secção 5",
    category: "Enrocamento",
  },
  {
    title: "Determinação da forma das partículas",
    norm: "EN 13383-2: Secção 7",
    category: "Enrocamento",
  },
  {
    title: "Determinação da densidade das partículas e da absorção de água",
    norm: "EN 13383-2: Secção 8",
    category: "Enrocamento",
  },
  {
    title: "Determinação da massa volúmica e porosidade",
    norm: "Procedimento LNEC",
    category: "Enrocamento",
  },
  {
    title: "Determinação da distribuição de massa (Método de referência)",
    norm: "EN 13383-2: Secção 6",
    category: "Enrocamento",
  },
  {
    title: "Determinação da resistência à compressão uniaxial",
    norm: "Procedimento LNEC",
    category: "Enrocamento",
  },
  {
    title: "Slake Durability Test",
    norm: "Procedimento LNEC",
    category: "Enrocamento",
  },
  {
    title: "Determinação da expansibilidade",
    norm: "Procedimento LNEC",
    category: "Enrocamento",
  },
  {
    title: "Determinação da resistência ao esmagamento",
    norm: "Procedimento LNEC",
    category: "Enrocamento",
  },
  {
    title: "Determinação do Índice de vazios (Macro-Ensaio)",
    norm: "Procedimento Interno",
    category: "Enrocamento",
  },
  {
    title: "Determinação da afinidade agregado-betume",
    norm: "EN 12697-11",
    category: "Misturas Betuminosas",
  },
  {
    title: "Determinação da baridade dos provetes",
    norm: "EN 12697-6",
    category: "Misturas Betuminosas",
  },
  {
    title: "Determinação da baridade máxima teórica",
    norm: "EN 12697-5",
    category: "Misturas Betuminosas",
  },
  {
    title: "Análise granulométrica",
    norm: "EN 12697-2",
    category: "Misturas Betuminosas",
  },
  {
    title: "Ensaio Marshall",
    norm: "EN 12697-34",
    category: "Misturas Betuminosas",
  },
  {
    title: "Determinação da espessura das camadas",
    norm: "EN 12697-36",
    category: "Misturas Betuminosas",
  },
  {
    title: "Cálculo da porosidade dos provetes",
    norm: "EN 12697-8",
    category: "Misturas Betuminosas",
  },
  {
    title: "Determinação da resistência conservada",
    norm: "MIL-STD-620A: Method 104",
    category: "Misturas Betuminosas",
  },
  {
    title: "Determinação da densidade aparente",
    norm: "E 267",
    category: "Misturas Betuminosas",
  },
  {
    title:
      "Determinação do teor em betume e do teor em água com o extractor de Kumagawa",
    norm: "E 268",
    category: "Misturas Betuminosas",
  },
  {
    title:
      "Determinação do teor em betume e determinação do inerte total de uma mistura com o extractor de refluxo",
    norm: "EN 12697-1",
    category: "Misturas Betuminosas",
  },
  {
    title: "Determinação da densidade com o picnómetro",
    norm: "E 35",
    category: "Misturas Betuminosas",
  },
  {
    title: "Optimização do teor em betume (Método de Marshall)",
    norm: "J.A.E. P. 14",
    category: "Misturas Betuminosas",
  },
  {
    title: "Ensaio de adesividade",
    norm: "J.A.E. P. 9",
    category: "Misturas Betuminosas",
  },
  {
    title:
      "Determinação da baridade máxima teórica pelo método do picnómetro de vácuo",
    norm: "ASTM D 2041",
    category: "Misturas Betuminosas",
  },
  {
    title:
      "Medição da profundidade da macrotextura da superfície do pavimento através da técnica volumétrica da mancha.",
    norm: "NP EN 13036-1",
    category: "Misturas Betuminosas",
  },
  {
    title: "Determinação da baridade de misturas compactadas",
    norm: "ASTM D 2726",
    category: "Misturas Betuminosas",
  },
  {
    title:
      "Efeito da água sobre a coesão de misturas betuminosas abertas mediante o ensaio Cântabro de perda por desgaste",
    norm: "NLT 362",
    category: "Misturas Betuminosas",
  },
  {
    title:
      "Permeabilidade (misturas betuminosas porosas) “in situ” medida com LCS",
    norm: "NLT 327",
    category: "Misturas Betuminosas",
  },
  {
    title:
      "Medição da irregularidade das camadas do pavimento: ensaio com régua",
    norm: "EN 13036-7",
    category: "Misturas Betuminosas",
  },
  {
    title: "Determinação da baridade",
    norm: "J.A.E. P. 13",
    category: "Misturas Betuminosas",
  },
  {
    title: "Determinação da resistividade de solos pelo método de Wenner",
    norm: "ASTM G57",
    category: "Solos",
  },
  {
    title: "Análise granulométrica",
    norm: "E 196",
    category: "Solos",
  },
  {
    title: "Análise granulométrica por peneiração húmida",
    norm: "E 239",
    category: "Solos",
  },
  {
    title: "Ensaio de compactação",
    norm: "E 197",
    category: "Solos",
  },
  {
    title: "Determinação do CBR",
    norm: "E 198",
    category: "Solos",
  },
  {
    title: "Correcção do CBR",
    norm: "-",
    category: "Solos",
  },
  {
    title: "Determinação da densidade das partículas",
    norm: "NP 83",
    category: "Solos",
  },
  {
    title: "Determinação do teor em água",
    norm: "NP 84",
    category: "Solos",
  },
  {
    title: "Determinação do teor em água",
    norm: "EN ISO 17892-1",
    category: "Solos",
  },
  {
    title: "Ensaio de equivalente de areia",
    norm: "E 199",
    category: "Solos",
  },
  {
    title: "Ensaio de expansibilidade",
    norm: "E 200",
    category: "Solos",
  },
  {
    title:
      'Determinação da baridade seca "in situ" pelo método da garrafa de areia',
    norm: "E 204",
    category: "Solos",
  },
  {
    title: "Ensaio de permeabilidade - carga constante",
    norm: "PIE.005.1",
    category: "Solos",
  },
  {
    title: "Ensaio da permeabilidade - carga variável",
    norm: "PIE.004.1",
    category: "Solos",
  },
  {
    title: "Ensaio de carga em placa",
    norm: "NF 9-94-117-1 / ASTM D 1194 / ENV 1997-3 / DIN 18134",
    category: "Solos",
  },
  {
    title: "Teor em matéria orgânica",
    norm: "E 201 / J.A.E. S.9 / NLT 117",
    category: "Solos",
  },
  {
    title: "Ensaio CBR imediato (CBR sem embebição e sem sobrecarga)",
    norm: "NF P94-078",
    category: "Solos",
  },
  {
    title: "Ensaio PDL (Penetrómetro Dinâmico Ligeiro)",
    norm: "PIE.001.1",
    category: "Solos",
  },
  {
    title: "Controlo de compactação com gamadensímetro",
    norm: "ASTM D 6938",
    category: "Solos",
  },
  {
    title: "Ensaio de corte direto",
    norm: "BS 1377:Part 1",
    category: "Solos",
  },
  {
    title: "Ensaio edométrico",
    norm: "PIE.033.1",
    category: "Solos",
  },
  {
    title: "Ensaios triaxiais",
    norm: "PIE.034.1",
    category: "Solos",
  },
  {
    title: "Compactação com martelo vibrador",
    norm: "BS 1924-2: Section 2.1.5",
    category: "Solos",
  },
  {
    title: "Determinação dos limites de consistência",
    norm: "NP 143",
    category: "Solos",
  },
  {
    title: "Classificação unificada",
    norm: "Reg. Pequenas Barragens de Terra – Decreto 48373 de 08/05/1968",
    category: "Solos",
  },
  {
    title: "Classificação para fins rodoviários",
    norm: "E 240",
    category: "Solos",
  },
  {
    title: "Determinação do valor de azul de metileno",
    norm: "AFNOR P 18-592",
    category: "Solos",
  },
  {
    title:
      "Determinação da matéria orgânica. Método de redução da água oxigenada.",
    norm: "PIE.003.1",
    category: "Solos",
  },
  {
    title: "Determinação do teor em cloretos. Método potenciométrico.",
    norm: "PIE.010.1",
    category: "Solos",
  },
  {
    title: "Determinação do pH",
    norm: "E 203",
    category: "Solos",
  },
  {
    title: "Determinação da quantidade de sulfatos de um solo",
    norm: "E 202",
    category: "Solos",
  },
  {
    title: "Determinação da resistividade com sonda de Wenner",
    norm: "PIE.011.1",
    category: "Solos",
  },
  {
    title: "Determinação da resistividade com sonda de condutividade",
    norm: "PIE.012.1",
    category: "Solos",
  },
  {
    title: "Coeficiente de fragmentabilidade dos materiais rochosos",
    norm: "NF P 94-066",
    category: "Solos",
  },
  {
    title: "Coeficiente de degradabilidade dos materiais rochosos",
    norm: "NF P 94-067",
    category: "Solos",
  },
  {
    title: "Determinação da baridade seca",
    norm: "PIE.008.1",
    category: "Solos",
  },
  {
    title: "Análise granulométrica. Macro-ensaio.",
    norm: "PIE.027.1",
    category: "Solos",
  },
  {
    title:
      "Determinação da massa volúmica aparente e da porosidade aberta em pedras naturais",
    norm: "NP EN 1936",
    category: "Pedras Naturais",
  },
  {
    title: "Point Load Test",
    norm: "ASTM D 5731",
    category: "Pedras Naturais",
  },
  {
    title: "Determinação da resistência à compressão uniaxial",
    norm: "NP EN 1926",
    category: "Pedras Naturais",
  },
  {
    title: "Slake Test",
    norm: "ASTM D 4644",
    category: "Pedras Naturais",
  },
  {
    title: "Ensaio de carga pontual (EN 1926 – Anexo B)",
    norm: "PIE.009.1",
    category: "Pedras Naturais",
  },
  {
    title: "Determinação da tensão de rotura por compressão",
    norm: "NP 1040",
    category: "Pedras Naturais",
  },
  {
    title: "Determinação do coeficiente de dilatação térmica linear",
    norm: "NP EN 14581: Método A",
    category: "Pedras Naturais",
  },
];

export default ensaiosData;
