import { useRef, useLayoutEffect, useEffect, useContext } from "react";
import Title from "../components/global/Title";
import ArrowSubtitle from "../components/global/ArrowSubtitle";
import classNames from "classnames";
import { gsap } from "gsap";
import AOS from "aos";
import "aos/dist/aos.css"; // Import the CSS for AOS animations
import { Helmet } from "react-helmet";
import useBreakpoint from "../helpers/useBreakpoint";
import { LanguageContext } from "../App";

export default function PoliticasDePrivacidade() {
  const { currentLanguage } = useContext(LanguageContext);

  const currentBreakpoint = useBreakpoint();

  const titleRef = useRef(null);
  const restRef = useRef(null);

  // Initial Animation
  useLayoutEffect(() => {
    if (
      currentBreakpoint === "sm" ||
      currentBreakpoint === "md" ||
      currentBreakpoint === "lg" ||
      currentBreakpoint === "xl" ||
      currentBreakpoint === "2xl"
    ) {
      // Set initial opacity to 0 before the animation starts
      gsap.set(titleRef.current, { opacity: 0, y: 50 });

      // GSAP animation timeline
      const animationTimeline = gsap.timeline();

      animationTimeline.to(titleRef.current, {
        opacity: 1,
        y: 0,
        duration: 0.5,
        ease: "power2.out",
        delay: 0.5,
      });

      return () => {
        animationTimeline.kill();
      };
    }
  }, [currentBreakpoint]);

  useEffect(() => {
    AOS.init({
      delay: 1000,
      duration: 500, // Animation duration
      disable: "mobile",
    });
  }, []); // Empty dependency array ensures this effect runs once after the initial render

  const politicas = [
    {
      title:
        currentLanguage === "PT"
          ? "Políticas de Privacidade"
          : "Privacy Policies",
      paragraphs: [
        {
          content:
            currentLanguage === "PT"
              ? "Prezamos a sua privacidade e, como tal, poderá, nesta Política de Privacidade, compreender quais as informações pessoais que recolhemos, tratamos e por que o fazemos, bem como como pode exercer os seus direitos ao abrigo do Regulamento (EU) 2016/679 do Parlamento Europeu e do Conselho."
              : "We value your privacy, and as such, in this Privacy Policy, you can understand what personal information we collect, process, and why we do it, as well as how you can exercise your rights under Regulation (EU) 2016/679 of the European Parliament and of the Council.",
          indented: false,
        },
        {
          content:
            currentLanguage === "PT"
              ? "O Responsável pelo Tratamento de Dados é a SENQUAL SOCIEDADE DE ENGENHARIA E QUALIDADE LDA, doravante designada por SENQUAL, com NIPC 503709751 e sede em Rua Dr. Joaquim Morais Júnior, nº 43, 4410-066 Serzedo - Vila Nova de Gaia. Pode entrar em contacto com o responsável pelo tratamento de dados a qualquer momento para esclarecer dúvidas sobre a proteção de dados através do email geral@senqual.pt ou por correio para a seguinte morada: Rua Dr. Joaquim Morais Júnior, nº 43, 4410-066 Serzedo - Vila Nova de Gaia."
              : "The Data Controller is SENQUAL SOCIEDADE DE ENGENHARIA E QUALIDADE LDA, hereinafter referred to as SENQUAL, with VAT number 503709751 and headquartered at Rua Dr. Joaquim Morais Júnior, nº 43, 4410-066 Serzedo - Vila Nova de Gaia.. You can contact the data controller at any time with any questions about data protection via email at geral@senqual.pt or by mail to the following address: Rua Dr. Joaquim Morais Júnior, nº 43, 4410-066 Serzedo - Vila Nova de Gaia.",
          indented: false,
        },
        {
          content:
            currentLanguage === "PT"
              ? "A SENQUAL reserva-se o direito de unilateralmente, a qualquer momento, alterar, modificar, aditar ou retificar a presente Política de Privacidade, sem necessidade de aviso prévio."
              : "SENQUAL reserves the right to unilaterally, at any time, change, modify, add, or rectify this Privacy Policy without prior notice.",
          indented: false,
        },
        {
          content:
            currentLanguage === "PT"
              ? "A Lei da Proteção de Dados Pessoais (doravante “LPDP”) e o Regulamento Geral de Proteção de Dados (Regulamento (UE) 2016/679 do Parlamento Europeu e do Conselho de 27 de abril de 2016, doravante “RGPD”) garantem a proteção das pessoas singulares no que diz respeito ao tratamento de dados pessoais e à livre circulação desses dados."
              : "The Personal Data Protection Law (hereinafter “LPDP”) and the General Data Protection Regulation (Regulation (EU) 2016/679 of the European Parliament and of the Council of April 27, 2016, hereinafter “GDPR”) ensure the protection of individuals regarding the processing of personal data and the free movement of such data.",
          indented: false,
        },
      ],
    },
    {
      title:
        currentLanguage === "PT"
          ? "Recolha de Dados Pessoais"
          : "Collection of Personal Data",
      paragraphs: [
        {
          content:
            currentLanguage === "PT"
              ? "Ao aceitar esta Política de Privacidade, o utilizador dá o seu consentimento informado, expresso, livre e inequívoco para que os dados pessoais fornecidos através do site senqual.pt sejam incluídos num ficheiro da responsabilidade da SENQUAL."
              : "By accepting this Privacy Policy, the user gives their informed, express, free, and unequivocal consent for the personal data provided through the senqual.pt website to be included in a file under the responsibility of SENQUAL.",
          indented: false,
        },
        {
          content:
            currentLanguage === "PT"
              ? "A SENQUAL não recolhe nem processa qualquer dado pessoal ou informação obtida através do seu sítio institucional para permitir a sua exploração, de qualquer forma. Em caso algum serão cedidos a outras pessoas ou entidades dados pessoais sem o consentimento prévio do titular dos dados."
              : "SENQUAL does not collect or process any personal data or information obtained through its institutional website to enable its exploitation in any way. Under no circumstances will personal data be transferred to other individuals or entities without the prior consent of the data subject.",
          indented: false,
        },
      ],
    },
    {
      title:
        currentLanguage === "PT"
          ? "Finalidades do Tratamento de Dados Pessoais"
          : "Purposes of Personal Data Processing",
      paragraphs: [
        {
          content:
            currentLanguage === "PT"
              ? "Os dados pessoais que tratamos através desta página serão unicamente utilizados para as seguintes finalidades:"
              : "The personal data we process through this page will be used exclusively for the following purposes:",
          indented: false,
        },
        {
          content:
            currentLanguage === "PT"
              ? "(i) Comunicação com os utilizadores e esclarecimento de dúvidas;"
              : "(i) Communication with users and clarification of doubts;",
          indented: true,
        },
        {
          content:
            currentLanguage === "PT"
              ? "(ii) Processamento de pedidos de informação."
              : "(ii) Processing requests for information.",
          indented: true,
        },
      ],
    },
    {
      title:
        currentLanguage === "PT"
          ? "Partilha de Dados Pessoais"
          : "Sharing of Personal Data",
      paragraphs: [
        {
          content:
            currentLanguage === "PT"
              ? "A SENQUAL não cede os dados pessoais a terceiros, salvo sob alguma obrigatoriedade legal prevista da Lei Portuguesa."
              : "SENQUAL does not transfer personal data to third parties, except under some legal obligation stipulated by Portuguese law.",
          indented: false,
        },
      ],
    },
    {
      title:
        currentLanguage === "PT" ? "Medidas de Segurança" : "Security Measures",
      paragraphs: [
        {
          content:
            currentLanguage === "PT"
              ? "A SENQUAL garante a confidencialidade de todos os dados fornecidos pelos seus utilizadores nos formulários de contacto."
              : "SENQUAL ensures the confidentiality of all data provided by its users in the contact forms.",
          indented: false,
        },
        {
          content:
            currentLanguage === "PT"
              ? "Os dados dos utilizadores são guardados num servidor localizado na União Europeia. Este servidor é protegido e mantido de acordo com os mais elevados standards de segurança e por forma a respeitar as leis de privacidade aplicáveis."
              : "User data is stored on a server located in the European Union. This server is protected and maintained according to the highest security standards and in compliance with applicable privacy laws.",
          indented: false,
        },
        {
          content:
            currentLanguage === "PT"
              ? "A recolha e tratamento de dados realiza-se de forma segura e que impede a sua perda ou manipulação. Todos os dados serão inseridos num Servidor Seguro (SSL) que os encripta/codifica (transforma num código). O utilizador poderá verificar que o seu browser é seguro se o símbolo do cadeado aparecer ou se o endereço começar com https em vez de http."
              : "Data collection and processing are carried out securely to prevent loss or manipulation. All data will be entered into a Secure Server (SSL) that encrypts/encodes them (transforms them into a code). Users can confirm that their browser is secure if the padlock symbol appears or if the address starts with https instead of http.",
          indented: false,
        },
        {
          content:
            currentLanguage === "PT"
              ? "Os dados pessoais são tratados com o nível de proteção legalmente exigível para garantir a segurança dos mesmos e evitar a sua alteração, perda, tratamento, destruição, acesso ou utilização não autorizada, tendo em conta o estado da tecnologia, sendo o utilizador consciente e aceitando que as medidas de segurança em Internet não são inexpugnáveis."
              : "Personal data is processed with the level of protection required by law to ensure their security and prevent their alteration, loss, processing, destruction, unauthorized access, or use, considering the state of technology. Users are aware and accept that internet security measures are not invulnerable.",
          indented: false,
        },
        {
          content:
            currentLanguage === "PT"
              ? "A SENQUAL, sempre que aceda a qualquer dado pessoal, compromete-se a:"
              : "SENQUAL, whenever accessing any personal data, commits to:",
          indented: false,
        },
        {
          content:
            currentLanguage === "PT"
              ? "(i) Utilizar os dados exclusivamente para as finalidades previamente definidas;"
              : "(i) Use the data exclusively for the purposes previously defined;",
          indented: true,
        },
        {
          content:
            currentLanguage === "PT"
              ? "(ii) Certificar-se de que os dados são tratados unicamente pelos trabalhadores cuja intervenção seja necessária para a prestação do serviço estando os mesmos obrigados ao dever de sigilo e confidencialidade."
              : "(ii) Ensure that data is processed only by employees whose intervention is necessary for the service provision, and they are obliged to confidentiality and secrecy.",
          indented: true,
        },
      ],
    },

    {
      title:
        currentLanguage === "PT"
          ? "Conservação dos Dados Pessoais"
          : "Retention of Personal Data",
      paragraphs: [
        {
          content:
            currentLanguage === "PT"
              ? "Os seus dados pessoais serão conservados pelo período de tempo mínimo necessário para a prossecução das suas finalidades ou, até à solicitação do próprio utilizador, através dos meios informados nesta política de privacidade, a pedir a exclusão dos seus dados e retirar o consentimento para o presente tratamento e/ou exercer o direito de portabilidade."
              : "Your personal data will be retained for the minimum period necessary to achieve its purposes or until the user requests deletion of their data and withdraws consent for the processing, and/or exercises the right to data portability through the means provided in this privacy policy.",
          indented: false,
        },
      ],
    },

    {
      title:
        currentLanguage === "PT"
          ? "Exercício de Direitos"
          : "Exercise of Rights",
      paragraphs: [
        {
          content:
            currentLanguage === "PT"
              ? "De acordo com as disposições da LDPD e do RGPD, o utilizador pode exercer a todo o tempo os seus direitos de acesso, retificação, apagamento, limitação, oposição e de portabilidade, através de solicitação por qualquer dos seguintes meios:"
              : "According to the provisions of the LPDP and the GDPR, the user can exercise their rights of access, rectification, erasure, restriction, opposition, and portability at any time, by requesting through any of the following means:",
          indented: false,
        },
        {
          content:
            currentLanguage === "PT"
              ? "Correio: Rua Dr. Joaquim Morais Júnior, nº 43, 4410-066 Serzedo - Vila Nova de Gaia"
              : "Mail: Rua Dr. Joaquim Morais Júnior, nº 43, 4410-066 Serzedo - Vila Nova de Gaia",
          indented: true,
        },
        {
          content:
            currentLanguage === "PT"
              ? "E-mail: geral@senqual.pt"
              : "Email: geral@senqual.pt",
          indented: true,
        },
        {
          content:
            currentLanguage === "PT"
              ? "Caso o utilizador deseje, a qualquer momento, deixar de fazer parte da base de dados SENQUAL poderá exercer esse direito através destes contactos. O Utilizador pode ainda apresentar reclamação à Comissão Nacional de Proteção de Dados, a autoridade pública que fiscaliza a aplicação da legislação em Portugal."
              : "If the user wishes, at any time, to no longer be part of SENQUAL's database, they can exercise this right through these contacts. The user can also file a complaint with the National Data Protection Commission, the public authority responsible for supervising the application of legislation in Portugal.",
          indented: false,
        },
      ],
    },

    {
      title:
        currentLanguage === "PT" ? "Utilização de Cookies" : "Use of Cookies",
      paragraphs: [
        {
          content:
            currentLanguage === "PT"
              ? "Os cookies são utilizados para determinar a utilidade, interesse e o número de utilizações dos seus websites."
              : "Cookies are used to determine the usefulness, interest, and number of uses of your websites.",
          indented: false,
        },
        {
          content:
            currentLanguage === "PT"
              ? "Existem dois grupos cookies que podem ser utilizados."
              : "There are two cookie groups that can be used.",
          indented: false,
        },
        {
          content:
            currentLanguage === "PT"
              ? "Cookies permanentes - são cookies que ficam armazenados ao nível do browser nos seus equipamentos de acesso (pc, mobile e tablet) e que são utilizados sempre que faz uma nova visita a um dos nossos websites. São utilizados, geralmente, para direcionar a navegação aos interesses do utilizador, permitindo-nos prestar um serviço mais personalizado."
              : "Permanent cookies - these cookies are stored in your web browser on your devices (PC, mobile, and tablet) and are used whenever you make a new visit to one of our websites. They are generally used to direct navigation to the user's interests, allowing us to provide a more personalized service.",
          indented: false,
        },
        {
          content:
            currentLanguage === "PT"
              ? "Cookies de sessão - são cookies temporários que permanecem no arquivo de cookies do seu browser até sair do website. A informação obtida por estes cookies serve para analisar padrões de tráfego na web, permitindo-nos identificar problemas e fornecer uma melhor experiência de navegação."
              : "Session cookies - these are temporary cookies that remain in your browser's cookie file until you leave the website. The information obtained by these cookies is used to analyze web traffic patterns, allowing us to identify issues and provide a better browsing experience.",
          indented: false,
        },
        {
          content:
            currentLanguage === "PT"
              ? "Todos os browsers permitem ao utilizador aceitar, recusar ou apagar cookies, nomeadamente através da seleção das definições apropriadas no respetivo navegador. Pode configurar os cookies no menu “opções” ou “preferências” do seu browser."
              : "All browsers allow users to accept, refuse, or delete cookies, particularly by selecting the appropriate settings in their browser. You can configure cookies in the 'options' or 'preferences' menu of your browser.",
          indented: false,
        },
        {
          content:
            currentLanguage === "PT"
              ? "Note-se, no entanto, que, ao desativar cookies, pode impedir que alguns serviços da web funcionem corretamente, afetando, parcial ou totalmente, a navegação no website."
              : "However, please note that disabling cookies may prevent some web services from functioning correctly, partially or entirely affecting website navigation.",
          indented: false,
        },
      ],
    },
  ];

  const politicasItems = politicas.map((section, index) => {
    const paragraphs = section.paragraphs.map((paragraph, index) => {
      return (
        <p
          className={classNames(
            index === section.paragraphs.length - 1 ? "mb-20" : "mb-4",
            paragraph.indented && "ml-16",
            "animate-on-scroll float-left text-dark-2",
          )}
        >
          {paragraph.content}
        </p>
      );
    });

    return (
      <div data-aos="fade-up">
        {[
          index > 0 && (
            <ArrowSubtitle text={section.title} classes="mb-12 float-left" />
          ),
          paragraphs,
        ]}
      </div>
    );
  });

  return (
    <>
      <Helmet>
        <title>
          {currentLanguage === "PT"
            ? "Políticas de Privacidade"
            : "Privacy Policy"}{" "}
          - Senqual
        </title>
        <meta
          name="description"
          content={
            currentLanguage === "PT"
              ? "Conhea a política de privacidade da Senqual."
              : "Discover Senqual's Privacy Policy."
          }
        />
      </Helmet>
      <div className="mt-14 flex flex-col bg-light-2 lg:mb-24">
        <div className="container mx-auto px-8">
          <div className="h-6 w-1/2 md:mb-8 md:h-24 lg:mb-0 lg:mt-12 lg:w-1/5"></div>
          <div ref={titleRef}>
            <Title
              text={
                <span>
                  {currentLanguage === "PT"
                    ? "Políticas de Privacidade"
                    : "Privacy Policy"}
                </span>
              }
              classes="md:-mt-16 lg:-mt-8 xl:-mt-16"
            />
          </div>
        </div>
        <div className="container mx-auto mt-12 px-8 lg:mt-20" ref={restRef}>
          <div className="flex w-full flex-col items-start lg:w-2/3">
            {politicasItems}
          </div>
        </div>
      </div>
    </>
  );
}
