import { useEffect, useState } from "react";

const useBreakpoint = () => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState("");

  const handleResize = () => {
    // Logic to determine the current breakpoint based on window.innerWidth
    const screenWidth = window.innerWidth;
    if (screenWidth < 640) {
      setCurrentBreakpoint("xs");
    } else if (screenWidth >= 640 && screenWidth < 768) {
      setCurrentBreakpoint("sm");
    } else if (screenWidth >= 768 && screenWidth < 1024) {
      setCurrentBreakpoint("md");
    } else if (screenWidth >= 1024 && screenWidth < 1280) {
      setCurrentBreakpoint("lg");
    } else if (screenWidth >= 1280 && screenWidth < 1536) {
      setCurrentBreakpoint("xl");
    } else {
      setCurrentBreakpoint("2xl");
    }
  };

  useEffect(() => {
    // Initial setup
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures that effect runs once after initial render

  return currentBreakpoint;
};

export default useBreakpoint;
