import {
  useState,
  useLayoutEffect,
  useRef,
  useEffect,
  useContext,
} from "react";
import BingMapsReact from "bingmaps-react";
import { gsap } from "gsap";
import { LanguageContext } from "../App";

import Title from "../components/global/Title";
import Address from "../components/specific/contacte-nos/Address";
import ContactForm from "../components/specific/contacte-nos/ContactForm";
import RecruitForm from "../components/specific/contacte-nos/RecruitForm";
import AOS from "aos";
import "aos/dist/aos.css"; // Import the CSS for AOS animations
import { Helmet } from "react-helmet";
import useBreakpoint from "../helpers/useBreakpoint";

export default function ContacteNos() {
  const { currentLanguage } = useContext(LanguageContext);
  //const [bingMapReady, setBingMapReady] = useState(false);
  const currentBreakpoint = useBreakpoint();

  const titleRef = useRef(null);
  const imageRef = useRef(null);
  const imageContainerRef = useRef(null);
  const mapRef = useRef(null);
  const mapContainerRef = useRef(null);
  const textRef = useRef(null);

  // Initial Animation
  useLayoutEffect(() => {
    if (
      currentBreakpoint === "sm" ||
      currentBreakpoint === "md" ||
      currentBreakpoint === "lg" ||
      currentBreakpoint === "xl" ||
      currentBreakpoint === "2xl"
    ) {
      // Set initial opacity to 0 before the animation starts
      gsap.set(imageContainerRef.current, {
        opacity: 0,
        xPercent: -25,
        zIndex: 10,
        position: "relative",
      });
      gsap.set(mapContainerRef.current, { opacity: 0, xPercent: 25 });
      gsap.set(mapRef.current, { opacity: 0 });
      gsap.set(imageRef.current, { opacity: 0 });
      gsap.set(titleRef.current, {
        opacity: 0,
        y: 50,
        zIndex: 20,
        position: "relative",
      });
      gsap.set(textRef.current, { opacity: 0, y: 50 });

      // GSAP animation timeline
      const animationTimeline = gsap.timeline();

      animationTimeline.to(titleRef.current, {
        opacity: 1,
        y: 0,
        duration: 0.5,
        ease: "power2.out",
        delay: 0.5,
      });

      animationTimeline.addLabel("containerAnimations");

      animationTimeline.to(imageContainerRef.current, {
        xPercent: 0,
        opacity: 1,
        duration: 0.75,
        ease: "power2.out",
      });

      animationTimeline.to(
        mapContainerRef.current,
        {
          xPercent: 0,
          opacity: 1,
          duration: 0.75,
          ease: "power2.out",
        },
        "containerAnimations",
      );

      animationTimeline.addLabel("textAnimations");

      animationTimeline.to(imageRef.current, {
        opacity: 1,
        duration: 0.5,
        ease: "power2.out",
      });
      animationTimeline.to(
        mapRef.current,
        {
          opacity: 1,
          duration: 0.5,
          ease: "power2.out",
        },
        "textAnimations",
      );

      animationTimeline.to(
        textRef.current,
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          ease: "power2.out",
        },
        "textAnimations",
      );

      return () => {
        animationTimeline.kill();
      };
    }
  }, [currentBreakpoint]);

  useEffect(() => {
    AOS.init({
      duration: 500,
      disable: "phone",
    });
  }, []);

  /*const pushPin = {
    center: {
      latitude: 41.0601502409649,
      longitude: -8.61662255508293,
    },
    options: {
      title: "Senqual",
      color: "#82C341",
    },
  };*/

  //const pushPins = bingMapReady ? [pushPin] : null;

  return (
    <>
      <Helmet>
        <title>
          {currentLanguage === "PT" ? "Contacte-nos" : "Contact Us"} - Senqual
        </title>
        <meta
          name="description"
          content={
            currentLanguage === "PT"
              ? "Descubra como contactar a Senqual."
              : "Get in touch with Senqual."
          }
        />
      </Helmet>
      <div className="relative mt-14 flex max-w-[100vw] flex-col overflow-x-hidden bg-light-2 pb-32">
        <div className="z-10 w-full">
          <div className="container mx-auto px-8">
            <div className="h-6 w-1/2 md:mb-8 md:h-24 lg:mb-0 lg:mt-12 lg:w-1/5"></div>
            <div ref={titleRef}>
              <Title
                text={
                  <span>
                    {currentLanguage === "PT" ? "Contacte-nos" : "Contact Us"}
                  </span>
                }
                classes="md:-mt-16 lg:-mt-8 xl:-mt-16"
              />
            </div>
          </div>
          <div
            className="mask-left relative z-10 -mt-5 overflow-hidden bg-green-4 lg:h-96 lg:w-3/5 xl:h-[500px] 2xl:h-[650px]"
            ref={imageContainerRef}
          >
            <img
              src={`${process.env.PUBLIC_URL}/img/contacte-nos/contacte-nos-1.jpg`}
              srcSet={`${process.env.PUBLIC_URL}/img/contacte-nos/contacte-nos-1-2x.jpg 2x`}
              alt={`${
                currentLanguage === "PT" ? "Contacte-nos" : "Contact Us"
              }  - Senqual`}
              className="h-full w-full object-cover "
              ref={imageRef}
            />
          </div>

          <div className="relative z-0 -mt-20 ml-auto w-11/12 lg:w-3/5 xl:-mt-48 2xl:-mt-72">
            <div
              className="mask-right-2 md:h-[450px]  h-80  bg-white sm:h-96 lg:h-[532px] lg:pb-0 xl:h-[40rem] 2xl:h-[48rem]"
              ref={mapContainerRef}
            >
              <div ref={mapRef} className="h-full w-full">
                {/*<BingMapsReact
                  className="absolute md:relative"
                  height="100%"
                  pushPins={pushPins}
                  mapOptions={{
                    showDashboard: false, // Hide zoom buttons and other controls
                    showMapTypeSelector: false, // Hide map type selector
                    disableScrollWheelZoom: true,
                  }}
                  viewOptions={{
                    center: {
                      latitude: pushPin.center.latitude,
                      longitude: pushPin.center.longitude - 0.001,
                    },
                    zoom: 17,
                    mapTypeId: "grayscale", // Set the map theme to greyscale
                  }}
                  onMapReady={function () {
                    setBingMapReady(true);
                  }}
                  width="100%"
                  bingMapsKey="AlSDUfoGmmiSTt7TV5WnybtQ3yoGmZloTm32Bfm6A0APzAsEY_Z10iQ8tu2oEZs9"
                />*/}

                <a
                  href="https://maps.app.goo.gl/jN7dW5ZzkvxL3UBt5"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/img/contacte-nos/mapa.jpg`}
                    alt={`${
                      currentLanguage === "PT" ? "Contacte-nos" : "Contact Us"
                    }  - Senqual`}
                    className="hidden h-full w-full object-cover md:block"
                  />
                  <img
                    src={`${process.env.PUBLIC_URL}/img/contacte-nos/mapa-2x.jpg`}
                    alt={`${
                      currentLanguage === "PT" ? "Contacte-nos" : "Contact Us"
                    }  - Senqual`}
                    className="h-full w-full object-cover md:hidden"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="container mx-auto px-8">
            <div className="float-left mx-auto my-16 flex flex-col justify-end gap-y-12 lg:-mt-[25rem] lg:mb-16 lg:w-1/3 xl:-mt-[19rem] xl:mb-32 2xl:-mt-[20rem] 2xl:mb-24">
              <div data-aos="fade-up" className="order-2 lg:order-1">
                <Address
                  title={currentLanguage === "PT" ? "Morada" : "Address"}
                  addresses={[
                    "Rua Dr. Joaquim Morais Júnior, nº 43",
                    "4410-066 Serzedo - Vila Nova de Gaia",
                  ]}
                />
                <div className="mb-12"></div>
                <h3 className="mb-4 text-lg font-bold uppercase text-green-3 2xl:mb-6 2xl:text-xl">
                  {currentLanguage === "PT"
                    ? "Resolução alternativa de litígios"
                    : "Alternative Dispute Resolution"}
                </h3>
                {currentLanguage === "PT" ? (
                  <p className="2xl:text-md text-sm text-green-3">
                    Entidade de Resolução Conflitos de Consumo (RAL) da área é o
                    “CIPAC – Centro de Informação de Consumo e Arbitragem do
                    Porto”, com sede na Rua Damião de Góis, n.º 31, loja 6,
                    4050-225 Porto (
                    <a
                      href="http://www.cicap.pt"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      www.cicap.pt
                    </a>
                    ).
                  </p>
                ) : (
                  <p className="2xl:text-md text-sm text-green-3">
                    The Consumer Disputes Resolution Entity for this area is
                    “CIPAC – Centro de Informação de Consumo e Arbitragem do
                    Porto”, located at Rua Damião de Góis, n.º 31, loja 6,
                    4050-225 Porto (
                    <a
                      href="http://www.cicap.pt"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      www.cicap.pt
                    </a>
                    ).
                  </p>
                )}
              </div>
              <div className="order-1 flex flex-col gap-y-12 md:flex-row lg:order-2">
                <div className="w-full md:w-[calc(50%-1rem)] lg:hidden">
                  <Address
                    title="Email"
                    addresses={["geral@senqual.pt", "laboratorio@senqual.pt"]}
                    link={true}
                  />
                </div>
                <div className="w-full md:ml-auto md:w-[calc(50%-1rem)] lg:hidden">
                  <Address
                    title={currentLanguage === "PT" ? "Telefone" : "Phone"}
                    addresses={["227346825", "912226272"]}
                    link={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-full flex-wrap lg:container lg:mx-auto lg:px-8">
            <div
              className="flex w-full flex-col justify-between rounded-[3px] bg-white py-16 lg:rounded-l-[3px] lg:rounded-r-none xl:w-1/2"
              data-aos="fade-up"
            >
              <div className="container mx-auto px-8 lg:px-16">
                <h3 className="mb-20 text-3xl font-medium uppercase text-green-3 lg:mb-10 ">
                  {currentLanguage === "PT" ? "Mensagem" : "Message Us"}
                </h3>
                <ContactForm />
              </div>
            </div>
            <div
              className="w-full rounded-[3px] bg-light-3 py-16 lg:rounded-l-none lg:rounded-r-[3px] xl:w-1/2 "
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <div className="container mx-auto px-8 lg:px-16">
                <h3 className="mb-20 text-3xl font-medium uppercase text-green-3 lg:mb-10 2xl:mb-14">
                  {currentLanguage === "PT" ? "Recrutamento" : "Recruitment"}
                </h3>

                <RecruitForm />
              </div>
            </div>
          </div>
        </div>

        <div className="absolute z-10 mx-auto hidden w-full lg:block">
          <div
            className="container mx-auto my-36 flex justify-end gap-x-24 px-8 lg:mb-24 lg:mt-48 xl:mb-24 xl:mt-52 2xl:mb-24 2xl:mt-64"
            ref={textRef}
          >
            <div>
              <Address
                title="Email"
                addresses={["geral@senqual.pt", "laboratorio@senqual.pt"]}
                link={true}
              />
            </div>
            <div>
              <Address
                title={currentLanguage === "PT" ? "Telefone" : "Phone"}
                addresses={["227346825", "912226272"]}
                link={true}
              />
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 z-0 block">
          <img
            src={`${process.env.PUBLIC_URL}/img/contacte-nos/background.jpg`}
            srcSet={`${process.env.PUBLIC_URL}/img/contacte-nos/background-2x.jpg 2x`}
            alt={`${
              currentLanguage === "PT" ? "Contacte-nos" : "Contact Us"
            }  - Senqual`}
            className="h-full w-full object-cover "
          />
        </div>
      </div>
    </>
  );
}
