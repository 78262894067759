import classNames from "classnames";
import React from "react";

export default function FormButton({ label, color, type = "submit" }) {
  let styles;

  if (color === "light") {
    styles =
      "text-light-2 bg-green-1 hover:bg-green-2 active:bg-green-2 active:hover:bg-green-3 focus:bg-green-2 font-bold";
  } else if (color === "dark") {
    styles =
      "text-light-3 bg-green-3 hover:bg-green-4 active:bg-green-4 active:hover:bg-dark-0 focus:bg-green-4 font-bold";
  } else {
    styles =
      "text-dark-2 bg-light-3 hover:bg-light-4 active:bg-light-4 active:hover:bg-dark-3 focus:bg-light-4 border border-1 border-dark-2";
  }

  return (
    <button
      type={type === "submit" ? "submit" : "button"}
      className={classNames(
        styles,
        "w-full rounded-[3px] py-3 text-lg uppercase tracking-tight outline-none transition-colors",
      )}
    >
      {label}
    </button>
  );
}
