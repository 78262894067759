import React, { useEffect, useRef, useContext } from "react";
import * as d3 from "d3";

import AOS from "aos";
import "aos/dist/aos.css"; // Import the CSS for AOS animations
import useBreakpoint from "../../../helpers/useBreakpoint";
import ensaiosData from "../../../data/ensaios";
import { LanguageContext } from "../../../App";

export default function EnsaiosGraph({ width, height, playAnimation }) {
  const { currentLanguage } = useContext(LanguageContext);

  console.log(height);
  const currentBreakpoint = useBreakpoint();

  useEffect(() => {
    AOS.init({ anchorPlacement: "top-bottom" });
  }, []); // Run this effect only once after initial render

  const ensaiosNewData = ensaiosData.map((obj) => {
    // Check the condition and update category accordingly
    if (
      obj.category === "Agregados" ||
      obj.category === "Enrocamento" ||
      obj.category === "Pedras Naturais"
    ) {
      return {
        ...obj,
        category:
          currentLanguage === "PT"
            ? "Agregados, Enrocamento e Pedra natural"
            : "Aggregates, Rockfill and Natural Stones",
      };
    } else if (
      obj.category === "Durabilidade do betão" ||
      obj.category === "Adjuvantes para betão, argamassa e caldas" ||
      obj.category === "Betões"
    ) {
      return {
        ...obj,
        category: currentLanguage === "PT" ? "Betão" : "Concrete",
      };
    } else if (
      obj.category === "Argamassas e Caldas de Injeção" ||
      obj.category === "Cimentos e Adições"
    ) {
      return {
        ...obj,
        category:
          currentLanguage === "PT"
            ? "Cimentos, caldas e argamassas"
            : "Cements, Mortars, and Grouts",
      };
    } else if (
      obj.category === "Misturas Betuminosas" ||
      obj.category === "Misturas tratadas com ligantes hidráulicos"
    ) {
      return {
        ...obj,
        category:
          currentLanguage === "PT"
            ? "Misturas Betuminosas"
            : "Bituminous Mixtures",
      };
    } else {
      return obj;
    }
    // If the condition doesn't match, return the object as it is
    return obj;
  });

  const categoryCounts = {};
  ensaiosNewData.forEach((item) => {
    categoryCounts[item.category] = (categoryCounts[item.category] || 0) + 1;
  });

  const total = Object.values(categoryCounts).reduce(
    (acc, count) => acc + count,
    0,
  );

  const pieChartData = [
    {
      category:
        currentLanguage === "PT"
          ? "Agregados, Enrocamento e Pedra natural"
          : "Aggregates, Rock, and Natural Stone",
      percentage: 33,
    },
    {
      category: currentLanguage === "PT" ? "Betão" : "Concrete",
      percentage: 25,
    },
    {
      category:
        currentLanguage === "PT"
          ? "Cimentos, caldas e argamassas"
          : "Cements, Slurries, and Mortars",
      percentage: 18,
    },
    {
      category: currentLanguage === "PT" ? "Solos" : "Soils",
      percentage: 12,
    },
    {
      category:
        currentLanguage === "PT"
          ? "Misturas Betuminosas"
          : "Bituminous Mixtures",
      percentage: 11,
    },
    {
      category: currentLanguage === "PT" ? "Aços" : "Steels",
      percentage: 1,
    },
  ];

  /*const pieChartData = Object.entries(categoryCounts).map(
    ([category, count]) => ({
      category,
      count,
      percentage: (count / total) * 100,
    }),
  );*/

  //pieChartData.sort((a, b) => b.count - a.count);
  pieChartData.sort((a, b) => b.percentage - a.percentage);

  console.log(pieChartData);

  const colors = [
    "#82C341",
    "#004E38",
    "#44AB73",
    "#43E4AA",
    "#A4FF95",
    "#517C08",
  ];

  const legendaItems = pieChartData.map((item, index) => {
    return (
      <li className="flex items-center gap-x-6 text-dark-2">
        <div
          className="h-4 w-4"
          style={{ backgroundColor: colors[index] }}
        ></div>
        <span className="max-w-[calc(100%-3.5rem)]">{item.category}</span>
      </li>
    );
  });

  const svgRef = useRef();

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    const radius = (Math.min(width, height) - 30) / 2;
    // colors for the arcs

    const pie = d3
      .pie()
      .startAngle(0) // Start angle at -π/2 radians (270 degrees)
      .endAngle(Math.PI * 2) // End angle at π/2 radians (270 degrees)
      .sort(null)
      .value((d) => d.percentage);

    const arc = d3
      .arc()
      .innerRadius(0)
      .outerRadius(function (d, i) {
        // Decrease outer radius with each subsequent arc
        return radius - i * 8;
      })
      .cornerRadius(3);

    const arcs = pie(pieChartData);

    svg.selectAll("*").remove();

    const defs = svg.append("defs");

    defs
      .append("filter")
      .attr("id", "drop-shadow")
      .attr("height", "130%")
      .append("feGaussianBlur")
      .attr("in", "SourceAlpha")
      .attr("stdDeviation", 5)
      .attr("result", "blur");

    defs
      .append("feOffset")
      .attr("in", "blur")
      .attr("dx", 2)
      .attr("dy", 2)
      .attr("result", "offsetBlur");

    const feMerge = defs.append("feMerge");
    feMerge.append("feMergeNode").attr("in", "offsetBlur");
    feMerge.append("feMergeNode").attr("in", "SourceGraphic");

    const g = svg
      .append("g")
      .attr("transform", `translate(${width / 2},${height / 2})`);

    const g2 = svg
      .append("g")
      .attr("transform", `translate(${width / 2},${height / 2})`);

    g2.append("circle")
      .attr("r", height !== 450 ? (radius + 10) * 0.719 : radius * 0.719) // Adjust the radius of the circle as needed
      .attr("fill", "#E5EBEE");

    if (playAnimation) {
      const path = g
        .selectAll("path")
        .data(arcs)
        .enter()
        .append("path")
        .style("filter", "url(#drop-shadow)")
        .style("opacity", "0.5")
        .transition()
        .duration(1000) // Animation duration in milliseconds
        .attrTween("d", arcTween);

      const path2 = g2
        .selectAll("path")
        .data(arcs)
        .enter()
        .append("path")
        .attr("fill", (d, i) => colors[i])
        .transition()
        .duration(1000) // Animation duration in milliseconds
        .attrTween("d", arcTween);
    }

    function arcTween(d) {
      // Calculate the interpolated start and end angles based on the data and radii
      var interpolateStartAngle = d3.interpolate(0, d.startAngle);
      var interpolateEndAngle = d3.interpolate(0, d.endAngle);

      return function (t) {
        // Get the interpolated values for start and end angles
        var interpolatedStartAngle = interpolateStartAngle(t);
        var interpolatedEndAngle = interpolateEndAngle(t);

        // Update the arc with interpolated angles and radii
        var interpolatedArc = d3
          .arc()
          .innerRadius(0)
          .outerRadius(
            height !== 450 ? radius + 30 - d.index * 8 : radius - d.index * 8,
          ) // Decrease outer radius with each subsequent arc
          .cornerRadius(3)
          .startAngle(interpolatedStartAngle)
          .endAngle(interpolatedEndAngle);

        // Return the arc path with updated angles and radii
        return interpolatedArc(d.data);
      };
    }
    // Adding labels with opacity animation

    if (playAnimation) {
      g2.selectAll("text")
        .data(arcs)
        .enter()
        .append("text")
        .style("opacity", 0) // Set initial opacity to 0
        .each(function (d) {
          d.outerRadius = radius + 30;
          d.angle = (d.startAngle + d.endAngle) / 2;
        })
        .attr("dy", "0.35em")
        .attr("text-anchor", function (d) {
          return (d.endAngle + d.startAngle) / 2 > Math.PI ? "end" : "start";
        })
        .attr("transform", function (d) {
          let x;
          if ((d.endAngle + d.startAngle) / 2 > Math.PI) {
            x =
              height !== 450
                ? Math.sin(d.angle) * d.outerRadius - 50
                : Math.sin(d.angle) * d.outerRadius - 54;
          } else {
            x =
              height !== 450
                ? Math.sin(d.angle) * d.outerRadius + 50
                : Math.sin(d.angle) * d.outerRadius + 54;
          }
          const y = -Math.cos(d.angle) * (d.outerRadius - 4);
          return `translate(${x},${y})`;
        })
        .attr("fill", "#006F4F")
        .attr("font-size", height !== 450 ? "12px" : "18px")
        .attr("font-weight", "500")
        .text((d) => `${d.data.percentage.toFixed()}%`)
        .transition() // Apply opacity transition
        .delay(1000) // Delay the animation to start after the arcs are drawn
        .duration(500) // Set the duration of the opacity transition
        .style("opacity", 1); // Set final opacity to 1

      // Adding lines with opacity animation
      g.selectAll("line")
        .data(arcs)
        .enter()
        .append("line")
        .style("opacity", 0) // Set initial opacity to 0
        .attr("x1", 0)
        .attr("y1", 0)
        .attr("x2", function (d) {
          const x = Math.sin(d.angle) * (radius + 25);
          return x > 0 ? x + 10 : x - 10;
        })
        .attr("y2", function (d) {
          const y = -Math.cos(d.angle) * (radius + 25);
          return y;
        })
        .attr("stroke", "#006F4F")
        .transition() // Apply opacity transition
        .delay(1000) // Delay the animation to start after the arcs are drawn
        .duration(500) // Set the duration of the opacity transition
        .style("opacity", 1); // Set final opacity to 1

      g.selectAll("line.extension")
        .data(arcs)
        .enter()
        .append("line")
        .attr("class", "extension")
        .style("opacity", 0) // Set initial opacity to 0
        .attr("x1", function (d) {
          const x = Math.sin(d.angle) * (radius + 25);
          return x > 0 ? x + 10 : x - 10;
        })
        .attr("y1", function (d) {
          const y = -Math.cos(d.angle) * (radius + 25);
          return y;
        })
        .attr("x2", function (d) {
          const x = Math.sin(d.angle) * (radius + 45);
          return x > 0 ? x + 36 : x - 36;
        })
        .attr("y2", function (d) {
          const y = -Math.cos(d.angle) * (radius + 25);
          return y;
        })
        .attr("stroke", "#006F4F")
        .transition() // Apply opacity transition
        .delay(1000) // Delay the animation to start after the arcs are drawn
        .duration(500) // Set the duration of the opacity transition
        .style("opacity", 1); // Set final opacity to 1
    }

    g2.append("circle")
      .attr("r", radius * 0.5238) // Adjust the radius of the circle as needed
      .style("filter", "url(#drop-shadow)")
      .style("opacity", "0.2");

    g2.append("circle")
      .attr("r", radius * 0.5238) // Adjust the radius of the circle as needed
      .attr("fill", "white");

    const icon = `<svg width="${height !== 450 ? "30" : "54"}" height="${
      height !== 450 ? "30" : "54"
    }" viewBox="0 0 70 54" xmlns="http://www.w3.org/2000/svg" style="background:white">
    <path d="M30.8571 0C28.7237 0 27 1.72366 27 3.85714V4.13438V16.1397C27 16.8147 26.4576 17.3571 25.7826 17.3571C25.3487 17.3571 24.9388 17.1281 24.7219 16.7424L18.9362 6.62946C10.0045 11.029 3.85714 20.2259 3.85714 30.8571V38.5714H65.5714V30.5679C65.4629 20.0571 59.3397 10.9929 50.4924 6.62946L44.7067 16.7424C44.4897 17.1281 44.0799 17.3571 43.646 17.3571C42.971 17.3571 42.4286 16.8147 42.4286 16.1397V4.13438V3.85714C42.4286 1.72366 40.7049 0 38.5714 0H30.8571ZM2.00089 42.4286C0.891964 42.4286 0 43.3205 0 44.4295C0 44.996 0.241071 45.5384 0.699107 45.8638C3.31473 47.7804 13.4759 54 34.7143 54C55.9527 54 66.1138 47.7804 68.7295 45.8638C69.1875 45.5263 69.4286 44.996 69.4286 44.4295C69.4286 43.3205 68.5366 42.4286 67.4277 42.4286H2.00089Z" fill="#004E38"/>
    
    </svg>
    `;

    // Append a foreignObject to the 'g' element
    const foreignObject = g2
      .append("foreignObject")
      .attr("x", -(height !== 450 ? 30 : 54) / 2)
      .attr("y", -(height !== 450 ? 30 : 54) / 2)
      .attr("width", height !== 450 ? 30 : 54) // Set the width of the foreignObject
      .attr("height", height !== 450 ? 30 : 54); // Set the height of the foreignObject

    // Append the provided SVG code to the foreignObject
    foreignObject.append("xhtml:body").html(icon);

    /*g2.append("text")
      .attr("dy", "-1rem")
      .attr("text-anchor", "middle")
      .attr("fill", "#58595B")
      .attr("font-size", "1.125rem")
      .attr("font-weight", "300")
      .text("TOTAL:");

    g2.append("text")
      .attr("dy", "1.5rem")
      .attr("text-anchor", "middle")
      .attr("fill", "#004E38")
      .attr("font-size", "2.75rem")
      .attr("font-weight", "500")
      .text(ensaiosData.length);*/
  }, [width, height, playAnimation]);

  return (
    <div className="flex w-full flex-wrap justify-center ">
      <div
        className="mb-12 lg:w-full"
        data-aos={
          currentBreakpoint === "sm" || currentBreakpoint === "md"
            ? "fade-up"
            : "fade-left"
        }
      >
        <h2 className="text-center text-2xl font-semibold uppercase text-green-1 lg:ml-auto lg:w-1/2 lg:pl-32 lg:text-left">
          {currentLanguage === "PT"
            ? 'Realizamos mais de 300 ensaios diferentes em laboratório e "in situ".'
            : "We conduct more than 300 different tests in the laboratory and on-site."}
        </h2>
      </div>

      <div
        className="mb-12 justify-center lg:mr-auto lg:flex lg:w-1/2"
        data-aos={
          currentBreakpoint === "sm" || currentBreakpoint === "md"
            ? "fade-up"
            : "fade-right"
        }
      >
        <svg
          ref={svgRef}
          height={height !== 450 ? height + 30 : height}
          width={width}
          className="mx-auto overflow-visible"
        ></svg>
      </div>

      <ul
        className="flex flex-col gap-y-4 px-8 md:w-1/3 lg:w-1/2 lg:pl-32 lg:pr-0"
        data-aos={
          currentBreakpoint === "sm" || currentBreakpoint === "md"
            ? "fade-up"
            : "fade-left"
        }
      >
        {legendaItems}
      </ul>
    </div>
  );
}
