import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

export default function PortefolioGraph({ data, width, height, items, delay }) {
  function convertToDecimal(arr) {
    // Find the maximum element in the array
    const maxElement = Math.max(...arr);

    // Convert elements to decimal numbers based on the maximum element
    const decimalArray = arr.map((element) => {
      if (element === maxElement) {
        // If the element is the maximum, set it to 1
        return 1;
      } else {
        // Convert other elements to decimal based on the maximum element
        return element / maxElement;
      }
    });

    return decimalArray;
  }

  const svgRef = useRef();

  useEffect(() => {
    let svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    const sum = data.reduce(function (accumulator, currentValue) {
      return accumulator + currentValue;
    }, 0);

    const curvePercent = convertToDecimal(data); // data set in percentages
    const colors = ["#004E38", "#82C341", "#C0E992", "#798F54", "#C0C179"]; // colors for the arcs
    const numberOfValues = curvePercent.length;
    const arcWidth = 30; // width of each arc in the graph
    const visibleArcWidth = arcWidth / 2; // visible arc width (half of arcWidth)

    const degree = Math.PI / 180; // just to convert the radian-numbers

    // Appending SVG to the body
    svg = d3.select(svgRef.current).attr("width", width).attr("height", height);

    // Container for the arcs
    const curves = svg
      .append("g")
      .attr("transform", `translate(${width / 2},${height / 2})`);

    // Appending arcs
    for (let i = 0; i < numberOfValues; i++) {
      // Visible arc
      const innerRadius = width / 2 - (i * 2 + 1) * visibleArcWidth;
      const outerRadius = width / 2 - i * 2 * visibleArcWidth;

      const visibleArc = d3
        .arc()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius)
        .startAngle(0)
        .endAngle(0); // Start angle is 0 initially

      curves
        .append("path")
        .style("fill", colors[i])
        .attr("d", visibleArc)
        .transition() // Add transition for animation
        .delay(delay ? 2000 : 0)
        .duration(1000) // Animation duration in milliseconds
        .ease(d3.easeCubicInOut) // Easing function for smooth animation
        .attrTween("d", () => {
          // Interpolate between 0 and the desired end angle to create the animation
          const interpolate = d3.interpolate(0, curvePercent[i] * 270 * degree);
          return function (t) {
            // Set the end angle based on the interpolation
            visibleArc.endAngle(interpolate(t));
            // Generate the arc path based on the updated end angle
            return visibleArc();
          };
        });

      // Calculate coordinates for text labels
      const labelX = -10; // X-coordinate at the middle of the arc
      const labelY = 30 * i - height / 2 + 13; // Y-coordinate (adjust as needed)

      // Append text labels
      curves
        .append("text")
        .attr("x", labelX)
        .attr("y", labelY)
        .attr("text-anchor", "end")
        .attr("font-weight", "500")
        .attr("font-size", "18px")
        .style("fill", colors[i])
        .text(items[i]);
    }

    // Append total sum to center of graph
    /*curves
      .append("text")
      .attr("x", 0)
      .attr("y", -15)
      .attr("text-anchor", "middle")
      .attr("font-size", "0.875rem")
      .style("fill", "#797979")
      .text("TOTAL:");

    curves
      .append("text")
      .attr("x", 0)
      .attr("y", 15)
      .attr("text-anchor", "middle")
      .attr("font-size", "2rem")
      .style("fill", "#EB7100")
      .text(sum);*/
  }, [data, width, height]);

  return <svg ref={svgRef}></svg>;
}
