import React from "react";
import classNames from "classnames";

export default function ArrowSubtitle({ text, left = false, classes = false }) {
  return (
    <h3
      className={classNames(
        classes && classes,
        !left ? "before:-right-8 before:-scale-x-100" : "text-r before:-left-8",
        "relative max-w-[calc(100%-1rem)] text-2xl font-medium uppercase text-green-3 before:absolute before:top-1.5 before:h-0 before:w-0 before:border-r-[1rem] before:border-t-[1rem] before:border-transparent before:border-t-green-1 before:content-['']",
      )}
    >
      {text}
    </h3>
  );
}
