import React from "react";
import classNames from "classnames";

export default function Title({ text, classes = false }) {
  return (
    <h1
      className={classNames(
        "relative z-20 text-center text-5xl font-semibold uppercase tracking-tight text-green-1 lg:-mt-6  lg:text-6xl lg:font-bold lg:tracking-normal xl:text-7xl 2xl:text-8xl",
        classes && classes,
      )}
    >
      {text}
    </h1>
  );
}
